import React, { useEffect, useState, useRef, ChangeEvent } from "react";
import {
    TextField,
    Grid,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    Button,
    InputAdornment,
    IconButton,
    Typography,
    Box,
    Stack,
    Modal,
    Divider,
    SvgIcon,
    Autocomplete,
    Switch,
    CircularProgress,
    TextareaAutosize,
    Tooltip,
    Menu,
    Checkbox,
    FormControlLabel
} from "@mui/material";
// import { Editor } from 'react-draft-wysiwyg';
// import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { LoadingButton } from "@mui/lab";
import { KeyboardBackspace, KeyboardArrowLeft, KeyboardArrowRight, SettingsPowerTwoTone, AlignHorizontalCenter, Widgets } from "@mui/icons-material";
import ReactQuill from "react-quill";
import { makeStyles } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Header from "../../components/headers/Header";
import demoImage from "../../assets/image1.png";
import { Edit } from "@mui/icons-material";
import { Download } from "@mui/icons-material";
import { Share } from "@mui/icons-material";
import { Delete } from "@mui/icons-material";
import { ThumbUp } from "@mui/icons-material";
import { ThumbDown } from "@mui/icons-material";
import { ReactComponent as EyeIcon } from "../../assets/ico_eye.svg";
import { Link, Navigate } from "react-router-dom";
import { isMobile, useDeviceData, isBrowser } from "react-device-detect";
import { useGeneratedbusinessurlblogsMutation, useGetBlogDetQuery, useDeleteBlogMutation, useBlogDetailsPublishMutation, useSavingThumbsUpMutation, useSavingThumbsDownMutation, useBlogDetailsDraftMutation, useRegenerateBusinessBlogMutation, useBlogsCountQuery, useCampaignBlogsCountQuery, useCopyurlMutation, usePublishtogoogleMutation, usePublishUnpublishCountMutation, useGetBlogsByCampaignstatusQuery } from "../../redux/api/promptApi";
import { IGetBlogsAdmin, IgeneratedbusinessblogDet } from "../../redux/api/types";
import { useLazyGetProductQuery } from '../../redux/api/productApi';
import { useNavigate, useLocation } from "react-router-dom";
import PayNowButton from '../../pages/payment/payNowButton';
import { object, string, TypeOf, number } from "zod";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReactComponent as DownloadIcon } from "../../assets/download_ico.svg";
import { ReactComponent as ShareIcon } from '../../assets/share_ico.svg';
import { ReactComponent as TrashIcon } from '../../assets/trash_ico.svg';
import { ReactComponent as EditIcon } from '../../assets/pencil_ico.svg';
import { ReactComponent as LikeIcon } from '../../assets/like_ico.svg';
import { ReactComponent as DisLikeIcon } from '../../assets/dislike_ico.svg';
import { ReactComponent as ViewIcon } from '../../assets/view1_ico.svg';
import { ReactComponent as RegenerateIcon } from '../../assets/regenerate_ico.svg';
import { useGetgeneratedBlogListingsQuery } from '../../redux/api/promptApi';
import { useGetproductsDetQuery } from '../../redux/api/promptApi';
import { ReactComponent as MenuIcon } from '../../assets/Menu_ico.svg';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { useGetUserDetailsMutation } from "../../redux/api/authApi";
import { renderToStaticMarkup } from 'react-dom/server';
import { ReactComponent as FeedBackIcon } from '../../assets/ico_feedback.svg'
import { useFeedBackMutation } from '../../redux/api/authApi';
import { store, useAppDispatch, useAppSelector } from "../../redux/store";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { IPlanDetail } from "../../redux/api/types";
import axios from 'axios';
import SearchIcon from '@mui/icons-material/Search';
import { useCampaignListMutation, useGetCnameEntriesMutation, useGetBlogsByCampaignQuery, useGetBlogGenerationStatusMutation } from "../../redux/api/promptApi";
import { usePlanDetailsMutation } from "../../redux/api/productApi";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoIcon from '@mui/icons-material/Info';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Pagination from "@mui/material/Pagination";
import youtubeIco1 from '../../assets/traffic_monster/youtubeIco_1.png'
import unsplashImg from '../../assets/traffic_monster/unsplash_ico_logo.png';
// import Stack from "@mui/material";
import unsplash_logo from '../../assets/traffic_monster/unsplash_logo.png';
import CloseIcon from '@mui/icons-material/Close';
import DraftBtn from '../../assets/traffic_monster/draftBtn.png';
// import EditNoteIcon from '@mui/icons-material/EditNote';
import { ReactComponent as ExportIcon } from '../../assets/podcast_monster/export_ico.svg';
import CodeIcon from '@mui/icons-material/Code';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import ClipboardIcon from '@mui/icons-material/FileCopy';
import { ReactComponent as WordIcon } from '../../assets/podcast_monster/docx_ico.svg';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { ReactComponent as ToggleIcon } from '../../assets/podcast_monster/toggle.svg';
import { ReactComponent as WPLogoWhite } from '../../assets/podcast_monster/wordpressLogo_white.svg';
import { ReactComponent as MediumLogo } from '../../assets/podcast_monster/mediumLogo.svg';
import { ReactComponent as PlayBtn } from '../../assets/podcast_monster/play_btn.svg';
import { ReactComponent as WPLogoBlue } from '../../assets/podcast_monster/wordpressLogo_blue.svg';
import bloggerLogo from '../../assets/podcast_monster/blogger_logo.png';
import ghostLogo from '../../assets/podcast_monster/ghost_logo.png';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { debug } from "console";
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { ReactComponent as SaveIcon } from '../../assets/podcast_monster/save_ico.svg';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import FindReplaceIcon from '@mui/icons-material/FindReplace';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import MicIcon from '@mui/icons-material/Mic';
import transcript_img from '../../assets/podcast_monster/transcript_img.png';
import SettingsIcon from '@mui/icons-material/Settings';
import { getCookie } from "utils/storageUtils";
import best_1 from '../../assets/ocesha_images/best_1.png';
import { ReactComponent as NotePencilIco } from '../../assets/ocesha_images/note_pencil_ico.svg';
import { ReactComponent as DeletIcon } from '../../assets/ocesha_images/delete_ico.svg';
import { ReactComponent as SubMenuIcon } from '../../assets/ocesha_images/sub_menu_ico.svg';
import { ReactComponent as TrashIco } from '../../assets/ocesha_images/trash_ico.svg';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { ReactComponent as EditIco } from '../../assets/ocesha_images/edit_ico.svg';
import { ReactComponent as ViewIco } from '../../assets/ocesha_images/view_ico.svg';
import { ReactComponent as ExportIco } from '../../assets/ocesha_images/export_ico.svg';
import { ReactComponent as DeleteIco } from '../../assets/ocesha_images/delete_icon.svg';
import { ReactComponent as RegenerateIco } from '../../assets/ocesha_images/regenerate_ico.svg';
import { ReactComponent as SettingsIco } from '../../assets/ocesha_images/settings_ico.svg';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import youtubeIco from '../../assets/traffic_monster/youtubeIco_1.png';

interface Blog {
    id: string;
    image_updated: 'Yes' | 'No';
    // Add other properties as needed
}

const customInputStyle = {
    color: '#fff',
    border: '1px solid #CBCBCB',
};

let timestampInMilliseconds = Date.now();
let milliSeconds = Math.floor(timestampInMilliseconds / 1000);


const feedbackform = object({
    email: string(),
    rating: number(),
    feedback: string(),
    productname: string(),
    date: string()
});

const blogUpdate = object({
    id: string(),
    blog_title: string(),
    blog_image_string: string(),
    blog_summary: string(),
    blog_det: string(),
    url: string(),
    publish_date: string(),
    author: string(),
    author_image: string(),
    author_url: string(),
    cta_label: string(),
    cta_url: string(),
    blog_image_path: string(),
    videotag: string(),
    product_id: string()
})

export type FeedBackForm = TypeOf<typeof feedbackform>

export type BlogUpdate = TypeOf<typeof blogUpdate>

const blogDraft = object({
    id: string(),
    blog_title: string(),
    blog_image_string: string(),
    blog_image: string(),
    blog_summary: string(),
    blog_det: string(),
    url: string(),
    author: string(),
    author_image: string(),
    author_url: string(),
    cta_label: string(),
    cta_url: string()
})

export type BlogDraft = TypeOf<typeof blogDraft>

interface LoadingStates {
    [key: string]: boolean;
}

//test comment to fource merge
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        // backgroundColor: '#1377C3', 
        backgroundColor: '#0F74BF',
        color: 'lightblack',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        // backgroundColor: 'white',
        /* backgroundColor: '#131825', */ // Set background color for odd rows to white
        color: 'black', // Set text color for odd rows to black
    },
    '&:nth-of-type(even)': {
        // backgroundColor: '#F2F6FA',
        /* backgroundColor: '#131825', */ // Set background color for even rows to gray
        color: 'darkslategray',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

interface UnsplashImage {
    id: string;
    urls: {
        thumb: string;
        regular: string;
    };
    alt_description: string;
    width: number;
    height: number;
}


const Blogs_Section = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const { FromCampaign } = location.state || {};
    const { FromUpdatedCampaign } = location.state || {};
    const { FromDashboard, ...otherState } = location.state || {};
    const { subscription } = location.state || {};
    const { userEmail } = location.state || {};
    const [selected, setSelected] = useState(false);
    const [openEdit, setOPenEdit] = useState(false);
    const [openPaidModal, setOpenPaidModal] = useState(false);
    const [helperModal, setHelperModal] = useState(FromDashboard === 'true' ? true : localStorage.getItem('AlertMessage') === 'false' ? false : false);
    const [isLiked, setIsLiked] = useState('');
    const [isDisLiked, setIsDisLiked] = useState('');
    //feedback popup
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const ratings = [1, 2, 3, 4, 5];
    const user = useAppSelector((state) => state.userState.user);
    const [productName, setProductName] = useState<string | null>(localStorage.getItem('module'));
    const currentDate = new Date().toISOString();
    const [feedbackForm, setFeedbackForm] = useState<FeedBackForm>({ email: localStorage.getItem('user_email') || "", rating: 0, feedback: '', productname: productName || '', date: currentDate })
    const [feedbackMutation] = useFeedBackMutation();

    const [getProduct, getProductState] = useLazyGetProductQuery();
    const genieSelector = useAppSelector((state) => state.genieState);
    const [wishRemCount, setWishRemCount] = useState(0);
    const [isTranscribeEditable, setIsTranscribeEditable] = useState<boolean>(false);


    const [generatedBlogDetail, setGeneratedBlogDetail] = useState<any>();
    const [productDetail, setProductDetail] = useState<any>();
    const [productURL, setProductURL] = useState('');
    const [planDetails, setPlanDetails] = useState<any>();
    const [timestampInSeconds, setMilliSeconds] = useState(0);
    const [freePlann, setFreePlan] = useState(localStorage.getItem('free_plan'));
    const [subscriptionLevel, setSubscriptionLevel] = useState(localStorage.getItem('subscription_level'));
    const [startDate, setStartDate] = useState<Date | null>(null);

    const staticTypeOptions = ['Blogs']
    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, false] }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['bold', 'italic', 'underline'],
            ['link'],
            [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],  // Include alignment options
        ],
    };

    const [generatedBusinessUrlBlogsMutation] = useGeneratedbusinessurlblogsMutation();
    const [regenerateBusinessBlogMutation] = useRegenerateBusinessBlogMutation();
    const [publishUnpublishCountMutation] = usePublishUnpublishCountMutation();

    const [publishedUnpublishedCount, setPublishedUnpublishedCount] = useState<any>();
    const [userDets, setUserDets] = useState<any>();
    const [getUserDetailsMutation] = useGetUserDetailsMutation();

    const user_email = localStorage.getItem('user_email') ? localStorage.getItem('user_email') : userEmail ? userEmail : getCookie('user_email') ? getCookie('user_email') : '';

    // const blogGenerated = localStorage.getItem('Blogs Generated');

    const [selectedBlogIndex, setSelectedBlogIndex] = useState(0);

    const [isLoading, setIsLoading] = useState<LoadingStates>({});

    const [isLoadingTranscript, setIsLoadingTranscript] = useState<LoadingStates>({});

    const [isLoadingCopied, setIsLoadingCopied] = useState<LoadingStates>({});

    const [selectedBlogTitle, setSelectedBlogTitle] = useState('');

    const [searchQuery, setSearchQuery] = useState('');

    const [updateblogs, setUpdateBlogs] = useState<BlogUpdate>({ id: '', blog_title: '', blog_image_path: '', blog_image_string: '', blog_summary: '', blog_det: '', url: '', publish_date: '', author: '', author_image: '', author_url: '', cta_label: '', cta_url: '', videotag: '', product_id: '' });

    const [selectedBlog, setSelectedBlog] = useState<IgeneratedbusinessblogDet | null>(null);

    const [prevImgLoader, setPrevImgLoader] = useState<boolean>(false);

    const [deleteBlogMutation] = useDeleteBlogMutation();

    const [savingthumbsupMutation] = useSavingThumbsUpMutation();

    const [savingthumbsdownMutation] = useSavingThumbsDownMutation();

    const [blogDetailsDraftMutation] = useBlogDetailsDraftMutation();
    const [blogDetailsPublishMutation] = useBlogDetailsPublishMutation();
    const [copySharingUrlMutation] = useCopyurlMutation();
    const [publishtogoogleMutation] = usePublishtogoogleMutation();
    const [searchResults, setSearchResults] = useState([]);
    const [authUrl, setAuthUrl] = useState('');
    const [blogImg, setBlogImg] = useState('');
    const [authorImg, setAuthorImg] = useState('');
    const [imageFile, setImageFile] = useState<File | null>(null);
    const [authorImgFile, setAuthorImgFile] = useState<File | null>(null);
    const [isDraftOrPublishLoading, setIsDraftOrPublishLoading] = useState<boolean>(false);
    const [isMenuDropdownOpen, setIsMenuDropdownOpen] = useState<any>();
    //const [varImageUpdated, setVarImageUpdated] = useState<boolean>(false);
    const [openExportModal, setOpenExportModal] = useState(false);
    const [blogIdForExport, setBlogIdForExport] = useState<any>();
    const [urlForExport, setUrlForExport] = useState<any>();
    const [productIdForExport, setproductIdForExport] = useState<any>();
    const [titleForExport, setTitleForExport] = useState<any>();
    const [summaryForExport, setSummaryForExport] = useState<any>();
    const [isDownloadLoading, setIsDownloadLoading] = useState<any>();
    const [isLoadingShareLink, setIsLoadingShareLink] = useState<string | null>(null);
    const [isLoadingEditPost, setIsLoadingEditPost] = useState<string | null>(null);
    const submenuRef = useRef<HTMLDivElement | null>(null);

    let varImageUpdated = false;

    const [getuserPlanDetails] = usePlanDetailsMutation();
    const [openUnsplash, setOpenUnsplash] = useState<any>();
    const [selectedUnsplashImg, setSelectedUnsplashImg] = useState(null);
    const [helpervideosrc, setHelperVideoSrc] = useState<any>();
    const [helperPopup, setHelperPopup] = useState<boolean>(false);
    const [sharingpostdata, setSharingPostData] = useState<any>();
    const [openPostEdit, setOpenPostEdit] = useState<boolean>(false);
    const [upgradeModal, setUpgradeModal] = useState<boolean>(false);
    const [deteleModal, setDeleteModal] = useState<boolean>(false);
    const [transcriptModal, setTranscriptModal] = useState<boolean>(false);
    const [transcribedData, setTranscribedData] = useState<any>();
    const [deleteBlogId, setDeleteBlogId] = useState<any>();
    const [anchorEl, setAnchorEl] = useState(null);
    const [blogIdofsubmenu, setBlogidofsubmenu] = useState<any>();
    const [productIdofsubmenu, setProductIdofSubmenu] = useState<any>();
    const [publishBlogModal, setPublishBlogModal] = useState<boolean>(false);
    const [publishToOcesha, setPublishToOcesha] = useState<any>(false);
    const [publishToCodegenie, setPublishToCodegenie] = useState<any>(false);
    const [blogIdToPublish, setBlogIdToPublish] = useState<any>();
    const [selectedDate, setSelectedDate] = useState<any>(null);
    const [closeDateFilter, setCloseDateFilter] = useState<boolean>(false);
    const [openMenuId, setOpenMenuId] = useState(null);
    const [fromDate, setFromDate] = useState<string | null>(null);
    const [toDate, setToDate] = useState<string | null>(null);
    const [toUpgrade, setToUpgrade] = useState<boolean>(false);
    // const videoRef = useRef<HTMLVideoElement>(null);

    /* const handlePlayVideo = () => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    }; */
    const handlePlayVideo = (videoSrc: any) => {
        // window.open('/images/wordpress.mp4', '_blank');
        setHelperVideoSrc(videoSrc);
        setHelperPopup(true);
    }
    const handlePlayVideo1 = (videoSrc: any) => {
        // window.open('/images/medium.mp4', '_blank');
        setHelperVideoSrc(videoSrc);
        setHelperPopup(true);
    }

    const handlePlayVideo2 = (videoSrc: any) => {
        // window.open('/images/blogger.mp4', '_blank');
        setHelperVideoSrc(videoSrc);
        setHelperPopup(true);
    }

    const handlePlayVideo3 = (videoSrc: any) => {
        // window.open('/images/ghost.mp4', '_blank');
        setHelperVideoSrc(videoSrc);
        setHelperPopup(true);
    }

    useEffect(() => {
        const loggedIn = localStorage.getItem("logged_in");
        if (loggedIn !== "true") {
            navigate("/signin");
        }
    }, [navigate]);



    const [plans, setPlans] = useState<IPlanDetail[]>([
        {
            user: 1,
            plan_name: "",
            total_wishes: 0,
            price: "",
            period: "",
            product_id: "",
            price_id: "",
            subscription_level: "",
            feature: [],
            contracts: 0,
            type: "",
            button_text: "",
            save: ''
        },
    ]);

    useEffect(() => {
        if (userEmail) {
            localStorage.setItem('user_email', userEmail || getCookie('user_email'));
        }
    }, [userEmail]);
    const fetchPlanDatas = async () => {
        try {
            const response = await getuserPlanDetails({ email: user_email, campaignid: ''/* selectedCampaign */ });

            if ('data' in response) {
                setPlanDetails(response.data.data);
                // console.log('plan details=>', planDetails.data);
            }
        } catch (error) {
            //console.log(error);
        }
    }

    useEffect(() => {
        if (helperModal) {
            localStorage.setItem('AlertMessage', "true");
        } else if (!FromDashboard) {
            localStorage.setItem('AlertMessage', "false");
        }
        setMilliSeconds(milliSeconds);
    });

    useEffect(() => {
        fetchPlanDatas();
        console.log('plan detsss=>', planDetails);
    }, [/* planDetails */]);

    useEffect(() => {
        // Update the name state when userDetails changes
        if (userDets)
            setAuthUrl(userDets.social_url);
    }, [userDets]);

    const handleBlogSelect = (index: any) => {
        setSelectedBlogIndex(index);
    }
    // const [editorState, setEditorState] = useState(EditorState.createWithContent(ContentState.createFromText(updateblogs.blog_det)));

    useEffect(() => {
        const fetchData = async () => {
            if (FromDashboard || fromDate || toDate || (closeDateFilter === true)) {
                try {
                    const response = await generatedBusinessUrlBlogsMutation({ userid: user_email, createddate: fromDate ? fromDate : '', createddate_to: toDate ? toDate : '' });
                    if ('data' in response) {
                        const { data } = response.data;
                        setGeneratedBlogDetail(data);

                        console.log('data from variable=>', data);
                    } else if ('error' in response) {

                        const { error } = response;
                        //console.log('Error:', error);
                    }
                } catch (error) {
                    //console.error('Error fetching blogs:', error);
                }
            }
        };

        fetchData();
    }, [FromDashboard, generatedBusinessUrlBlogsMutation, user_email, timestampInSeconds, fromDate, toDate]);


    useEffect(() => {
        if (localStorage.getItem('FromEditBlog') === 'true') {
            window.location.reload();
            localStorage.setItem('FromEditBlog', 'false');
        }
    }, []);

    const generatingCount = parseInt(localStorage.getItem('Generating Count') || '0') >= 1;
    const freePlan = parseInt(localStorage.getItem('free_plan') || '0') === 0;

    useEffect(() => {
        if (getBlogsCount?.data > 0) {
            localStorage.setItem('blogsCount', 'true');
        } else {
            localStorage.setItem('blogsCount', 'false');
        }
    })

    useEffect(() => {
        if (generatingCount) {
            localStorage.setItem("free_plan", "0");
        }
    })

    const handleRegenerateBlogClick = () => {
        if (generatingCount) {
            localStorage.setItem("free_plan", "0");
            navigate('/codegenie/dashboard');
        }
        /* if (freePlan) {
            setOpenPaidModal(true);
        } */ /* else {
            navigate('/codegenie/dashboard');
        } */
    }

    const handleFormSubmit = () => {
        // Call the feedbackMutation function with the form data as payload
        feedbackMutation(feedbackForm)
            .then((response) => {
                // console.log('Feedback sent successfully:', response);
                toast.success('FeedBack Sent Successfully!!');
                setIsPopupOpen(false);
                setFeedbackForm({
                    email: localStorage.getItem('user_email') || userEmail || '',
                    rating: 0,
                    feedback: '',
                    productname: 'TrafficMonster',
                    date: currentDate
                });

            })
            .catch((error) => {
                console.error('Error sending feedback:', error);

            });
    };

    const handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        // Update feedbackForm with the textarea value
        setFeedbackForm((prevForm) => ({
            ...prevForm,
            feedback: event.target.value,
        }));

    };

    const handleRating = (event: number) => {
        // Update feedbackForm with the textarea value
        setFeedbackForm((prevForm) => ({
            ...prevForm,
            rating: event
        }));

    };

    //Edit functionality in Listing
    const handleEditClick = (blog: IgeneratedbusinessblogDet) => {
        //console.log('blog=>', blog);
        setSelectedBlog(blog);
        setOPenEdit(true);

        const mode = blog.mode || 'Live';
        console.log(blog.mode);

        let updatedBlogDet = blog.blog_det_draft ? blog.blog_det_draft : blog.blog_det;

        //do some formatting for blog details
        let htmlstring = "<div>";


        {
            (updatedBlogDet) ? updatedBlogDet.split('\n\n').map((paragraph: any, idx: any) => {

                paragraph = paragraph.replace(/\*\*/g, '');
                paragraph = paragraph.replace(/\#\#\#/g, '');
                paragraph = paragraph.replace(/\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+/g, '');
                paragraph = paragraph.replace(/\"/g, '');
                paragraph = paragraph.replace(/:/g, ' ');

                if (paragraph.includes('://') || paragraph.includes('//')) {
                    let newBlogDet = (
                        <div key={idx}><div dangerouslySetInnerHTML={{ __html: paragraph.replace("<h5>", "</br><h5>") }} /><br /></div>
                    );
                    htmlstring = htmlstring + renderToStaticMarkup(newBlogDet);

                } else if (!paragraph.match(/<h5>/) && (paragraph.match(/^(Article|Paragraph [1-3]|Introduction|Conclusion|Blogging 101|Blog|Paragraph [1-3] Heading):/))) {

                    let newBlogDet = (
                        <div key={idx}>
                            <br />
                            <div dangerouslySetInnerHTML={{ __html: paragraph.replace(/^(Article|Paragraph [1-3]|Introduction|Conclusion|Blogging 101|Blog|Paragraph [1-3] Heading):/, '') }} />
                        </div>
                    );
                    htmlstring = htmlstring + renderToStaticMarkup(newBlogDet);
                } else if (paragraph.includes(':')) {
                    if (paragraph.indexOf(':') < 100) {
                        let parts = paragraph.split(':');
                        let label = parts[0];
                        label = label.replace('<h5>', '');
                        label = label.replace('</h5>', '');
                        label = label.replace('<br>', '');
                        let content = "";
                        if (parts.slice(1).indexOf(":") >= 0) {
                            content = parts.slice(1).join(':');
                        } else
                            content = parts[1];
                        content = content.replace('<h5>', '');
                        content = content.replace('</h5>', '');
                        content = content.replace('<br>', '');
                        let newBlogDet = (
                            <div key={idx}>
                                <h2>{label}:</h2><div dangerouslySetInnerHTML={{ __html: content }} /><br />
                            </div>
                        );
                        htmlstring = htmlstring + renderToStaticMarkup(newBlogDet);
                    } else {
                        let newBlogDet =
                            (
                                <div key={idx}>
                                    <div dangerouslySetInnerHTML={{ __html: paragraph }} />
                                </div>
                            );
                        htmlstring = htmlstring + renderToStaticMarkup(newBlogDet);
                    }
                } else {
                    let modifiedParagraph = "";
                    let newparagraph = paragraph.split('\n');
                    if (newparagraph[0].length < 100) {
                        if (newparagraph[0].indexOf("<h5>") !== -1)
                            modifiedParagraph = newparagraph[0];
                        else
                            modifiedParagraph = "<h5>" + newparagraph[0] + "</h5>";
                        for (var i = 1; i < newparagraph.length; i++) {
                            modifiedParagraph = modifiedParagraph + newparagraph[i];
                        }
                    } else {
                        for (var i = 0; i < newparagraph.length; i++) {
                            modifiedParagraph = modifiedParagraph + newparagraph[i];
                        }
                        modifiedParagraph = modifiedParagraph.replace("<h5>", "");
                        modifiedParagraph = modifiedParagraph.replace("</h5>", "");
                        modifiedParagraph = modifiedParagraph.replace("<div", "<div>");
                        modifiedParagraph = modifiedParagraph.replace("</div>", "</div>");
                    }

                    let newBlogDet = (<div key={idx}><div dangerouslySetInnerHTML={{ __html: modifiedParagraph }} /> <br /></div>);
                    htmlstring = htmlstring + renderToStaticMarkup(newBlogDet);
                    return (
                        ""
                    );
                }
            }) :
                blog.blog_det_draft && blog.blog_det_draft.split('\n\n').map((paragraph: any, idx: any) => {

                    paragraph = paragraph.replace(/\*\*/g, '');
                    paragraph = paragraph.replace(/\#\#\#/g, '');
                    paragraph = paragraph.replace(/\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+\+/g, '');
                    paragraph = paragraph.replace(/\"/g, '');
                    paragraph = paragraph.replace(/:/g, ' ');

                    if (paragraph.includes('://') || paragraph.includes('//')) {
                        let newBlogDet = (
                            <div key={idx}>
                                <div dangerouslySetInnerHTML={{ __html: paragraph.replace("<h5>", "<h5>") }} />
                                <br />
                            </div>
                        );
                        htmlstring = htmlstring + renderToStaticMarkup(newBlogDet);
                    } else if (!paragraph.match(/<h5>/) && (paragraph.match(/^(Article|Paragraph [1-3]|Introduction|Conclusion|Blogging 101|Blog|Paragraph [1-3] Heading):/))) {
                        let newBlogDet = (
                            <div key={idx}>
                                <br />
                                <div dangerouslySetInnerHTML={{ __html: paragraph.replace(/^(Article|Paragraph [1-3]|Introduction|Conclusion|Blogging 101|Blog|Paragraph [1-3] Heading):/, '') }} />
                            </div>
                        );
                        htmlstring = htmlstring + renderToStaticMarkup(newBlogDet);
                    } else if (paragraph.includes(':')) {
                        if (paragraph.indexOf(':') < 100) {
                            let parts = paragraph.split(':');
                            let label = parts[0];
                            label = label.replace('<h5>', '');
                            label = label.replace('</h5>', '');
                            label = label.replace('<br>', '');
                            let content = "";
                            if (parts.slice(1).indexOf(":") >= 0) {
                                content = parts.slice(1).join(':');
                            } else
                                content = parts[1];
                            content = content.replace('<h5>', '');
                            content = content.replace('</h5>', '');
                            content = content.replace('<br>', '');
                            let newBlogDet = (
                                <div key={idx}>
                                    <h2>{label}:</h2><div dangerouslySetInnerHTML={{ __html: content }} /><br />
                                </div>
                            );
                            htmlstring = htmlstring + renderToStaticMarkup(newBlogDet);
                        } else {
                            let newBlogDet = (
                                <div key={idx}>
                                    <div dangerouslySetInnerHTML={{ __html: paragraph }} />
                                </div>
                            );
                            htmlstring = htmlstring + renderToStaticMarkup(newBlogDet);
                        }
                    } else {
                        let modifiedParagraph = "";
                        let newparagraph = paragraph.split('\n');
                        if (newparagraph[0].length < 100) {
                            if (newparagraph[0].indexOf("<h5>") !== -1)
                                modifiedParagraph = newparagraph[0];
                            else
                                modifiedParagraph = "<h5>" + newparagraph[0] + "</h5>";
                            for (var i = 1; i < newparagraph.length; i++) {
                                modifiedParagraph = modifiedParagraph + newparagraph[i];
                            }
                        } else {
                            for (var i = 0; i < newparagraph.length; i++) {
                                modifiedParagraph = modifiedParagraph + newparagraph[i];
                            }
                            modifiedParagraph = modifiedParagraph.replace("<h5>", "");
                            modifiedParagraph = modifiedParagraph.replace("</h5>", "");
                            modifiedParagraph = modifiedParagraph.replace("<div", "<div>");
                            modifiedParagraph = modifiedParagraph.replace("</div>", "</div>");
                        }
                        let newBlogDet = (
                            <div key={idx}>
                                <div dangerouslySetInnerHTML={{ __html: modifiedParagraph }} />
                                <br />
                            </div>
                        );

                        return ("");
                    }
                })
        }

        htmlstring = htmlstring + "</div>";

        htmlstring = htmlstring.replace(/<h5>/g, "<h3>");
        htmlstring = htmlstring.replace(/<\/h5>/g, "</h3>");

        htmlstring = updatedBlogDet;

        //formatting ends
        let user_image = userDets.user_image ? userDets.user_image : '';
        let social_url = userDets.social_url ? userDets.social_url : '';

        /*  if (mode === 'Live') {
             setUpdateBlogs({
                 id: blog._id,
                 blog_title: blog.blog_title,
                 blog_image_path: blog.blog_image_path,
                 blog_image_string: blog.blog_image_string,
                 blog_summary: blog.blog_summary,
                 blog_det: htmlstring,
                 url: blog.url,
                 publish_date: blog.publish_date,
                 author: blog.author ? blog.author : userDets.name,
                 author_image: blog.author_image ? blog.author_image : user_image,
                 author_url: (blog.author_url != "undefined" && blog.author_url) ? blog.author_url : (planDetails ? planDetails?.social_url : social_url),
                 cta_label: blog.cta_label ? blog.cta_label : planDetails?.cta_lable,
                 cta_url: blog.cta_url ? blog.cta_url : planDetails?.cta_link,
                 videotag: blog.videotag ? blog.videotag : planDetails?.videotag,
                 product_id: blog.product_id
             });
         } else {
             setUpdateBlogs({
                 id: blog._id,
                 blog_title: blog.blog_title_draft ? blog.blog_title_draft : blog.blog_title,
                 blog_image_path: blog.blog_image_path_draft,
                 blog_image_string: blog.blog_image_string,
                 blog_summary: blog.blog_summary_draft,
                 blog_det: htmlstring,
                 url: blog.url_draft,
                 publish_date: blog.publish_date,
                 author: blog.author ? blog.author : userDets.name,
                 author_image: blog.author_image ? blog.author_image : user_image,
                 author_url: (blog.author_url != "undefined" && blog.author_url) ? blog.author_url : (planDetails ? planDetails?.social_url : social_url),
                 cta_label: blog.cta_label ? blog.cta_label : planDetails?.cta_lable,
                 cta_url: blog.cta_url ? blog.cta_url : planDetails?.cta_link,
                 videotag: blog.videotag ? blog.videotag : planDetails?.videotag,
                 product_id: blog.product_id
             });
         } */

        const updatedState = {
            id: blog._id,
            blog_title: blog.blog_title_draft || blog.blog_title,
            blog_image_path: blog.blog_image_path_draft || blog.blog_image_path,
            blog_image_string: blog.blog_image_string,
            blog_summary: blog.blog_summary_draft || blog.blog_summary,
            blog_det: htmlstring,
            url: blog.url_draft || blog.url,
            publish_date: blog.publish_date,
            author: blog.author || userDets.name,
            author_image: blog.author_image || user_image,
            author_url: blog.author_url || social_url,
            cta_label: blog.cta_label,
            cta_url: blog.cta_url,
            videotag: blog.videotag ? blog.videotag : planDetails?.videotag,
            product_id: blog.product_id
        };
        setUpdateBlogs(updatedState);

        setTimeout(() => {
            navigate('/ocesha-edit-blog', { state: updatedState });
        }, 0);
    };

    useEffect(() => {
        // If updateblogs are coming from an API or dynamic data
        if (updateblogs) {
            console.log(updateblogs);
        }
    }, [updateblogs]); //

    const handleDelete = async (blog_id: string) => {
        // const confirmed = window.confirm('Are you sure, you want to delete this blog?');
        // if (confirmed) {
        try {
            const response = await deleteBlogMutation({ blog_id });
            //console.log(response);
            if ('data' in response) {
                const datas = response.data;
                if (datas.status === 'success') {
                    toast.success('Blog Deleted successfully');

                    setGeneratedBlogDetail((prevBlogs: any) => {
                        const updatedBlogs = prevBlogs.filter((blog: any) => blog._id !== blog_id);

                        return updatedBlogs.map((blog: any, index: any) => ({
                            ...blog,
                            serialNumber: index + 1,
                        }));
                    });
                }
                else {
                    toast.error('Failed to delete blog');
                }
            } else {
                toast.error('Failed to delete blog');
            }
        } catch (error) {
            console.error('Error deleting blog', error);
            toast.error('An error occurred while deleting the blog');
        } finally {
            setDeleteModal(false);
        }
        // }
    }

    const handleRegeneratingBlogClick = async (blog_id: string) => {
        setIsLoading(prevLoadingStates => ({
            ...prevLoadingStates,
            [blog_id]: true
        }));
        try {
            const response = await regenerateBusinessBlogMutation({ blog_id });
            //console.log('response of regeneration=>', response);
            if ('data' in response) {
                const regeneratedResp = response.data;
                if (regeneratedResp.status === 'success') {
                    fetchingPublishDatas('');
                    refetch();
                    timestampInMilliseconds = Date.now();
                    milliSeconds = Math.floor(timestampInMilliseconds / 1000);
                    setMilliSeconds(milliSeconds);
                    // window.location.reload();
                    toast.success(regeneratedResp.msg);
                    setAnchorEl(null);
                }
            }
        } catch (error) {
            console.error('Error deleting blog', error);
        } finally {
            setIsLoading(prevLoadingStates => ({
                ...prevLoadingStates,
                [blog_id]: false
            }));
        }
    }

    const handleTranscriptClick = async (blog_id: string) => {
        if (planDetails?.subscription_level === 'level_0' || planDetails?.subscription_level === 'level_0y') {
            setUpgradeModal(true);
            return;
        } else {
            setIsLoadingTranscript(prevLoadingStates => ({
                ...prevLoadingStates,
                [blog_id]: true
            }));
            try {
                const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
                const response = await axios.get(
                    `${apiUrl}/api/prompts/getBlogDet?blog_id=${blog_id}&campId=${null}`,
                );
                console.log('transcript res=>', response.data);
                if (response.data.status === 'success') {
                    const data = response.data.data;
                    const TranscriptData = data.map((item: { product_desc: string }) => item.product_desc);
                    setTranscribedData(TranscriptData);
                    setOpenMenuId(null);
                    setTranscriptModal(true);
                } else {
                    console.log('Error fetching transcription data!');
                }
            } catch (error) {
                console.log('error getting transcript=>', error);
            } finally {
                setIsLoadingTranscript(prevLoadingStates => ({
                    ...prevLoadingStates,
                    [blog_id]: false
                }));
            }
        }
    }

    const handleCopyTranscriptClick = () => {
        navigator.clipboard.writeText(`${transcribedData}`).then(() => {
            toast.success('Transcript Copied to Clipboard');
        })
    }

    console.log(generatedBlogDetail);

    const handelShareClick = async (blog_id: string, publish_to_ocesha: number, publish_to_code_genie: number) => {
        if (userDets?.partner === 1) {
            setBlogIdToPublish(blog_id);
            setPublishBlogModal(true);
        } else {
            try {
                setIsLoadingCopied(prevLoadingStates => ({
                    ...prevLoadingStates,
                    [blog_id]: true
                }));
                // const copyingurl = await copySharingUrlMutation({ blog_id });
                const copyingurl = await publishtogoogleMutation({ blog_id, publish_to_ocesha, publish_to_code_genie });
                console.log('copyurl publishtogoogle resp=>', copyingurl);
                if ('data' in copyingurl) {
                    const datas = copyingurl.data;
                    if (datas.status === 'success' && datas.msg === 'Submitted for index') {
                        toast.success('Submitted to google indexing');
                        /* navigator.clipboard.writeText(datas.url).then(() => {
                            fetchingPublishDatas('');
                            refetch();
                            timestampInMilliseconds = Date.now();
                            milliSeconds = Math.floor(timestampInMilliseconds / 1000);
                            setMilliSeconds(milliSeconds);
                            toast.success('Blog Published to google!'); */
                        /* cleared FromDashboard state on url */
                        /* if (window.history.replaceState) {
                            window.history.replaceState(otherState, '', location.pathname + location.search);
                        } */
                        /*  */
                        // window.location.reload();
                        // });
                    } else {
                        toast.error('Something went wrong, try after some time.');
                    }
                }
            } catch (error) {
                console.error('Error', error);
            } finally {
                setIsLoadingCopied(prevLoadingStates => ({
                    ...prevLoadingStates,
                    [blog_id]: false
                }));
            }
        }
    }


    const PublishToServer = async (blog_id: string, publish_to_ocesha: number, publish_to_code_genie: number) => {
        try {
            setIsLoadingCopied(prevLoadingStates => ({
                ...prevLoadingStates,
                [blog_id]: true
            }));
            // const copyingurl = await copySharingUrlMutation({ blog_id });
            const copyingurl = await publishtogoogleMutation({ blog_id, publish_to_ocesha, publish_to_code_genie });
            console.log('copyurl publishtogoogle resp=>', copyingurl);
            if ('data' in copyingurl) {
                const datas = copyingurl.data;
                if (datas.status === 'success' && datas.msg === 'Submitted for index') {
                    toast.success('Submitted to google indexing');
                    /* navigator.clipboard.writeText(datas.url).then(() => {
                        fetchingPublishDatas('');
                        refetch();
                        timestampInMilliseconds = Date.now();
                        milliSeconds = Math.floor(timestampInMilliseconds / 1000);
                        setMilliSeconds(milliSeconds);
                        toast.success('Blog Published to google!'); */
                    /* cleared FromDashboard state on url */
                    /* if (window.history.replaceState) {
                        window.history.replaceState(otherState, '', location.pathname + location.search);
                    } */
                    /*  */
                    // window.location.reload();
                    // });
                } else {
                    toast.error('Something went wrong, try after some time.');
                }
            }
        } catch (error) {
            console.error('Error', error);
        } finally {
            setIsLoadingCopied(prevLoadingStates => ({
                ...prevLoadingStates,
                [blog_id]: false
            }));
        }
    }

    const handleExportClick = (blogId: string, url: string, productId: string, title: string, summary: string) => {
        console.log(url);
        setUrlForExport(url);
        setBlogIdForExport(blogId);
        setproductIdForExport(productId);
        setTitleForExport(title);
        setSummaryForExport(summary);
        setOpenExportModal(true);
    }

    const handleExportShare = async (mode: any) => {
        if (planDetails?.distribution_to_social_media_platform === true) {
            try {
                setIsLoadingShareLink(mode);
                const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
                const response = await axios.post(
                    `${apiUrl}/api/prompts/get_html_path?blog_id=${blogIdForExport}&mode=${mode}`,
                    null,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
                console.log('sharing link response=>', response.data);
                if (response.data) {
                    debugger;
                    const { url, blog_summary, blog_title, hashtags } = response.data;
                    let shareUrl = '';
                    switch (mode) {
                        case 'fb':
                            shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
                            break;
                        case 'tw':
                            shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`;
                            break;
                        case 'in':
                            shareUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(url)}`;
                            break;
                        default:
                            break;
                    }
                    window.open(shareUrl, '_blank');
                    /* const url = response.data;
                                    let shareUrl = '';
                    switch (mode) {
                        case 'fb':
                            shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${encodeURIComponent(titleForExport)} - ${encodeURIComponent(summaryForExport)}`;
                            break;
                        case 'tw':
                            shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(titleForExport)} - ${encodeURIComponent(summaryForExport)}` ;
                            break;
                        case 'in':
                            shareUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(url)}&title=${encodeURIComponent(titleForExport)}&summary=${encodeURIComponent(summaryForExport)}`;
                            break;
                        default:
                            break;
                    }
                    window.open(shareUrl, '_blank'); */
                    /*  const clipboardText = `${localStorage.getItem('editedpostblogtitle') ? localStorage.getItem('editedpostblogtitle') : blog_title}\n\n
                     ${localStorage.getItem('editedpostblogsummary') ? localStorage.getItem('editedpostblogsummary') : blog_summary}\n\n
                     ${localStorage.getItem('editedposthashtags') ? localStorage.getItem('editedposthashtags') : hashtags}\n\n
                     ${url}`; */

                    /* navigator.clipboard.writeText(clipboardText).then(() => {
                        toast.success('Post details copied to clipboard!'); */


                    /* setTimeout(() => {
                        window.open(shareUrl, '_blank');
                    }, 1000);
                }) */
                } else {
                    setIsLoadingShareLink(null);
                    toast.error('Something went Wrong. Please try later!')
                }
            } catch (error) {
                console.log(error);
                setIsLoadingShareLink(null);
            } finally {
                setIsLoadingShareLink(null);
                localStorage.removeItem('editedposthashtags');
                localStorage.removeItem('editedpostblogsummary');
                localStorage.removeItem('editedpostblogtitle');
                localStorage.removeItem('editedposturl');
            }
        } else {
            setUpgradeModal(true);
        }

    };

    const handleExportPublicLinkClick = async (mode: any, ifmedium: any) => {
        /*  const url = window.location.origin + `/PreviewBlogs1/${blogIdForExport}?campId=${productIdForExport}`
          navigator.clipboard.writeText(url);
          toast.success('Link copied to clipboard!'); */
        if (ifmedium === 'medium' && planDetails?.distribution_to_blogging_platform === false) {
            setUpgradeModal(true);
        } else {
            try {
                setIsLoadingShareLink(mode);
                const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
                const response = await axios.post(
                    `${apiUrl}/api/prompts/get_html_path?blog_id=${blogIdForExport}&mode=${mode}`,
                    null,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
                console.log('sharing link response=>', response.data);
                if (response.data) {
                    navigator.clipboard.writeText(response.data.url);
                    toast.success('Link has been copied to your clipboard! \r\n\r\nNote: You may need to click "Allow" when prompted by your browser to enable clipboard access for www.ocesha.com.', {
                        style: { whiteSpace: 'pre-line', width: '300px' }, autoClose: 15000,
                    });
                } else {
                    setIsLoadingShareLink(null);
                    toast.error('Something went Wrong. Please try later!')
                }
            } catch (error) {
                console.log(error);
                setIsLoadingShareLink(null);
            } finally {
                setIsLoadingShareLink(null);
            }
        }
    }

    /* const handleDownloadFileClick = async (file_type: string) => {
        if (planDetails?.download_content === true || planDetails?.distribution_to_blogging_platform === true) {
            setIsDownloadLoading(file_type);
            try {
                const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
                const response = await axios.post(
                    `${apiUrl}/api/prompts/export?doc_type=${file_type}&blog_id=${blogIdForExport}`,
                    null,
                    {
                        responseType: file_type === 'pdf' || file_type === 'docx' ? 'blob' : 'text',
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
                console.log('file response=>', response);
                if (file_type === 'pdf' || file_type === 'docx') {

                    // Creating download link from the blob
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `export.${file_type}`);
                    document.body.appendChild(link);
                    link.click();
                    //remove the link after download
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                } else {
                    if (file_type === 'html' || file_type === 'html1' || file_type === 'html2' || file_type === 'html3' || file_type === 'html4' || file_type === 'html5') {
                        //Removing the first and last quotes if present
                        let htmlContent = response.data;
                        if (htmlContent.startsWith('"') && htmlContent.endsWith('"')) {
                            htmlContent = htmlContent.slice(1, -1);
                        }

                        //Removing all \n and \t
                        htmlContent = htmlContent.replace(/\\n/g, '').replace(/\n/g, '').replace(/\\t/g, '').replace(/\t/g, '');

                        //Unescaping double quotes \" to "
                        htmlContent = htmlContent.replace(/\\"/g, '"');
                        //Unescaping double quotes \\s to \s
                        htmlContent = htmlContent.replace(/\\\\s/g, '\\s').replace(/\\\\\./g, '\\.');

                        //copying the result to clipboard
                        await navigator.clipboard.writeText(htmlContent);
                        toast.success('Blog content copied to clipboard.  Please paste to your blog editor.');
                    } else if (file_type === 'txt') {
                        await navigator.clipboard.writeText(response.data);
                        toast.success('Text content copied to clipboard!');
                    }
                }
            } catch (error) {
                console.log('Unexpected Error:', error);
                // toast.error('Unexpected Error:', error);
            } finally {
                setIsDownloadLoading(null);
            }
        } else {
            setUpgradeModal(true);
        }
    } */


    const handleDownloadFileClick = async (file_type: string) => {

        if (planDetails?.download_content === true || planDetails?.distribution_to_blogging_platform === true) {
            setIsDownloadLoading(file_type);
            try {
                const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
                const response = await axios.post(
                    `${apiUrl}/api/prompts/export?doc_type=${file_type}&blog_id=${blogIdForExport}`,
                    null,
                    {
                        responseType: file_type === 'pdf' || file_type === 'docx' ? 'blob' : 'text',
                        headers: {
                            'Content-Type': file_type.indexOf('clipboard') > -1 ? 'application/json' : 'multipart/form-data',
                        },


                    }
                );


                console.log('file response=>', response);

                if (file_type === 'pdf' || file_type === 'docx') {
                    // Creating download link from the blob
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `export.${file_type}`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                } else if (file_type === 'txt' || file_type.indexOf('clipboard') > -1) {
                    if (file_type.indexOf('clipboard') > -1) {

                        const parsedContent = JSON.parse(response.data);
                        var cleanedContent = parsedContent.html_content;
                        var cleanedTextContent = parsedContent.text_conent;
                        const cleanedContentWithHtmlFormatting = cleanedContent
                            .replace(/\\n/g, '<br>')
                            .replace(/\\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;')
                            .replace(/\\"/g, '')
                            .replace(/```/g, '')
                            .replace(/<\/html> \"/g, '</html>');

                        // Convert cleaned content to plain text by stripping HTML tags
                        const plainTextContent = cleanedTextContent
                            .replace(/<br>/g, '\n') // Replace <br> with newlines
                            .replace(/&nbsp;/g, ' ') // Replace non-breaking spaces with regular spaces
                            .replace(/##/g, '')
                            .replace(/\*\*/g, '')
                            .replace(/<[^>]*>/g, ''); // Remove any remaining HTML tags


                        // Create clipboard item with both text/html and text/plain
                        const clipboardItem = new ClipboardItem({
                            'text/html': new Blob([cleanedContentWithHtmlFormatting], { type: 'text/html' }),
                            'text/plain': new Blob([plainTextContent], { type: 'text/plain' }), // For Notepad compatibility
                        });

                        await navigator.clipboard.write([clipboardItem]);
                        toast.success('Content has been copied to your clipboard! \r\n\r\nNote: You may need to click "Allow" when prompted by your browser to enable clipboard access for www.ocesha.com.', {
                            style: { whiteSpace: 'pre-line', width: '300px' }, autoClose: 15000,
                        });
                        /* try {
                             const clipboardItem = new ClipboardItem({
                                 'text/html': new Blob([cleanedContent], { type: 'text/html' }),
                             });
 
                             await navigator.clipboard.write([clipboardItem]);
                             toast.success('Content copied to clipboard with formatting!');
                         } catch (err) {
                             console.error('Failed to copy content: ', err);
                             toast.error('Failed to copy content!');
                         } */
                    } else {
                        await navigator.clipboard.writeText(response.data);
                        toast.success('Text has been copied to your clipboard! \r\n\r\nNote: You may need to click "Allow" when prompted by your browser to enable clipboard access for www.ocesha.com.', {
                            style: { whiteSpace: 'pre-line', width: '300px' }, autoClose: 15000,
                        });

                    }
                }
                else if (file_type === 'html') {
                    try {
                       /*  const clipboardItem = new ClipboardItem({
                            'text/html': new Blob([response.data], { type: 'text/html' }),
                        });

                        await navigator.clipboard.write([clipboardItem]); */
                        let htmlContent = response.data;
                        if (htmlContent.startsWith('"') && htmlContent.endsWith('"')) {
                            htmlContent = htmlContent.slice(1, -1);
                        }

                        //Removing all \n and \t
                        htmlContent = htmlContent.replace(/\\n/g, '').replace(/\n/g, '').replace(/\\t/g, '').replace(/\t/g, '');

                        //Unescaping double quotes \" to "
                        htmlContent = htmlContent.replace(/\\"/g, '"');
                        //Unescaping double quotes \\s to \s
                        htmlContent = htmlContent.replace(/\\\\s/g, '\\s').replace(/\\\\\./g, '\\.');

                        //copying the result to clipboard
                        await navigator.clipboard.writeText(htmlContent);
                        toast.success('HTML has been copied to your clipboard! \r\n\r\nNote: You may need to click "Allow" when prompted by your browser to enable clipboard access for www.ocesha.com.', {
                            style: { whiteSpace: 'pre-line', width: '300px' }, autoClose: 15000,
                        });
                    } catch (error) {
                        console.error('Failed to copy HTML content:', error);
                        toast.error('Failed to copy HTML content!');
                    }
                }/* {
                    toast.success('HTML has been copied to your clipboard! \r\n\r\nNote: You may need to click "Allow" when prompted by your browser to enable clipboard access for www.ocesha.com.', {
                        style: { whiteSpace: 'pre-line', width: '300px' }, autoClose: 15000,
                    });
                } */
            } catch (error) {
                console.log('Unexpected Error:', error);
            } finally {
                setIsDownloadLoading(null);
            }
        } else {
            setUpgradeModal(true);
        }
    };


    const handlemagicIcoClick = async (mode: any) => {
        if (planDetails?.distribution_to_social_media_platform === true) {
            try {
                setIsLoadingEditPost(mode);
                const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
                const response = await axios.post(
                    `${apiUrl}/api/prompts/create_hash_tags?blog_id=${blogIdForExport}&social_media=${mode}`,
                    null,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
                if (response.data.status === 'Success') {
                    if (response.data.data) {
                        setSharingPostData(response.data.data);
                    } else {
                        setIsLoadingEditPost(null);
                        toast.error('Error fetching data!');
                    }
                } else {
                    setIsLoadingEditPost(null);
                    toast.error('hashtags not created, try again later!');
                }
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoadingEditPost(null);
                setOpenPostEdit(true);
            }
        } else {
            setUpgradeModal(true);
        }
    }


    // const useremail = localStorage.getItem('user_email') ?? '';
    const useremail = localStorage.getItem('user_email') ? localStorage.getItem('user_email') : userEmail ? userEmail : getCookie('user_email');



    const handleViewClick = (blogId: string, productId: string, mode: string, statichtml: boolean, canonical_url: string) => {
        console.log('view click blog id=>', blogId);
        console.log('canoni url=>', canonical_url);
        localStorage.setItem('prevImg', '');
        let url = `/PreviewBlogs1/${blogId}?campId=${productId}`;
        // if (mode == "Live" || mode == 'draft' && statichtml == true)
        //     url = canonical_url;

        // url = url + `?campId=${productId}`

        window.open(url, '_blank');
    }

    const params = new URLSearchParams(window.location.search);
    const promptid = params.get('promptid') ?? "";
    const [selectedCampaign, setSelectedCampaign] = useState('');
    const [campaignListDatas, setCampaignListDatas] = useState<any>();
    const [campaignListMutation] = useCampaignListMutation();
    const [blogGenerationStatusMutation] = useGetBlogGenerationStatusMutation();
    const [generationStatus, setGenerationStatus] = useState<Boolean>(true);
    const [metricStatus, setMetricStatus] = useState('');
    const [totalMetric, setTotalMetric] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 12;
    const domain = window.location.hostname;
    const getBlogsCount = useBlogsCountQuery({ promptid: promptid, userid: useremail || getCookie('user_email') || '', cat: "", query: "", domain: domain });
    const getCampaignBlogsCount = useCampaignBlogsCountQuery({ campaignid: selectedCampaign || '', userid: useremail || getCookie('user_email') || '', cat: "", query: "", domain: domain });
    const getProducts = useGetproductsDetQuery({ user_id: useremail || getCookie('user_email') || '' });
    const getBlogs = useGetgeneratedBlogListingsQuery({ promptid: promptid, userid: user_email || getCookie('user_email') || '', limitval: pageSize, skipval: (currentPage - 1) * pageSize });
    const getCampaignBasedBlogs = useGetBlogsByCampaignQuery({ campaignid: selectedCampaign || '', limitval: pageSize, skipval: (currentPage - 1) * pageSize });
    const getCampaignStatusBasedBlogs = useGetBlogsByCampaignstatusQuery({ campaignid: selectedCampaign || '', limitval: pageSize, skipval: (currentPage - 1) * pageSize, status: metricStatus })
    /* for image updation frequently */
    const { data, error, refetch } = useGetgeneratedBlogListingsQuery({
        promptid: promptid,
        userid: user_email || '',
        limitval: pageSize,
        skipval: (currentPage - 1) * pageSize
    });
    /*  const { data, error, refetch } = useGetBlogsByCampaignQuery({
         campaignid: selectedCampaign || '',
         limitval: pageSize,
         skipval: (currentPage - 1) * pageSize
     }); */
    /*  */
    // let totalPages = Math.ceil((getBlogsCount?.data ?? 0) / pageSize);
    // const [totalPages, setTotalPages] = useState(Math.ceil((getCampaignBlogsCount?.data ?? 0) / pageSize));
    const [totalPages, setTotalPages] = useState(Math.ceil((getBlogsCount?.data ?? 0) / pageSize));
    const [totalPagesFiltered, setTotalPagesFiltered] = useState(totalPages);
    type IntervalId = ReturnType<typeof setInterval>;
    const intervalIdRef = useRef<IntervalId | null>(null);
    const [query, setQuery] = useState('');
    const [images, setImages] = useState<UnsplashImage[]>([]);
    const [unsplashSearch, setUnsplashSearch] = useState<boolean>(false);
    const [findText, setFindText] = useState('');
    const [replaceText, setReplaceText] = useState('');
    const [updatedText, setUpdatedText] = useState('');
    const [matches, setMatches] = useState<number[]>([]);
    const [currentMatchIndex, setCurrentMatchIndex] = useState<number>(-1);
    /* for image updation frequently */
    /*  useEffect(() => {
         const intervalId = setInterval(() => {
             refetch(); 
         }, 5000);
         return () => clearInterval(intervalId);
     }, []); */

    const campaignRes = async () => {
        try {
            const response = await campaignListMutation({ email: user_email || '' });
            if ('data' in response) {
                setCampaignListDatas(response.data);
                console.log('campaign list response=>', response.data)
            } else {
                //console.error('Error fetching campaign List:', response.error);
            }
        } catch (error) {
            //console.log('Error fetching campaign List:', error);
        }
    }


    const handlecampaignSelectionChange = (event: any, fromCampaign: any, fromUpdatedCampaign: any) => {
        localStorage.removeItem('publishingCampId');
        const selectedValue = event.target.value || fromCampaign || fromUpdatedCampaign;
        // setSelectedCampaign(event.target.value);
        setSelectedCampaign(selectedValue);
        //console.log('Selected campaign:', event.target.value);//getting selected campaign id here
        // fetchingPublishDatas(event.target.value);
        fetchingPublishDatas(selectedValue);
        localStorage.setItem('campId', selectedValue);
        // localStorage.setItem('selectedCamp', selectedValue);
    };

    useEffect(() => {
        const publishingblogCampId = localStorage.getItem('publishingCampId');
        if (publishingblogCampId) {
            setSelectedCampaign(publishingblogCampId);
            fetchingPublishDatas(publishingblogCampId);
        }
        else if (FromCampaign) {

            setSelectedCampaign(FromCampaign);
            fetchingPublishDatas(FromCampaign);
            localStorage.setItem('campId', FromCampaign);

            const count = 0;
            localStorage.setItem('refreshCount', count.toString());

            const refreshPage = () => {
                const refreshCountStr = localStorage.getItem('refreshCount');
                const refreshCountt = refreshCountStr !== null ? parseInt(refreshCountStr) : 0;

                if (refreshCountt < 1) {
                    setTimeout(() => {
                        localStorage.setItem('refreshCount', (refreshCountt + 1).toString());
                        // window.location.reload();
                        setSelectedCampaign(FromCampaign);
                        fetchingPublishDatas(FromCampaign);

                        // processBlogs();

                    }, 20000);
                }
            };
            refreshPage();

            // **Set FromCampaign to null**
            navigate(location.pathname, {
                replace: true,
                state: { ...location.state, FromCampaign: null },
            });

        } else if (FromUpdatedCampaign) {
            setSelectedCampaign(FromUpdatedCampaign);
            fetchingPublishDatas(FromUpdatedCampaign);
            localStorage.setItem('campId', FromUpdatedCampaign);
        } else if (campaignListDatas && campaignListDatas.length > 0 /* && !selectedCampaign */) {
            const savedCampId = localStorage.getItem('campId');
            if (!savedCampId) {
                // Only set to the 0th index if no other campaign was previously selected
                setSelectedCampaign(campaignListDatas[0].campaign_id);
                fetchingPublishDatas(campaignListDatas[0].campaign_id);
                localStorage.setItem('campId', campaignListDatas[0].campaign_id);
            } else {
                setSelectedCampaign(savedCampId);
                fetchingPublishDatas(savedCampId);
            }
            /* setSelectedCampaign(campaignListDatas[0].campaign_id);
            fetchingPublishDatas(campaignListDatas[0].campaign_id);
            localStorage.setItem('campId', campaignListDatas[0].campaign_id); */

        }
    }, [campaignListDatas, FromCampaign, FromUpdatedCampaign, location, navigate])

    useEffect(() => {
        campaignRes();
    }, []);

    const checkForUnupdatedImages = () => {
        //const UnupdatedImage = data && data.some((blog: any) => blog.image_updated === 'No');
        //if (UnupdatedImage) {
        refetch();
        timestampInMilliseconds = Date.now();
        milliSeconds = Math.floor(timestampInMilliseconds / 1000);
        setMilliSeconds(milliSeconds);
        //}
    }

    const BlogGenerationStatus = async () => {
        try {
            const response = await blogGenerationStatusMutation({ campaignid: localStorage.getItem('campId') || FromCampaign/* selectedCampaign || FromCampaign || '' */ });

            if ('data' in response) {
                const currentStatus = response.data.status;
                const imageUpdated = Boolean(currentStatus);

                setGenerationStatus(response.data.status);
                if (response.data.status == false) {
                    //alert("varImageUpdated=="+varImageUpdated);
                    checkForUnupdatedImages();
                    fetchingPublishDatas(localStorage.getItem('campId') || '');


                }

                //check still image is not refreshed or not and call the list api again
                generatedBlogDetail.map((blog: any, index: any) => {
                    // Check some data within each blog
                    if (blog.blog_image_path.indexOf("/images/blogs12.png") > -1) {
                        checkForUnupdatedImages();
                        fetchingPublishDatas(localStorage.getItem('campId') || '');
                        console.log(`Blog ${blog.blog_image_path} is published:`);
                    }

                });



                // if (response.data.status == true && varImageUpdated == true){
                //     clearInterval(intervalIdRef.current as IntervalId);
                // }

                //console.log('generation status response=>', response.data.status)
            } else {
                //console.error('Error fetching     blog generation status:', response.error);
            }
        } catch (error) {
            //console.log('Error fetching blog generation status:', error);
        }
    }

    useEffect(() => {
        intervalIdRef.current = setInterval(() => {
            BlogGenerationStatus();
        }, 5000);
        return () => clearInterval(intervalIdRef.current as IntervalId);
    }, []);

    useEffect(() => {
        let timerId: any;
        const checkForUnupdatedImages = () => {
            const UnupdatedImage = data && data.some((blog: any) => blog.image_updated === 'No');
            if (UnupdatedImage) {
                refetch();
                timestampInMilliseconds = Date.now();
                milliSeconds = Math.floor(timestampInMilliseconds / 1000);
                setMilliSeconds(milliSeconds);
            }
        }

        if (generationStatus === false) {

            checkForUnupdatedImages();

            if (error) {
                //console.log(error);
            }
            /* 
            checkForUnupdatedImages();
            setInterval(checkForUnupdatedImages, 5000);
            return () => clearInterval(timerId); */

            timerId = setInterval(() => {
                checkForUnupdatedImages();
                const unupdatedImageExists = data && data.some((blog: any) => blog.image_updated === 'No');
                if (!unupdatedImageExists) {
                    clearInterval(timerId);
                }
            }, 5000);
        }
        return () => clearInterval(timerId);
    }, [data, error, refetch]);

    /* useEffect(() => {
        if (error) {
            console.error('Error fetching blogs:', error);
        }

        if (data) {
            data.forEach((blog: IGetBlogsAdmin) => {
                if (blog.image_updated === 'Yes') {
                    setGeneratedBlogDetail(blog);
                } else {
                    console.log(`Image for blog with ID ${blog._id} is not updated.`);
                }
            });
        }
    }, [data, error]); */
    /*  */

    useEffect(() => {

        if (getBlogs && getBlogs.data) {



            const updatedBlogs = getBlogs.data.map((blog: any, index: any) => ({
                ...blog,
                serialNumber: (currentPage - 1) * pageSize + index + 1,
            }));
            if (selectedBlogTitle) {
                const filtered = updatedBlogs.filter((blog: any) => blog.blog_title === selectedBlogTitle);
                const paginatedBlogs = filtered.slice(
                    (currentPage - 1) * pageSize,
                    currentPage * pageSize
                );
                setGeneratedBlogDetail(paginatedBlogs);
                setTotalPagesFiltered(Math.ceil(filtered.length / pageSize));
                console.log('total pages if filtered', totalPagesFiltered);
                /* setGeneratedBlogDetail(filtered);

                const totalPagesFiltered = Math.ceil(filtered.length / pageSize);
                setTotalPagesFiltered(totalPagesFiltered); */
            }
            else if (fromDate || toDate) {
                const fromDateObj = fromDate ? new Date(fromDate).setHours(0, 0, 0, 0) : null;
                const toDateObj = toDate ? new Date(toDate).setHours(23, 59, 59, 999) : null;

                const filteredBlogs = updatedBlogs.filter((blog: any) => {
                    const blogDate = new Date(blog.created_date).setHours(0, 0, 0, 0);

                    if (fromDateObj && toDateObj) {
                        return blogDate >= fromDateObj && blogDate <= toDateObj;
                    } else if (fromDateObj) {
                        return blogDate >= fromDateObj;
                    } else if (toDateObj) {
                        return blogDate <= toDateObj;
                    }
                    return true; // If no date filters are applied, return all
                });

                const paginatedBlogs = filteredBlogs.slice(
                    (currentPage - 1) * pageSize,
                    currentPage * pageSize
                );

                setGeneratedBlogDetail(paginatedBlogs);
                setTotalPagesFiltered(Math.ceil(filteredBlogs.length / pageSize));
            }
            /* else if (selectedDate) {
                const selectedDateString = new Date(selectedDate).toLocaleDateString('en-US');;

                const filteredBlogs = updatedBlogs.filter((blog: any) => {
                    const blogDate = new Date(blog.created_date).toLocaleDateString('en-US');
                    return blogDate === selectedDateString;
                });
                const paginatedBlogs = filteredBlogs.slice(
                    (currentPage - 1) * pageSize,
                    currentPage * pageSize
                );
                setGeneratedBlogDetail(paginatedBlogs);
                setTotalPagesFiltered(Math.ceil(filteredBlogs.length / pageSize));
            } */ else {
                const paginatedBlogs = updatedBlogs.slice(
                    (currentPage - 1) * pageSize,
                    currentPage * pageSize
                );
                setGeneratedBlogDetail(paginatedBlogs);
                setTotalPages(Math.ceil(getCampaignBlogsCount?.data / pageSize));
            }
        }
    }, [getBlogs, currentPage, selectedBlogTitle, pageSize]);

    /* const processBlogs = () => {
        if (selectedCampaign && getCampaignBasedBlogs && getCampaignBasedBlogs.data) {
            const updatedcampaignBlogs = getCampaignBasedBlogs.data.map((blog, index) => ({
                ...blog,
                serialNumber: (currentPage - 1) * pageSize + index + 1,
            }));
            //console.log('campaignBlogs=>', updatedcampaignBlogs);
            // Apply filtering if a blog title is selected
            if (selectedCampaign) {
                const filtered = updatedcampaignBlogs.filter((blog) => blog.product_id === selectedCampaign);
                setGeneratedBlogDetail(filtered);
                console.log('filtered val=>', filtered);
                const totalPagesFiltered = Math.ceil(filtered.length / pageSize);
                setTotalPagesFiltered(totalPagesFiltered);

            } else {
                setGeneratedBlogDetail(updatedcampaignBlogs);
                setTotalPages(Math.ceil(updatedcampaignBlogs.length / pageSize));
            }
        }
    }; */

    /* const processMetricStatusBasedBlogs = () => {
        if (selectedCampaign && getCampaignStatusBasedBlogs && getCampaignStatusBasedBlogs.data) {
            const updatedcampaignBlogs = getCampaignStatusBasedBlogs.data.map((blog, index) => ({
                ...blog,
                serialNumber: (currentPage - 1) * pageSize + index + 1,
            }));
            //console.log('campaignBlogs=>', updatedcampaignBlogs);
            // Apply filtering if a blog title is selected
            if (selectedCampaign) {
                const filtered = updatedcampaignBlogs.filter((blog) => blog.product_id === selectedCampaign);
                setGeneratedBlogDetail(filtered);
                console.log('filtered val=>', filtered);
                const totalPagesFiltered = Math.ceil(filtered.length / pageSize);
                setTotalPagesFiltered(totalPagesFiltered);

            } else {
                setGeneratedBlogDetail(updatedcampaignBlogs);
                setTotalPages(Math.ceil(updatedcampaignBlogs.length / pageSize));
            }
        }
    }; */

    /* useEffect(() => {
        if (metricStatus) {
            processMetricStatusBasedBlogs();
        } else {
            setTotalMetric('Total');
            processBlogs();
        }
    }, [getCampaignBasedBlogs, getCampaignStatusBasedBlogs, currentPage, selectedCampaign, pageSize, metricStatus]); */

    useEffect(() => {
        if (userDets?.subscription_level === '' && generatedBlogDetail?.length > 0) {
            setToUpgrade(true);
            setUpgradeModal(true);
        } else if (generatedBlogDetail?.length <= 0) {
            setHelperVideoSrc("/images/My_Blogs_Page.mp4");
            setHelperPopup(true);
        }
    }, [userDets?.subscription_level, generatedBlogDetail?.length]);

    const handlePublishedClick = () => {
        setMetricStatus('Live');
        setTotalMetric('');
    };

    const handleUnpublishedClick = () => {
        setMetricStatus('draft');
        setTotalMetric('');
    };

    /* const handleTotalMetricClick = () => {
        setTotalMetric('Total');
        setMetricStatus('');
        processBlogs();
    } */

    /* useEffect(() => {
        processBlogs();
    }, [getCampaignBasedBlogs, currentPage, selectedCampaign, pageSize, generatedBlogDetail]); */


    useEffect(() => {
        if (getProducts) {
            setProductDetail(getProducts.data);

            const productDet = getProducts.data?.map((product: any, index: any) => ({
                ...product
            }));

            setProductDetail(productDet)

        }
    }, [getProducts]);


    useEffect(() => {
        //console.log("productDetail....");

        const producturl = productDetail ? productDetail.map((product: any) => product.url ?? '') : '';
        setProductURL(producturl[0]);

    }, [productDetail])

    const dropdownList = generatedBlogDetail ? generatedBlogDetail.map((blog: any) => blog.blog_title || blog.blog_title_draft) : [];

    const handleToggleClick = () => {
        navigate('/codegenie/blogs-list');
    }
    const handleToggleClick1 = () => {
        localStorage.setItem('activeItem', 'blogs');
        navigate('/codegenie/generated-blogs');
    }

    const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            setImageFile(file);

            const objectUrl = URL.createObjectURL(file);
            setBlogImg(objectUrl);
        }
    };

    const accessKey = 'hT9C64LpMAlpDRMJ-Wbt8IBbUKICCu7JfbLjxmebTvo';

    const handleAuthorImageChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            setAuthorImgFile(file);

            const objectUrl = URL.createObjectURL(file);
            setAuthorImg(objectUrl);
        }
    };

    const handleSearch = async () => {
        setUnsplashSearch(true);
        if (!query) {
            toast.error('Please enter any value to search');
        } else {
            try {
                // const response = await axios.get(`https://api.unsplash.com/search/photos`, {
                const response = await axios.get(`https://api.unsplash.com/search/photos?client_id=${accessKey}`, {
                    params: { query, per_page: 20 },
                    /* headers: {
                        Authorization: `Client-ID ${accessKey}`
                    } */
                });
                setImages(response.data.results);
            } catch (error) {
                console.error("Error fetching images from Unsplash", error);
            }
        }
    };

    const horizontalImages = images.filter(image => image.width > image.height);

    const handleSelectUnsplashImage = async (imageUrl: any, imageid: any) => {
        setSelectedUnsplashImg(imageid);
        setBlogImg(imageUrl);

        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const file = new File([blob], "unsplash_image.jpg", { type: blob.type });

        setImageFile(file);
    };

    useEffect(() => {
        return () => {
            if (blogImg) {
                URL.revokeObjectURL(blogImg);
            }
        };
    }, [blogImg]);

    const handleUnsplashselectionChange = () => {
        if (!blogImg) {
            toast.error('Please select any image!');
        } else {
            setOpenUnsplash(false);
        }
    }

    const handlesaveDraft = async () => {
        /* const updatedBlog = await blogDetailsDraftMutation({
            id: selectedBlog?._id || '',
            blog_title: updateblogs.blog_title,
            blog_image_string: updateblogs.blog_image_string,
            blog_image: updateblogs.blog_image_string,
            blog_summary: updateblogs.blog_summary,
            blog_det: updateblogs.blog_det,
            url: updateblogs.url,
            author: updateblogs.author,
            author_image: updateblogs.author_image,
            author_url: updateblogs.author_url,
            cta_label: updateblogs.cta_label,
            cta_url: updateblogs.cta_url
        });
        console.log(updatedBlog); */
        setIsDraftOrPublishLoading(true);
        const formData = new FormData();
        formData.append('id', selectedBlog?._id || '');
        formData.append('blog_title', updateblogs.blog_title);
        formData.append('blog_image_string', updateblogs.blog_image_string);
        if (imageFile) {
            formData.append('blog_image', imageFile);
        }
        formData.append('blog_summary', updateblogs.blog_summary);
        formData.append('blog_det', updateblogs.blog_det);
        formData.append('url', updateblogs.url);
        formData.append('cta_url', updateblogs.cta_url);
        formData.append('cta_label', updateblogs.cta_label);
        formData.append('author', updateblogs.author);
        // formData.append('author_image', updateblogs.author_image);
        if (authorImgFile) {
            formData.append('autor_image', authorImgFile);
        }
        formData.append('author_url', updateblogs.author_url);
        formData.append('videotag', updateblogs.videotag);

        /* if ('data' in updatedBlog) {
            const datas = updatedBlog.data.status;
            console.log(datas);
            if (datas === 'success') {
                toast.success('Blog saved to draft Successfully!!');
                window.location.reload();
            } else {
                toast.error('Something went wrong!');
            }
        } */
        try {
            const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
            const response = await axios.post(apiUrl + '/api/prompts/savecontentasdraft', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.data.status === 'success') {
                refetch();
                timestampInMilliseconds = Date.now();
                milliSeconds = Math.floor(timestampInMilliseconds / 1000);
                setMilliSeconds(milliSeconds);
                // toast.success('Blog saved to draft Successfully!!');
                toast.success('Blog saved Successfully!!');
                // window.location.reload();
            } else {
                toast.error('Something went wrong!');
            }
            setOPenEdit(false);
        } catch (error) {
            console.error('Error updating blog:', error);
        } finally {
            setIsDraftOrPublishLoading(false);
        }
    };

    const handlePublished = async () => {
        //console.log('selectedCamp=>', selectedCampaign);
        localStorage.setItem('publishingCampId', selectedCampaign);
        /* const updatedBlog = await blogDetailsPublishMutation({
            id: selectedBlog?._id || '',
            blog_title: updateblogs.blog_title,
            blog_image_string: updateblogs.blog_image_string,
            blog_image: imageFile,
            blog_summary: updateblogs.blog_summary,
            blog_det: updateblogs.blog_det,
            url: updateblogs.url,
            cta_url: updateblogs.cta_url,
            cta_label: updateblogs.cta_label,
            author: updateblogs.author,
            author_image: updateblogs.author_image,
            author_url: updateblogs.author_url
        }); */
        setIsDraftOrPublishLoading(true);
        const formData = new FormData();
        formData.append('id', selectedBlog?._id || '');
        formData.append('blog_title', updateblogs.blog_title);
        formData.append('blog_image_string', updateblogs.blog_image_string);
        if (imageFile) {
            formData.append('blog_image', imageFile);
        }
        formData.append('blog_summary', updateblogs.blog_summary);
        formData.append('blog_det', updateblogs.blog_det);
        formData.append('url', updateblogs.url);
        formData.append('cta_url', updateblogs.cta_url);
        formData.append('cta_label', updateblogs.cta_label);
        formData.append('author', updateblogs.author);
        // formData.append('author_image', updateblogs.author_image);
        if (authorImgFile) {
            formData.append('autor_image', authorImgFile);
        }
        formData.append('author_url', updateblogs.author_url);
        formData.append('videotag', updateblogs.videotag);
        try {
            const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
            const response = await axios.post(apiUrl + '/api/prompts/publishcontent', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.data.status === 'success') {
                toast.success('Blog Published Successfully!!');
                refetch();
                timestampInMilliseconds = Date.now();
                milliSeconds = Math.floor(timestampInMilliseconds / 1000);
                setMilliSeconds(milliSeconds);
                // window.location.reload();

            } else {
                toast.error('Something went wrong!');
            }


            /*  if ('data' in updatedBlog) {
                 const datas = updatedBlog.data.status;
                 if (datas === 'success') {
                     toast.success('Blog Published Successfully!!');
                     window.location.reload();
                 } else {
                     toast.error('Something went wrong!');
                 }
             } */
            setOPenEdit(false);
        } catch (error) {
            console.error('Error updating blog:', error);
        } finally {
            setIsDraftOrPublishLoading(false);
        }
    };

    // Pagination


    //


    const fetchData = async () => {
        try {
            const response = await getUserDetailsMutation({ email: useremail || getCookie('user_email') || '' });
            if ('data' in response) {
                setUserDets(response.data.data);
                console.log('userDetails=>', response.data.data);
            }
        } catch (error) {
        }
    }
    useEffect(() => {
        fetchData();
    }, []);

    /* useEffect(() => {
        if (userDets?.free_plan === 1) {
            setHelperModal(true);
        }
    }, [userDets?.free_plan]); */

    const fetchingPublishDatas = async (campaignId: string) => {
        try {
            const responseCount = await publishUnpublishCountMutation({ email: useremail || getCookie('user_email') || '', campaignid: campaignId });
            //console.log('publish count=>', responseCount);
            if ('data' in responseCount) {
                const datas = responseCount.data;
                //console.log(datas);
                setPublishedUnpublishedCount(datas);
            }
        } catch (error) {
            //console.log('Error fetching publishing counts data:', error);
        }
    }
    useEffect(() => {
        fetchingPublishDatas('');
    }, []);

    useEffect(() => {
        const handleStorageChange = () => {
            setFreePlan(localStorage.getItem('free_plan'));
            setSubscriptionLevel(localStorage.getItem('subscription_level'));
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);


    useEffect(() => {

        const { data } = getProductState;
        if (data?.plan_details) {
            //console.log(data?.plan_details);
            setPlans([...data?.plan_details]);
        }
        else
            setPlans([
                {
                    user: 1,
                    plan_name: "",
                    total_wishes: 0,
                    price: "",
                    period: "",
                    product_id: "",
                    price_id: "",
                    subscription_level: "",
                    feature: [],
                    contracts: 0,
                    type: "",
                    button_text: "",
                    save: ''
                },
            ]);
    }, [getProductState]);
    const [isWideScreen, setIsWideScreen] = useState(false);

    /* 1366x768 resolution for the section(what if you had 1000 AI agents.) */
    useEffect(() => {
        const handleResize = () => {
            setIsWideScreen(window.innerWidth <= 1366 && window.innerHeight <= 768);
        };

        handleResize(); // Initial call
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    /*  */
    useEffect(() => {
        localStorage.removeItem('prevImg');
    }, [])

    const handlePreviewBlogClick = async (blog_image: any, blog_id: string, productId: string) => {
        localStorage.setItem('prevImg', '');
        if (blog_image) {
            setPrevImgLoader(true);
            const formData = new FormData();
            formData.append('blog_image', blog_image);
            formData.append('blog_id', blog_id);
            try {
                const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
                //console.log('apiurl', apiUrl);
                const response = await axios.post(apiUrl + '/api/prompts/getBlogDetwihtImage', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                //console.log('API response:', response.data.temp_image_path);

                localStorage.setItem('prevImg', response.data.temp_image_path);
            } catch (error) {
                //console.error('Error during API call:', error);
            } finally {
                setPrevImgLoader(false);
                window.open(`/PreviewBlogs/${updateblogs.id}?campId=${productId}`, '_blank');
            }
        } else {
            window.open(`/PreviewBlogs/${updateblogs.id}?campId=${productId}`, '_blank');
        }
    };

    const handleCTALabelChange = (e: any) => {
        const newValue = e.target.value;
        setPlanDetails((prevDetails: any) => ({
            ...prevDetails,
            cta_lable: newValue
        }));
        setUpdateBlogs(prevState => ({
            ...prevState,
            cta_label: newValue
        }));
    }

    const handleCTALinkChange = (e: any) => {
        const newValue = e.target.value;
        setPlanDetails((prevDetails: any) => ({
            ...prevDetails,
            cta_link: newValue
        }));
        setUpdateBlogs(prevState => ({
            ...prevState,
            cta_url: newValue
        }));
    }

    const handlevideochange = (event: any) => {
        const newVideotag = event.target.value;
        setUpdateBlogs((prevBlogs) => ({
            ...prevBlogs,
            videotag: newVideotag,
        }));
    };


    const modifyImageUrl = (url: any) => {
        let modifiedUrl = new URL(url);

        modifiedUrl.searchParams.set('fit', 'none');

        modifiedUrl.searchParams.set('w', '1152');

        modifiedUrl.searchParams.set('h', '500');

        return modifiedUrl.toString();
    };

    const handleMenuOpenClick = (index: any) => {
        if (isMenuDropdownOpen === index) {
            setIsMenuDropdownOpen(null);
        } else {
            setIsMenuDropdownOpen(index);
        }
    }

    const youtubeplaceholderText = 'https://www.youtube.com/watch?v=X7sEkixKk4g&t=1s';

    const handleContinueRegClick = () => {
        navigate('/pricing', { state: { 'campaignIdFromBL': localStorage.getItem('campId'), 'from_page': 'campaign_list', 'useremail': userDets?.email || getCookie('user_email') || '' } });
    }

    useEffect(() => {
        // Initialize updatedText when selectedCampaign changes
        const selectedCampaignData = campaignListDatas?.find((campaign: any) => campaign.campaign_id === selectedCampaign);
        if (selectedCampaignData) {
            setUpdatedText(selectedCampaignData.product_desc);
        }
    }, [selectedCampaign, campaignListDatas]);

    const handleTranscribedTextAreaChange = (value: any) => {
        const updatedCampaignList = campaignListDatas.map((campaign: any) => {
            if (campaign.campaign_id === selectedCampaign) {
                return { ...campaign, product_desc: value };
            }
            return campaign;
        });
        setCampaignListDatas(updatedCampaignList);
        setUpdatedText(value);
    }

    console.log("Selected Campaign:", selectedCampaign);

    const handleTranscribedSaveClick = async (campaignid: any, productsdesc: any) => {
        setIsTranscribeEditable(false);
        try {
            const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
            const response = await axios.get(
                `${apiUrl}/api/products/update_original_text?campaignid=${campaignid}&new_product_desc=${productsdesc}`,
            );
            console.log('saved Transcribed det=>', response.data);
            if (response.data.status === 'success') {
                toast.success(response.data.data);
                // campaignRes();
                window.location.reload();
            } else {
                toast.error('updating failed!');
            }
        } catch (error) {
            console.log(error);
        }
    }

    // Handle Find and Replace
    const handleFind = () => {
        if (findText) {
            if (matches.length === 0) {
                // Find all matches
                const indices: number[] = [];
                const regex = new RegExp(findText, "gi");
                let match;

                while ((match = regex.exec(updatedText)) !== null) {
                    indices.push(match.index);
                }

                if (indices.length > 0) {
                    setMatches(indices);
                    setCurrentMatchIndex(0);
                }
            } else {
                // Move to the next match
                const nextIndex = (currentMatchIndex + 1) % matches.length;
                setCurrentMatchIndex(nextIndex);
            }
        }
    };

    const handleReplace = () => {
        if (findText && currentMatchIndex !== -1) {
            const start = matches[currentMatchIndex];
            const end = start + findText.length;

            const updatedContent =
                updatedText.substring(0, start) +
                replaceText +
                updatedText.substring(end);

            setUpdatedText(updatedContent);
            handleTranscribedTextAreaChange(updatedContent);

            // Reset matches after replace
            setMatches([]);
            setCurrentMatchIndex(-1);
        }
    };

    const handleReplaceAll = () => {
        if (findText) {
            const updatedContent = updatedText.replace(new RegExp(findText, "g"), replaceText);
            setUpdatedText(updatedContent);
            handleTranscribedTextAreaChange(updatedContent);

            // Reset matches
            setMatches([]);
            setCurrentMatchIndex(-1);
        }
    };

    const outputRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (outputRef.current) {
            const highlightedElement = outputRef.current.querySelector(`#current-match`);
            if (highlightedElement) {
                highlightedElement.scrollIntoView({ behavior: "smooth", block: "center" });
            }
        }
    }, [currentMatchIndex]);

    const getHighlightedText = () => {
        if (!findText || matches.length === 0) {
            // Return the plain text with escaped HTML to prevent issues
            return { __html: updatedText.replace(/\n/g, '<br>') };
        }

        // Build the highlighted content using a safe approach
        const regex = new RegExp(`(${findText})`, 'gi');
        const highlightedContent = updatedText.replace(regex, (match, group, offset) => {
            const isCurrentMatch = matches[currentMatchIndex] === offset;
            return `<span id="${isCurrentMatch ? "current-match" : ""}" style="background-color: ${isCurrentMatch ? "yellow" : "lightgray"
                }; font-weight: ${isCurrentMatch ? "bold" : "normal"}; color: ${isCurrentMatch ? "#000" : "#000"};">${match}</span>`;
        });

        return { __html: highlightedContent.replace(/\n/g, '<br>') };
    };

    console.log(campaignListDatas);

    const handleDeleteClick = (blogId: any) => {
        setDeleteBlogId(blogId);
        setDeleteModal(true);
    }

    const handleClick = (event: any, blogId: any, productId: any) => {
        if (openMenuId === blogId) {
            setOpenMenuId(null);
        } else {
            setOpenMenuId(blogId);
        }
        setAnchorEl(event.currentTarget);
        setBlogidofsubmenu(blogId);
        setProductIdofSubmenu(productId);
    };

    const handleClose = () => {
        setOpenMenuId(null);
    };

    const handlePublishToOceshaChange = (event: any) => {
        const isChecked = event.target.checked;
        setPublishToOcesha(isChecked);
    };

    const handlePublishToCodegenieChange = (event: any) => {
        const isChecked = event.target.checked;
        setPublishToCodegenie(isChecked);
    };

    /* useEffect(() => {
        if (FromDashboard === 'true' && subscription !== '') {
            setHelperVideoSrc("/images/My_Blogs_Page.mp4");
            setHelperPopup(true);
        }
    }, []); */

    return (
        <>

            {/*  {localStorage.getItem('FromSignIn') === 'true' && generatedBlogDetail?.length < 0 ?
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CircularProgress size={50} color="primary" />
                </div>
                : */}
            <div style={{ padding: isMobile ? '2%' : '' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '0px' }}>
                    <p style={{ fontWeight: 'bold', fontSize: '32px', color: '#fff', margin: 0, display: 'flex', alignItems: 'center' }}>My Blogs</p>
                    <Tooltip title="Click here for help video">
                        <img src={youtubeIco}
                            onClick={() => {
                                setHelperVideoSrc("/images/My_Blogs_Page.mp4");
                                setHelperPopup(true);
                            }} style={{ cursor: 'pointer', height: '40px', marginTop: '8px' }} />
                    </Tooltip>
                </div>
                {/* <p style={{ fontWeight: 'bold', fontSize: '20px', color: '#fff', marginTop: '1%' }}>Last 7 days</p> */}
                <div className="row" style={{ display: 'flex',/* , flexDirection: 'row', */ }}>
                    {isMobile ?
                        <>
                            <div className="col-12">
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: '15px' }}>
                                    <Autocomplete
                                        options={dropdownList.filter((title: any) => title.toLowerCase().includes(searchQuery.toLowerCase()))}
                                        sx={{ /* paddingTop: 5, */ "& label": { color: "black" }, "& input": { className: 'red-border1' }, background: '#35353B', width: '100%' }}
                                        value={selectedBlogTitle || ''}
                                        onChange={(event, newValue) => {
                                            setSelectedBlogTitle(newValue || '');
                                            setSearchQuery(newValue || '');
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                /* style={{ border: '1px solid #CBCBCB' }} */
                                                {...params}
                                                placeholder="Search by article name..."
                                                size="small"
                                                InputLabelProps={{
                                                    className: 'red-label'
                                                }}
                                                onChange={(e) => setSearchQuery(e.target.value)}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchIcon />
                                                        </InputAdornment>
                                                    ),
                                                    sx: {
                                                        border: 'none',
                                                        outline: 'none'
                                                    }
                                                }}
                                                sx={{
                                                    '& .MuiInputBase-input::placeholder': {
                                                        color: 'white',
                                                        opacity: 1
                                                    },
                                                    '& .MuiOutlinedInput-root': {
                                                        border: 'none',
                                                        '& fieldset': {
                                                            border: 'none',
                                                        }
                                                    },
                                                }
                                                }
                                            />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="row" style={{ marginTop: '2%' }}>
                                <div className="col-12">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']} sx={{ width: '100%' }}>
                                            {/*  <DatePicker label="Filter by date"
                                        value={selectedDate}
                                        onChange={(newVal) => {
                                            const formattedDate = dayjs(newVal).format('YYYY-MM-DD');
                                            setSelectedDate(formattedDate);
                                        }}
                                        sx={{ width: '100%' }} /> */}
                                            <DatePicker
                                                label="From Date"
                                                value={fromDate ? dayjs(fromDate) : null} // Ensure proper value handling
                                                onChange={(newVal) => {
                                                    setFromDate(newVal ? dayjs(newVal).format('YYYY-MM-DD') : null);
                                                }}
                                                sx={{ width: '100%' }}
                                            />

                                            {fromDate && (
                                                <IconButton onClick={() => { setFromDate(null); setCloseDateFilter(true); }} size="small">
                                                    <CloseIcon />
                                                </IconButton>
                                            )}
                                        </DemoContainer>
                                    </LocalizationProvider>

                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']} sx={{ width: '100%' }}>
                                            <DatePicker
                                                label="To Date"
                                                value={toDate ? dayjs(toDate) : null} // Ensure proper value handling
                                                onChange={(newVal) => {
                                                    setToDate(newVal ? dayjs(newVal).format('YYYY-MM-DD') : null);
                                                }}
                                                sx={{ width: '100%' }}
                                            />
                                            {toDate && (
                                                <IconButton onClick={() => { setToDate(null); setCloseDateFilter(true); }} size="small">
                                                    <CloseIcon />
                                                </IconButton>
                                            )}
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </div>
                            </div>

                            <div className="row" style={{ marginTop: '2%' }}>
                                <div className="col-12">
                                    <button className="button_leniar" style={{ width: '100%'/* width: '65%', */ /* borderRadius: '25px' *//* , alignSelf: 'flex-start' */ }}
                                        onClick={() => {
                                            const clearLocalStorage = () => {
                                                const keysToRemove = ["campaignid", "returningtocampaign", "sourceurl", "campaignName", "paymentOption",
                                                    "autoPublish", "limitPublish", "smUrlpublish", "addTags", "captureLeads", "newsLetter", "twitterBtn", "fbBtn", "pintrestBtn", "linkedinBtn",
                                                    "video", "twitterurl", "fburl", "pintresturl", "linkedinurl", "useOwnDomain", "ChangepayMode", "IdChangingMode", "Authorurl", "Authorname",
                                                    "authorDefaultCheck", "videoTag", "podcastUrl", "sourcetext"];
                                                keysToRemove.forEach(key => localStorage.removeItem(key));
                                            };
                                            if (userDets?.mode === 'Canceled') {
                                                setUpgradeModal(true);
                                                return;
                                            }
                                            if (planDetails?.subscription_level !== undefined || planDetails !== '' || userDets.blog_count_balance > 0 || userDets.podcast_count_balance > 0) {
                                                localStorage.setItem('activeItem', 'createblog');
                                                navigate('/codegenie/new-campaign');
                                                clearLocalStorage();
                                            } else if (userDets?.blog_count_balance === 0 && userDets?.podcast_count_balance === 0) {
                                                setUpgradeModal(true);
                                            } else {
                                                localStorage.setItem('fromUpgrade', 'true');
                                                navigate('/pricing', { state: { 'campaignIdFromBL': localStorage.getItem('campId'), 'from_page': 'blog_listing', 'useremail': userEmail/* userDets?.email */ } });
                                            }
                                        }}><AddOutlinedIcon />Create Blog</button>
                                </div>
                                {/*  <div className="col-6">
                                    <Button sx={{ background: '#F9DEDC', color: '#B3261E', width: '100%' }}><TrashIco />Clear all blogs</Button>
                                </div> */}
                            </div>

                            <div className="row" style={{ marginTop: '2%' }}>
                                <div className="col-12" style={{ justifyContent: 'center', display: 'flex', alignItems: 'center', width: '100%' }}>
                                    <Pagination
                                        count={selectedBlogTitle ? totalPagesFiltered : Math.ceil((getBlogsCount?.data ?? 0) / pageSize)}
                                        page={currentPage}
                                        onChange={(event, value) => setCurrentPage(value)}
                                        color="primary"
                                        sx={{
                                            '& .MuiPaginationItem-root': {
                                                backgroundColor: '#D9D9D9',
                                                color: '#7A7A7A',
                                                borderRadius: '4px',
                                                padding: '0.4rem',
                                                fontSize: '0.875rem',
                                                margin: '0 0.2rem',
                                            },
                                            '& .Mui-selected': {
                                                backgroundColor: '#ffff',
                                                color: '#4BA5EB',
                                                border: '1px solid #4BA5EB'
                                            },
                                            '& .MuiPaginationItem-ellipsis': {
                                                backgroundColor: 'transparent',
                                                color: '#000',
                                            },
                                            '& .MuiPaginationItem-icon': {
                                                color: '#C4CDD5',
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                        </>
                        : ''}

                    <div className="col-sm-12 col-md-9 col-lg-9" style={{ marginTop: '1%' }}>
                        {(generatedBlogDetail && generatedBlogDetail.length > 0) ?
                            (generatedBlogDetail && generatedBlogDetail/* .filter((blog: any) => {
                                const createdDate = new Date(blog.created_date);
                                const sevenDaysAgo = new Date();
                                sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
                                return createdDate >= sevenDaysAgo;
                            }) */.map((blog: any, index: any) => (
                                <>
                                    <div style={{ display: isMobile ? 'block' : 'flex', gap: '20px' }}>
                                        <img src={`${blog.mode === 'draft' ? blog.blog_image_path_draft : blog.blog_image_path}?t=${timestampInSeconds}`} style={{ borderRadius: '12px', width: isMobile ? '100%' : '', maxWidth: isMobile ? '100%' : '326px', cursor: 'pointer' }} onClick={() => handleViewClick(blog._id, blog.product_id, blog.mode, blog?.statichtml, blog?.canonical_url)} />
                                        <div>
                                            <div className="row">
                                                <div className="col-sm-10 col-md-9 col-lg-9" style={{ marginTop: isMobile ? '2%' : '', cursor: 'pointer' }} onClick={() => handleViewClick(blog._id, blog.product_id, blog.mode, blog?.statichtml, blog?.canonical_url)}>
                                                    <p style={{ fontSize: '16px', fontWeight: 'bold', color: '#fff', maxWidth: isMobile ? '' : '35ch', overflowWrap: 'break-word', wordWrap: 'break-word', whiteSpace: 'normal' }}>{blog.mode === 'draft' ? blog.blog_title_draft.replace(/<\/?[^>]+(>|$)/g, '').replace(/\"/g, '').replace(/\*\*/g, '')/* .substring(0, 100) + '...' */ : blog.blog_title.replace(/<\/?[^>]+(>|$)/g, '').replace(/\"/g, '').replace(/\*\*/g, '')/* .substring(0, 100) + '...' */}</p>
                                                </div>
                                                <div className="col-sm-2 col-md-3 col-lg-3" style={{ display: 'flex', justifyContent: 'flex-end', gap: '6px', marginTop: isMobile ? '2%' : '' }}>
                                                    {!isMobile ? <NotePencilIco style={{ cursor: 'pointer' }} onClick={() => handleEditClick(blog)} />/* onClick={() => navigate('/ocesha-edit-blog', { state: { 'blog': blog } })} /> */ : ''}
                                                    {!isMobile ? <DeletIcon style={{ cursor: 'pointer' }} onClick={() => handleDeleteClick(blog._id)/* handleDelete(blog._id) */} /> : ''}
                                                    {/* <div style={{ position: 'relative', display: 'inline-block', marginTop: '-3%' }}  onClick={(e) => e.stopPropagation()}> */}
                                                    {/* <IconButton > */} <SubMenuIcon style={{ cursor: 'pointer', marginTop: '-3%' }} onClick={(e) => handleClick(e, blog._id, blog.product_id)}/*  onClick={(e) => { e.stopPropagation(); handleMenuOpenClick(index) }} */ />{/* </IconButton> */}
                                                    <Menu
                                                        anchorEl={openMenuId === blog._id ? anchorEl : null}
                                                        open={openMenuId === blog._id}
                                                        onClose={() => setOpenMenuId(null)}
                                                        PaperProps={{
                                                            style: {
                                                                backgroundColor: '#36393F',
                                                                color: '#CACBCC',
                                                                borderRadius: '5px',
                                                                minWidth: '214px',
                                                                boxShadow: 'none'
                                                            },
                                                        }}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'right',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'right',
                                                        }}
                                                    >
                                                        <MenuItem onClick={() => { handleClose(); handleEditClick(blog);/* navigate('/ocesha-edit-blog', { state: { 'blog': blog } }); */ }} style={{ color: '#CACBCC' }}>
                                                            <EditIco fontSize="small" style={{ marginRight: '8px' }} />
                                                            Edit Blog
                                                        </MenuItem>
                                                        <MenuItem onClick={() => { handleClose(); handleViewClick(blogIdofsubmenu, blog.product_id, blog.mode, blog?.statichtml, blog?.canonical_url) }}>
                                                            <ViewIco fontSize="small" style={{ marginRight: '8px' }} />
                                                            View Blog
                                                        </MenuItem>
                                                        <MenuItem onClick={() => { handleClose(); handleExportClick(blogIdofsubmenu, blog.url, blog.product_id, blog.blog_title, blog.blog_summary); }}>
                                                            <ExportIco fontSize="small" style={{ marginRight: '8px' }} />
                                                            Export
                                                        </MenuItem>
                                                        <MenuItem onClick={() => { handleClose(); handleDeleteClick(blogIdofsubmenu); }}>
                                                            <DeleteIco fontSize="small" style={{ marginRight: '8px' }} />
                                                            Delete
                                                        </MenuItem>
                                                        <MenuItem onClick={() => {
                                                            /* handleClose();  */handleRegeneratingBlogClick(blogIdofsubmenu);
                                                        }}>
                                                            {isLoading[blogIdofsubmenu] ? (
                                                                <CircularProgress size={20} style={{ marginRight: '8px', color: '#CACBCC' }} />
                                                            ) : (
                                                                <RegenerateIco fontSize="small" style={{ marginRight: '8px' }} />
                                                            )}
                                                            Regenerate Blog
                                                        </MenuItem>
                                                        {(blog?.source === 'youtube' || blog?.source === 'upload') && (
                                                            <MenuItem onClick={() => { /* handleClose(); */ handleTranscriptClick(blogIdofsubmenu); }}>
                                                                {isLoadingTranscript[blogIdofsubmenu] ? (
                                                                    <CircularProgress size={20} style={{ marginRight: '8px', color: '#CACBCC' }} />
                                                                ) : (
                                                                    <MicIcon fontSize="small" style={{ marginRight: '8px' }} />
                                                                )}
                                                                Transcript
                                                            </MenuItem>
                                                        )}
                                                        <MenuItem onClick={() => navigate('/codegenie/new-campaign', { state: { FromEdit: 'startEditing', campIdFrEdit: productIdofsubmenu, subscriptionTH: ''/* subscriptionTH */, mode: ''/* planDetails?.mode */ } })}>
                                                            <SettingsIco fontSize="small" style={{ marginRight: '8px' }} />
                                                            Blog Settings
                                                        </MenuItem>
                                                    </Menu>
                                                    <Tooltip title="Click here for help video">
                                                        <img src={youtubeIco1} style={{ cursor: 'pointer', height: '40px', marginTop: '-9px', marginLeft: '-9px' }}
                                                            onClick={() => {
                                                                setHelperVideoSrc("/images/Blog_Export.mp4");
                                                                setHelperPopup(true);
                                                            }} />
                                                    </Tooltip>
                                                    {/* </div> */}
                                                </div>
                                            </div>
                                            <p style={{ fontSize: '12px', fontWeight: 'lighter', color: '#CACBCC' }}>{`Created on: ${new Date(blog.created_date).toLocaleString('en-US', {
                                                month: 'long',
                                                day: 'numeric',
                                                year: 'numeric',
                                                hour: 'numeric',
                                                minute: 'numeric',
                                                hour12: true,
                                            })}`}</p>
                                            <p style={{ fontSize: '12px', fontWeight: 'lighter', color: '#CACBCC' }}>{`${blog.mode === 'draft' ? blog.blog_summary.slice(0, 155) + '...' : blog.blog_summary_draft.slice(0, 155) + '...'}`}</p>
                                        </div>
                                    </div>
                                    {index !== generatedBlogDetail.length - 1 && (
                                        <hr style={{ borderColor: '#737373', margin: '20px 0', width: '100%' }} />
                                    )}
                                </>
                            ))) :
                            <div>
                                {generatedBlogDetail && generatedBlogDetail.length === 0 ?
                                    <div className="alert alert-info" role="alert">
                                        {((localStorage.getItem('DirectSignup') === 'true' || localStorage.getItem('appsumo_coupon_code')) && (planDetails?.plan_name === 'Free' || planDetails?.plan_name === 'Life Time' || planDetails?.plan_name === '' || !planDetails?.plan_name) || !planDetails?.plan_name) || selectedDate ? 'No blogs generated.' : 'Our AI Agents are still working on your blogs.  Please check back in a few minutes.'}
                                    </div>
                                    :
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <CircularProgress size={50} color="primary" />
                                    </div>
                                }
                            </div>}

                        {!isMobile && generatedBlogDetail?.length > 0 ?
                            <Stack spacing={2} style={{ marginTop: '1rem', marginBottom: '1rem', alignItems: 'center' }}>
                                <Pagination
                                    count={selectedBlogTitle ? totalPagesFiltered : Math.ceil((getBlogsCount?.data ?? 0) / pageSize)}
                                    page={currentPage}
                                    onChange={(event, value) => setCurrentPage(value)}
                                    color="primary"
                                    sx={{
                                        '& .MuiPaginationItem-root': {
                                            backgroundColor: '#D9D9D9',
                                            color: '#7A7A7A',
                                            borderRadius: '4px',
                                            padding: '0.4rem',
                                            fontSize: '0.875rem',
                                            margin: '0 0.2rem',
                                        },
                                        '& .Mui-selected': {
                                            backgroundColor: '#ffff',
                                            color: '#4BA5EB',
                                            border: '1px solid #4BA5EB'
                                        },
                                        '& .MuiPaginationItem-ellipsis': {
                                            backgroundColor: 'transparent',
                                            color: '#000',
                                        },
                                        '& .MuiPaginationItem-icon': {
                                            color: '#C4CDD5',
                                        },
                                    }}
                                />
                            </Stack> : ''}
                    </div>
                    {!isMobile ? <div className="col-3">
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: '15px', marginTop: '3%' }}>
                            <Autocomplete
                                options={dropdownList.filter((title: any) => title.toLowerCase().includes(searchQuery.toLowerCase()))}
                                sx={{ /* paddingTop: 5, */ "& label": { color: "black" }, "& input": { className: 'red-border1' }, background: '#35353B', width: '100%' }}
                                value={selectedBlogTitle || ''}
                                onChange={(event, newValue) => {
                                    setSelectedBlogTitle(newValue || '');
                                    setSearchQuery(newValue || '');
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        /* style={{ border: '1px solid #CBCBCB' }} */
                                        {...params}
                                        placeholder="Search by article name..."
                                        size="small"
                                        InputLabelProps={{
                                            className: 'red-label'
                                        }}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                            sx: {
                                                border: 'none',
                                                outline: 'none'
                                            }
                                        }}
                                        sx={{
                                            '& .MuiInputBase-input::placeholder': {
                                                color: 'white',
                                                opacity: 1
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                border: 'none',
                                                '& fieldset': {
                                                    border: 'none',
                                                }
                                            },
                                        }
                                        }
                                    />
                                )}
                            />
                            <Typography sx={{ width: '100%' }}>Filter By Date Range</Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']} sx={{ width: '100%' }}>
                                    {/*  <DatePicker label="Filter by date"
                                        value={selectedDate}
                                        onChange={(newVal) => {
                                            const formattedDate = dayjs(newVal).format('YYYY-MM-DD');
                                            setSelectedDate(formattedDate);
                                        }}
                                        sx={{ width: '100%' }} /> */}
                                    <DatePicker
                                        label="From Date"
                                        value={fromDate ? dayjs(fromDate) : null} // Ensure proper value handling
                                        onChange={(newVal) => {
                                            setFromDate(newVal ? dayjs(newVal).format('YYYY-MM-DD') : null);
                                        }}
                                        sx={{ width: '100%' }}
                                    />

                                    {fromDate && (
                                        <IconButton onClick={() => { setFromDate(null); setCloseDateFilter(true); }} size="small">
                                            <CloseIcon />
                                        </IconButton>
                                    )}
                                </DemoContainer>
                            </LocalizationProvider>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']} sx={{ width: '100%' }}>
                                    <DatePicker
                                        label="To Date"
                                        value={toDate ? dayjs(toDate) : null} // Ensure proper value handling
                                        onChange={(newVal) => {
                                            setToDate(newVal ? dayjs(newVal).format('YYYY-MM-DD') : null);
                                        }}
                                        sx={{ width: '100%' }}
                                    />
                                    {toDate && (
                                        <IconButton onClick={() => { setToDate(null); setCloseDateFilter(true); }} size="small">
                                            <CloseIcon />
                                        </IconButton>
                                    )}
                                </DemoContainer>
                            </LocalizationProvider>
                            {/* <Button sx={{ background: '#F9DEDC', color: '#B3261E', width: '100%', display: 'flex', justifyContent: 'left', alignItems: 'center', gap: '3%' }}><TrashIco />Clear all blogs</Button> */}
                            <button className="button_leniar" style={{ width: '65%', borderRadius: '25px', alignSelf: 'flex-start' }}
                                onClick={() => {
                                    const clearLocalStorage = () => {
                                        const keysToRemove = ["campaignid", "returningtocampaign", "sourceurl", "campaignName", "paymentOption",
                                            "autoPublish", "limitPublish", "smUrlpublish", "addTags", "captureLeads", "newsLetter", "twitterBtn", "fbBtn", "pintrestBtn", "linkedinBtn",
                                            "video", "twitterurl", "fburl", "pintresturl", "linkedinurl", "useOwnDomain", "ChangepayMode", "IdChangingMode", "Authorurl", "Authorname",
                                            "authorDefaultCheck", "videoTag", "podcastUrl", "sourcetext"];
                                        keysToRemove.forEach(key => localStorage.removeItem(key));
                                    };
                                    if (userDets?.mode === 'Canceled') {
                                        setUpgradeModal(true);
                                        return;
                                    }
                                    if (planDetails?.subscription_level !== undefined || planDetails !== '' || userDets.blog_count_balance > 0 || userDets.podcast_count_balance > 0) {
                                        localStorage.setItem('activeItem', 'createblog');
                                        navigate('/codegenie/new-campaign');
                                        clearLocalStorage();
                                    } else if (userDets?.blog_count_balance === 0 && userDets?.podcast_count_balance === 0) {
                                        setUpgradeModal(true);
                                    } else {
                                        localStorage.setItem('fromUpgrade', 'true');
                                        navigate('/pricing', { state: { 'campaignIdFromBL': localStorage.getItem('campId'), 'from_page': 'blog_listing', 'useremail': userEmail/* userDets?.email */ } });
                                    }
                                }}><AddOutlinedIcon />Create Blog</button>
                        </div>
                    </div> : ''}
                </div>
            </div>
            {/* // } */}
            {/* popups */}
            <Modal
                open={openUnsplash}
                onClose={() => setOpenUnsplash(false)}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute" as "absolute",
                        top: "50%",
                        left: "50%",
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        transform: "translate(-50%, -50%)",
                        width: isMobile ? "90%" : "54%",
                        bgcolor: "background.paper",
                        // border: "2px solid #000",
                        // boxShadow: 24,
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 1.2)',
                        pt: 2,
                        px: 4,
                        pb: 3,
                        borderRadius: '10px',
                        overflowY: isMobile ? 'auto' : 'hidden',
                        height: isMobile ? '600px' : '650px',
                        maxHeight: '650px'
                    }}
                >
                    <div className="row" style={{ width: '100%', marginTop: '5%' }}>
                        <div className=" col-sm-12 col-md-10 col-lg-10">
                            <TextField
                                placeholder="Search Unsplash images"
                                size="small"
                                InputLabelProps={{
                                    className: 'red-label'
                                }}
                                InputProps={{
                                    /*  ...params.InputProps, */
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    '& .MuiInputBase-input::placeholder': {
                                        color: 'white',
                                        opacity: 1
                                    },
                                    width: '100%'
                                }}
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                            />
                        </div>

                        <div className="col-sm-12 col-md-2 col-lg-2" style={{ marginTop: isMobile ? '2%' : '' }}>
                            <Button variant="contained" onClick={handleSearch} sx={{ fontWeight: 'bold', width: isMobile ? '100%' : '' }}>Search</Button>
                        </div>
                        {/* <div className="col-md-8">
                        <Typography component="div">
                        <input
                            type="text"
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            placeholder="Search Unsplash images"
                            style={{
                                width: '100%',
                                padding: '10px',
                                fontSize: '1rem',
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                marginBottom: '1rem',
                            }}
                        />
                        </Typography>
                        </div>
                        <div className="col-md-4">
                        <Button variant="contained" onClick={handleSearch}>Search</Button>
                    </div> */}
                    </div>
                    {unsplashSearch === false ?
                        <div className="row" style={{ width: '100%' }}>
                            <div className="col-md-12">
                                <img src={unsplash_logo} style={{ width: '10%', display: 'block', margin: '0 auto', marginTop: '17%' }}></img>
                                <Typography variant="h4" sx={{ marginTop: '1%', textAlign: 'center' }}>Unsplash</Typography>
                                <Typography variant="h6" sx={{ marginTop: '1%', textAlign: 'center' }}>Try entering a search term, such as apple or daisy, to explore beautiful, high-resolution visuals.</Typography>{/* <br></br> */}

                                <Typography variant="h6" sx={{ textAlign: 'center' }}>Once you've found an image you're happy with, click on it to add it to your workspace.</Typography>
                                <Typography variant="h6" sx={{ textAlign: 'center' }} onClick={() => window.open('https://unsplash.com/', '_blank')}><a href="" style={{ textDecoration: 'none' }}>unsplash.com</a></Typography>
                            </div>
                        </div>
                        :
                        <div className="row" style={{ marginTop: '6%' }}>
                            <div style={{
                                display: 'grid',
                                gridTemplateColumns: isMobile ? 'repeat(3, 1fr)' : 'repeat(4, 1fr)',
                                gap: '10px',
                                justifyContent: 'center'
                            }}/* style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }} */>
                                {horizontalImages.map((image) => (
                                    <div key={image.id} className="thumbnail-container">
                                        <img
                                            className="thumbnail"
                                            key={image.id}
                                            src={modifyImageUrl(image.urls.thumb)}
                                            alt={image.alt_description}
                                            style={{ width: '100px', /* height: '100px', */ cursor: 'pointer', border: image.id === selectedUnsplashImg ? '3px solid #4BA5EB' : '' }}
                                            onClick={() => handleSelectUnsplashImage(image.urls.regular, image.id)}
                                        />
                                        <img
                                            className="full-image"
                                            src={image.urls.regular}
                                            alt={image.alt_description}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    }

                    <div className="row" style={{ marginTop: '5%' }}>
                        <div className={isMobile ? "col-6" : "col-sm-6 col-md-6 col-lg-6"}>
                            <Button variant="contained" sx={{ fontWeight: 'bold' }} onClick={handleUnsplashselectionChange/* () => setOpenUnsplash(false) */}>Select</Button>
                        </div>
                        <div className={isMobile ? "col-6" : "col-sm-6 col-md-6 col-lg-6"}>
                            <Button variant="contained" sx={{ fontWeight: 'bold' }} onClick={() => setOpenUnsplash(false)}>Close</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            <Modal
                open={openExportModal}
                onClose={() => setOpenExportModal(false)}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute" as "absolute",
                        top: "50%",
                        left: "50%",
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        transform: "translate(-50%, -50%)",
                        width: isMobile ? "90%" : "30%",
                        // bgcolor: "background.paper",
                        bgcolor: "#1F1F27",
                        // border: "2px solid #000",
                        // boxShadow: 24,
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 1.2)',
                        pt: 2,
                        px: 4,
                        pb: 3,
                        borderRadius: '5px',
                        overflowY: 'auto'/* isMobile ? 'auto' : 'hidden' */,
                        height: isMobile ? '600px' : '650px',
                        maxHeight: '650px',
                        paddingTop: 'none',
                        paddingLeft: 'none',
                        paddingRight: 'none',
                        padding: '1%',
                        '&:focus': { outline: 'none' },
                    }}
                >
                    <div className="row" style={{ width: '100%', paddingTop: '1%', paddingBottom: '1%', backgroundColor: '#4BA5EB', alignItems: 'center' }}>
                        <div className="col-2">
                            <CloseIcon style={{ color: '#fff', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => setOpenExportModal(false)} />
                        </div>
                        <div className="col-10" style={{ paddingTop: '2%' }}>
                            <h6 style={{ color: '#fff', fontWeight: 'bold' }}>Export</h6>
                        </div>
                    </div>

                    {/* <div className="row" style={{ width: '100%', paddingTop: '3%', paddingBottom: '1%', alignItems: 'center' }}>
                        <div className="col-12">
                            <Typography>Index</Typography>
                        </div>
                    </div> */}
                    {planDetails?.instant_indexing_with_google == 1 ?
                        <div className="row" style={{ width: '100%', paddingTop: '3%', paddingBottom: '1%', alignItems: 'center' }}>
                            <div className="col-6">
                                <Typography sx={{ color: '#fff', fontWeight: 'bold' }}>Publish to Google</Typography>
                            </div>
                            <div className="col-3" style={{ textAlign: 'center' }}>
                                <PlayBtn style={{ cursor: 'pointer' }} />
                            </div>
                            <div className="col-3" style={{ textAlign: 'center' }}>
                                {isLoadingCopied[blogIdForExport] ? <CircularProgress size={24} /> : <ShareOutlinedIcon style={{ border: '1px solid #4BA5EB', borderRadius: '3px', padding: '1%', color: '#4BA5EB', cursor: 'pointer' }} onClick={() => handelShareClick(blogIdForExport, 0, 0)} />}
                            </div>
                        </div>
                        : ''}

                    <div className="row" style={{ width: '100%', paddingTop: '3%', paddingBottom: '1%', alignItems: 'center' }}>
                        <div className="col-12">
                            <Typography>Link Sharing</Typography>
                        </div>
                    </div>

                    <div className="row" style={{ width: '100%', paddingTop: '3%', paddingBottom: '1%', alignItems: 'center' }}>
                        <div className="col-2">
                            <ToggleIcon style={{ color: '#fff' }} />
                        </div>
                        <div className="col-6">
                            <Typography sx={{ color: '#fff', fontWeight: 'bold' }}>Public Link</Typography>
                        </div>
                        <div className="col-4">
                            <Typography sx={{ color: '#fff', fontWeight: 'bold', cursor: 'pointer', background: '#4BA5EB', textAlign: 'center', borderRadius: '5px' }} onClick={() => handleExportPublicLinkClick('tw', '')}>{isLoadingShareLink === 'tw' ? <CircularProgress size={24} style={{ color: '#fff' }} /> : 'Copy Link'}</Typography>
                        </div>
                    </div>

                    <div className="row" style={{ width: '100%', paddingTop: '3%', paddingBottom: '1%', alignItems: 'center' }}>
                        <div className="col-12">
                            <Typography>Download</Typography>
                        </div>
                    </div>

                    <div className="row" style={{ width: '100%', paddingTop: '3%', paddingBottom: '1%', alignItems: 'center' }}>
                        <div className="col-2">
                            <ClipboardIcon sx={{ color: '#fff' }} />
                        </div>
                        <div className="col-8">
                            <Typography sx={{ color: '#fff', fontWeight: 'bold' }}>{'Copy to clipboard'}</Typography>
                        </div>
                        <div className="col-2">
                            {isDownloadLoading == 'clipboard1' ? <CircularProgress size={24} /> : <Typography sx={{ color: '#4BA5EB', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => handleDownloadFileClick('clipboard1')}>Copy</Typography>}
                        </div>
                    </div>

                    <div className="row" style={{ width: '100%', paddingTop: '3%', paddingBottom: '1%', alignItems: 'center' }}>
                        <div className="col-2">
                            <CodeIcon sx={{ color: '#fff' }} />
                        </div>
                        <div className="col-8">
                            <Typography sx={{ color: '#fff', fontWeight: 'bold' }}>HTML</Typography>
                        </div>
                        <div className="col-2">
                            {isDownloadLoading == 'html' ? <CircularProgress size={24} /> : <Typography sx={{ color: '#4BA5EB', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => handleDownloadFileClick('html')}>Copy</Typography>}
                        </div>
                    </div>

                    <div className="row" style={{ width: '100%', paddingTop: '3%', paddingBottom: '1%', alignItems: 'center' }}>
                        <div className="col-2">
                            <TextFieldsIcon sx={{ color: '#fff' }} />
                        </div>
                        <div className="col-8">
                            <Typography sx={{ color: '#fff', fontWeight: 'bold' }}>{'Text(Markdown)'}</Typography>
                        </div>
                        <div className="col-2">
                            {isDownloadLoading == 'txt' ? <CircularProgress size={24} /> : <Typography sx={{ color: '#4BA5EB', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => handleDownloadFileClick('txt')}>Copy</Typography>}
                        </div>
                    </div>

                    <div className="row" style={{ width: '100%', paddingTop: '3%', paddingBottom: '1%', alignItems: 'center' }}>
                        <div className="col-2">
                            <WordIcon style={{ color: '#fff' }} />
                        </div>
                        <div className="col-8">
                            <Typography sx={{ color: '#fff', fontWeight: 'bold' }}>{'Word(docx)'}</Typography>
                        </div>
                        <div className="col-2" style={{ justifyContent: 'center', display: 'flex' }}>
                            {isDownloadLoading == 'docx' ? <CircularProgress size={24} /> : <FileDownloadIcon sx={{ color: '#4BA5EB', cursor: 'pointer' }} onClick={() => handleDownloadFileClick('docx')} />}
                        </div>
                    </div>

                    <div className="row" style={{ width: '100%', paddingTop: '3%', paddingBottom: '1%', alignItems: 'center' }}>
                        <div className="col-2">
                            <PictureAsPdfIcon style={{ color: '#fff' }} />
                        </div>
                        <div className="col-8">
                            <Typography sx={{ color: '#fff', fontWeight: 'bold' }}>PDF</Typography>
                        </div>
                        <div className="col-2" style={{ justifyContent: 'center', display: 'flex' }}>
                            {isDownloadLoading == 'pdf' ? <CircularProgress size={24} /> : <FileDownloadIcon sx={{ color: '#4BA5EB', cursor: 'pointer' }} onClick={() => handleDownloadFileClick('pdf')} />}
                        </div>
                    </div>

                    <div className="row" style={{ width: '100%', paddingTop: '3%', paddingBottom: '1%', alignItems: 'center' }}>
                        <div className="col-12">
                            <Typography>Share</Typography>
                        </div>
                    </div>

                    <div className="row" style={{ width: '100%', paddingTop: '3%', paddingBottom: '1%', alignItems: 'center'/* , cursor: 'pointer' */ }}>
                        <div className="col-2">
                            <TwitterIcon style={{ color: '#fff' }} />
                        </div>
                        <div className="col-4">
                            <Typography sx={{ color: '#fff', fontWeight: 'bold' }}>Twitter</Typography>
                        </div>
                        <div className="col-2" onClick={() => handlePlayVideo('/images/twitter.mp4')} style={{ textAlign: 'center' }}>
                            <PlayBtn style={{ cursor: 'pointer' }} />
                        </div>
                        <div className="col-2" style={{ textAlign: 'center' }}>
                            {isLoadingEditPost === 'twitter' ? <CircularProgress size={24} /> : <AutoAwesomeOutlinedIcon style={{ border: '1px solid #4BA5EB', borderRadius: '3px', padding: '1%', color: '#4BA5EB', cursor: 'pointer' }} onClick={() => handlemagicIcoClick('twitter')} />}
                        </div>
                        <div className="col-2" style={{ textAlign: 'center' }}>
                            {isLoadingShareLink === 'tw' ? <CircularProgress size={24} /> : <ShareOutlinedIcon style={{ border: '1px solid #4BA5EB', borderRadius: '3px', padding: '1%', color: '#4BA5EB', cursor: 'pointer' }} onClick={() => handleExportShare('tw')} />}
                        </div>
                    </div>

                    <div className="row" style={{ width: '100%', paddingTop: '3%', paddingBottom: '1%', alignItems: 'center'/* , cursor: 'pointer'  */ }}>
                        <div className="col-2">
                            <FacebookIcon style={{ color: '#fff' }} />
                        </div>
                        <div className="col-4">
                            <Typography sx={{ color: '#fff', fontWeight: 'bold' }}>Facebook</Typography>
                        </div>
                        <div className="col-2" onClick={() => handlePlayVideo('/images/facebook.mp4')} style={{ textAlign: 'center' }}>
                            <PlayBtn style={{ cursor: 'pointer' }} />
                        </div>
                        <div className="col-2" style={{ textAlign: 'center' }}>
                            {isLoadingEditPost === 'facebook' ? <CircularProgress size={24} /> : <AutoAwesomeOutlinedIcon style={{ border: '1px solid #4BA5EB', borderRadius: '3px', padding: '1%', color: '#4BA5EB', cursor: 'pointer' }} onClick={() => handlemagicIcoClick('facebook')} />}
                        </div>
                        <div className="col-2" style={{ textAlign: 'center' }}>
                            {isLoadingShareLink === 'fb' ? <CircularProgress size={24} /> : <ShareOutlinedIcon style={{ border: '1px solid #4BA5EB', borderRadius: '3px', padding: '1%', color: '#4BA5EB', cursor: 'pointer' }} onClick={() => handleExportShare('fb')} />}
                        </div>
                    </div>

                    <div className="row" style={{ width: '100%', paddingTop: '3%', paddingBottom: '1%', alignItems: 'center'/* , cursor: 'pointer' */ }}>
                        <div className="col-2">
                            <LinkedInIcon style={{ color: '#fff' }} />
                        </div>
                        <div className="col-4">
                            <Typography sx={{ color: '#fff', fontWeight: 'bold' }}>LinkedIn</Typography>
                        </div>
                        <div className="col-2" onClick={() => handlePlayVideo('/images/linkedin.mp4')} style={{ textAlign: 'center' }}>
                            <PlayBtn style={{ cursor: 'pointer' }} />
                        </div>
                        <div className="col-2" style={{ textAlign: 'center' }}>
                            {isLoadingEditPost === 'linkedin' ? <CircularProgress size={24} /> : <AutoAwesomeOutlinedIcon style={{ border: '1px solid #4BA5EB', borderRadius: '3px', padding: '1%', color: '#4BA5EB', cursor: 'pointer' }} onClick={() => handlemagicIcoClick('linkedin')} />}
                        </div>
                        <div className="col-2" style={{ textAlign: 'center' }}>
                            {isLoadingShareLink === 'in' ? <CircularProgress size={24} /> : <ShareOutlinedIcon style={{ border: '1px solid #4BA5EB', borderRadius: '3px', padding: '1%', color: '#4BA5EB', cursor: 'pointer' }} onClick={() => handleExportShare('in')} />}
                        </div>
                    </div>

                    <div className="row" style={{ width: '100%', paddingTop: '3%', paddingBottom: '1%', alignItems: 'center' }}>
                        <div className="col-12">
                            <Typography>Export</Typography>
                        </div>
                    </div>

                    <div className="row" style={{ width: '100%', paddingTop: '3%', paddingBottom: '1%', alignItems: 'center' }}>
                        <div className="col-2">
                            <WPLogoBlue />
                        </div>
                        <div className="col-8">
                            <Typography sx={{ color: '#fff', fontWeight: 'bold' }}>WordPress</Typography>
                        </div>
                        <div className="col-2">
                            {isDownloadLoading == 'clipboard' ? <CircularProgress size={24} /> : <Typography sx={{ color: '#4BA5EB', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => handleDownloadFileClick(/* 'html1' *//* 'txt' */'clipboard')}>Copy</Typography>}
                        </div>
                        {/* <div className="col-2" onClick={() => handlePlayVideo('/images/wordpress.mp4')} style={{ textAlign: 'center' }}>
                            <PlayBtn style={{ cursor: 'pointer' }} />
                        </div> */}
                    </div>

                    {/* <div className="row" style={{ width: '100%', paddingTop: '3%', paddingBottom: '1%', alignItems: 'center' }}>
                        <div className="col-2">
                            <WPLogoWhite />
                        </div>
                        <div className="col-7">
                            <Typography sx={{ color: '#fff', fontWeight: 'bold' }}>WordPress.org</Typography>
                        </div>
                                                <div className="col-2">
                            {isDownloadLoading == 'html2' ? <CircularProgress size={24} /> : <Typography sx={{ color: '#4BA5EB', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => handleDownloadFileClick('html2')}>Copy</Typography>}
                        </div>
                        <div className="col-1">
                             <PlayBtn />
                        </div>
                    </div> */}

                    <div className="row" style={{ width: '100%', paddingTop: '3%', paddingBottom: '1%', alignItems: 'center'/* , cursor: 'pointer' */ }}>
                        <div className="col-2">
                            <MediumLogo /* style={{ color: '#fff' }} */ />
                        </div>
                        <div className="col-8">
                            <Typography sx={{ color: '#fff', fontWeight: 'bold' }}>Medium</Typography>
                        </div>
                        <div className="col-2">
                            {isDownloadLoading == 'clipboard2' ? <CircularProgress size={24} /> : <Typography sx={{ color: '#4BA5EB', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => handleDownloadFileClick('clipboard2')}>Copy</Typography>}
                        </div>
                        {/* <div className="col-2" onClick={() => handlePlayVideo1('/images/medium.mp4')} style={{ textAlign: 'center' }}>
                            <PlayBtn style={{ cursor: 'pointer' }} />
                        </div> */}
                    </div>

                    <div className="row" style={{ width: '100%', paddingTop: '3%', paddingBottom: '1%', alignItems: 'center'/* , cursor: 'pointer' */ }}>
                        <div className="col-2">
                            <img src={bloggerLogo} style={{ width: '50%' }} />
                        </div>
                        <div className="col-8">
                            <Typography sx={{ color: '#fff', fontWeight: 'bold' }}>Blogger</Typography>
                        </div>
                        <div className="col-2">
                            {isDownloadLoading == 'clipboard3' ? <CircularProgress size={24} /> : <Typography sx={{ color: '#4BA5EB', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => handleDownloadFileClick(/* 'html4' */'clipboard3')}>Copy</Typography>}
                        </div>
                        {/* <div className="col-2" onClick={() => handlePlayVideo2('/images/blogger.mp4')} style={{ textAlign: 'center' }}>
                            <PlayBtn style={{ cursor: 'pointer' }} />
                        </div> */}
                    </div>

                    <div className="row" style={{ width: '100%', paddingTop: '3%', paddingBottom: '1%', alignItems: 'center'/* , cursor: 'pointer' */ }}>
                        <div className="col-2">
                            {/* <MediumLogo /> */}
                            <img src={ghostLogo} style={{ width: '50%' }} />
                        </div>
                        <div className="col-8">
                            <Typography sx={{ color: '#fff', fontWeight: 'bold' }}>Ghost.org</Typography>
                        </div>
                        <div className="col-2">
                            {isDownloadLoading == 'clipboard4' ? <CircularProgress size={24} /> : <Typography sx={{ color: '#4BA5EB', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => handleDownloadFileClick(/* 'html5' */'clipboard4')}>Copy</Typography>}
                        </div>
                        {/* <div className="col-2" onClick={() => handlePlayVideo3('/images/ghost.mp4')} style={{ textAlign: 'center' }}>
                            <PlayBtn style={{ cursor: 'pointer' }} />
                        </div> */}
                    </div>
                </Box>
            </Modal>

            <Modal
                open={helperPopup}
                /* onClose={(event, reason) => {
                    if (reason !== "backdropClick") {
                        setHelperModal(false);
                    }
                }} */
                disableEscapeKeyDown
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute" as "absolute",
                        top: "50%",
                        left: "50%",
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        transform: "translate(-50%, -50%)",
                        width: isMobile ? "95%" : "70%",
                        bgcolor: "background.paper",
                        border: "2px solid #000",
                        boxShadow: 24,
                        pt: 2,
                        px: 4,
                        pb: 3,
                        borderRadius: '15px',
                        padding: '3%'
                    }}
                >
                    {/* <video
                        controls
                        style={{ width: '100%' }}
                        src={helpervideosrc}
                        autoPlay
                    /> */}
                    <iframe
                        width="100%"
                        height="500"
                        src={helpervideosrc}
                        title="Help Video"
                        frameBorder="0"
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                    ></iframe>
                    <Stack flexDirection="row">
                        <Button
                            onClick={() => setHelperPopup(false)}
                            variant="contained"
                            sx={{ mr: 2, marginTop: '15%' }}
                        >
                            Close
                        </Button>
                    </Stack>
                </Box>
            </Modal>

            <Modal open={openPostEdit}
                onClose={() => setOPenEdit(false)}
                aria-labelledby="modal-title"
                aria-describedby="modal-description">
                <Box sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "90%", // Adjusted width for responsiveness
                    maxWidth: 1000, // Maximum width
                    bgcolor: "#131825",
                    border: "none",
                    pt: 2,
                    px: 4,
                    pb: 3,
                    maxHeight: '110vh', // Limit height for smaller screens
                    overflow: 'auto'
                }}>
                    <Stack width="100%">
                        <Grid container>
                            <Grid item xs={12} lg={8}>
                                <Typography variant='h5' fontWeight="bold" style={{ color: '#fff' }}>Edit Post Content</Typography>
                            </Grid>
                            <Grid item xs={12} lg={4} style={{ gap: '10px', display: 'flex' }}>
                                <Button variant="contained" style={{ textAlign: 'right', width: '50%' }} onClick={() => {
                                    const contentToCopy = `${sharingpostdata}`;
                                    navigator.clipboard.writeText(contentToCopy).then(() => {
                                        toast.success('Data has been copied to your clipboard!\r\n\r\nNote: You may need to click "Allow" when prompted by your browser to enable clipboard access for www.ocesha.com.', {
                                            style: { whiteSpace: 'pre-line', width: '300px' }, autoClose: 15000,
                                        });
                                        //toast.success('Copied to clipboard!');
                                    }).catch((error) => {
                                        console.log('error copying edited content!', error);
                                    })
                                }}><ContentCopyIcon /> Copy</Button>
                                <Button variant="contained" style={{ textAlign: 'right', width: '50%' }} onClick={() => setOpenPostEdit(false)}>Close</Button>
                            </Grid>
                        </Grid>
                        <Divider style={{ height: '2px', backgroundColor: '#CBCBCB', marginTop: '1%' }} />

                        <Grid container>
                            <Grid item xs={12} lg={12} style={{ /* borderRight: "1px solid #CBCBCB", */ padding: '1%' }}>
                                {/*  <Stack padding="1%">
                                    <Typography style={{ color: '#fff' }}>URL</Typography>
                                    <TextField
                                        InputProps={{
                                            style: customInputStyle,
                                        }}
                                        style={{ width: '100%' }}
                                        value={sharingpostdata?.url}
                                        onChange={(e) => {
                                            const updatedurl = e.target.value;
                                            setSharingPostData({ ...sharingpostdata, url: updatedurl }); localStorage.setItem('editedposturl', updatedurl);
                                        }}></TextField>
                                </Stack> */}
                                {/*  <Stack padding="1%">
                                    <Typography style={{ color: '#fff' }}>Blog Title</Typography>
                                    <TextField
                                        InputProps={{
                                            style: customInputStyle,
                                        }}
                                        style={{ width: '100%' }}
                                        value={sharingpostdata?.blog_title}
                                        onChange={(e) => {
                                            const updatedblogtitle = e.target.value;
                                            setSharingPostData({ ...sharingpostdata, blog_title: updatedblogtitle }); localStorage.setItem('editedpostblogtitle', updatedblogtitle);
                                        }}></TextField>
                                </Stack> */}
                                <Stack padding="1%">
                                    <Typography style={{ color: '#fff' }}>Poster Content</Typography>
                                    <TextareaAutosize style={{ background: 'transparent', border: '1px solid #CBCBCB', width: '100%', borderRadius: '3px', height: '278px', overflow: 'auto', color: '#fff' }}
                                        value={sharingpostdata}
                                        onChange={(e) => {
                                            const updatedblogsummary = e.target.value;
                                            setSharingPostData(updatedblogsummary); localStorage.setItem('editedpostblogsummary', updatedblogsummary);
                                        }} />
                                </Stack>
                                {/* <Stack padding="1%" >
                                    <Typography style={{ color: '#fff' }}>Hashtags</Typography>
                                    <Grid container >
                                        <Grid item xs={12} sm={12}>
                                            <TextareaAutosize style={{ background: 'transparent', border: '1px solid #CBCBCB', width: '100%', borderRadius: '3px', height: '68px', overflow: 'auto', color: '#4BA5EB' }}
                                                value={sharingpostdata?.hashtags}
                                                onChange={(e) => {
                                                    const updatedHashtags = e.target.value;
                                                    setSharingPostData({ ...sharingpostdata, hashtags: updatedHashtags }); localStorage.setItem('editedposthashtags', updatedHashtags);
                                                }} />
                                        </Grid>
                                    </Grid>
                                </Stack> */}
                            </Grid>
                        </Grid>

                    </Stack>
                </Box>
            </Modal>

            <Modal
                open={deteleModal}
                /* onClose={(event, reason) => {
                    if (reason !== "backdropClick") {
                        setHelperModal(false);
                    }
                }} */
                disableEscapeKeyDown
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute" as "absolute",
                        top: "50%",
                        left: "50%",
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        transform: "translate(-50%, -50%)",
                        width: isMobile ? "95%" : "40%",
                        bgcolor: "background.paper",
                        border: "2px solid #000",
                        boxShadow: 24,
                        pt: 2,
                        px: 4,
                        pb: 3,
                        borderRadius: '15px',
                        padding: '3%'
                    }}
                >
                    <Typography style={{ textAlign: 'center', fontWeight: 'bold' }}><InfoIcon sx={{ color: '#ffcc00' }} /><br />Are you sure, you want to delete this blog?</Typography>

                    <Stack flexDirection="row" sx={{ display: 'flex', gap: '10px', marginTop: '1%' }}>

                        <Button
                            variant="contained"
                            onClick={() => handleDelete(deleteBlogId)}>
                            Yes
                        </Button>

                        <Button
                            onClick={() => setDeleteModal(false)}
                            variant="contained"
                        // sx={{ mr: 2, marginTop: '15%' }}
                        >
                            No
                        </Button>
                    </Stack>
                </Box>
            </Modal>

            <Modal
                open={upgradeModal}
                /* onClose={(event, reason) => {
                    if (reason !== "backdropClick") {
                        setHelperModal(false);
                    }
                }} */
                disableEscapeKeyDown
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute" as "absolute",
                        top: "60%",
                        left: "50%",
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        transform: "translate(-50%, -50%)",
                        width: isMobile ? "95%" : "55%",
                        bgcolor: "background.paper",
                        border: "2px solid #000",
                        boxShadow: 24,
                        pt: 2,
                        px: 4,
                        pb: 3,
                        borderRadius: '15px',
                        padding: '3%'
                    }}
                >
                    <Typography style={{ textAlign: 'center' }}><InfoIcon sx={{ color: '#ffcc00' }} /><br />{toUpgrade === true ? (<>
                        <span style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>FREE TRIAL</span>
                        <br />
                        We have created your <strong>personalized, SEO, FREE blogs</strong> from your content.  You can create 1 more blog with the Free Trial
                        <br /><br />
                        You can edit these blogs and export them to social media platforms, blogging sites, or download them.
                        <br /><br />
                        <span style={{ fontWeight: 'bold' }}>Next:</span> To start using the blogs, please complete registration by selecting a plan. Each plan includes a <strong>14-day free trial</strong> during which you will <strong>NOT</strong> be billed. You can cancel at any time. If you cancel before the end of the 14-day free period, you will not be charged.

                    </>) : 'This feature is not included in your plan.  Please upgrade to have access to this feature.'}</Typography>

                    <Stack flexDirection="row" sx={{ display: 'flex', gap: '10px', marginTop: '1%' }}>

                        <Button
                            variant="contained"
                            onClick={() => {
                                const value = {
                                    _id: "",
                                    product_name: "CodeGenie",
                                    product_module: genieSelector.module ? genieSelector.module : "",
                                    userid: "",
                                    productid: ""
                                };
                                getProduct(value);
                                localStorage.setItem('fromUpgrade', 'true');
                                navigate('/pricing', { state: { campIdFromUpgrade: selectedCampaign, from_page: 'pricing', fromNonSubscribed: 'true' } });
                            }}>
                            {toUpgrade === true ? 'Continue' : 'Upgrade'}
                        </Button>

                        {toUpgrade === true ? '' : <Button
                            onClick={() => setUpgradeModal(false)}
                            variant="contained"
                        // sx={{ mr: 2, marginTop: '15%' }}
                        >
                            Close
                        </Button>}
                    </Stack>
                </Box>
            </Modal>

            <Modal open={transcriptModal}
                onClose={() => setTranscriptModal(false)}
                aria-labelledby="modal-title"
                aria-describedby="modal-description">
                <Box sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "90%", // Adjusted width for responsiveness
                    maxWidth: 1000, // Maximum width
                    bgcolor: "#131825",
                    border: "none",
                    pt: 2,
                    px: 4,
                    pb: 3,
                    maxHeight: '110vh', // Limit height for smaller screens
                    overflow: 'auto'
                }}>
                    <Stack width="100%">
                        <Grid container>
                            <Grid item xs={12} lg={12}>
                                <Typography variant='h5' fontWeight="bold" style={{ color: '#fff' }}>Transcript</Typography>
                            </Grid>
                        </Grid>
                        <Divider style={{ height: '2px', backgroundColor: '#CBCBCB', marginTop: '1%' }} />

                        <Grid container>
                            <Grid item xs={12} lg={12} style={{ /* borderRight: "1px solid #CBCBCB", */ padding: '1%' }}>

                                <Stack padding="1%">
                                    <TextareaAutosize
                                        style={{
                                            width: '100%',
                                            borderRadius: '7px',
                                            backgroundColor: 'transparent',
                                            color: '#fff',
                                            textAlign: 'justify',
                                            height: '172px',
                                            overflow: 'auto',
                                        }}
                                        value={transcribedData ? transcribedData : 'No Transcribed Text Available'}
                                        placeholder="No Transcribed Text Available"
                                        readOnly
                                    /* onChange={(e) => handleTranscribedTextAreaChange(e.target.value)} */
                                    />
                                </Stack>

                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} lg={12} style={{ /* borderRight: "1px solid #CBCBCB", */ padding: '1%', gap: '10px', display: 'flex' }}>
                                <Button variant="contained" onClick={handleCopyTranscriptClick}>Copy</Button>
                                <Button
                                    onClick={() => setTranscriptModal(false)}
                                    variant="contained"
                                // sx={{ mr: 2, marginTop: '15%' }}
                                >
                                    Close
                                </Button>

                            </Grid>
                        </Grid>

                    </Stack>
                </Box>
            </Modal>

            <Modal open={publishBlogModal}
                onClose={() => setPublishBlogModal(false)}
                aria-labelledby="modal-title"
                aria-describedby="modal-description">
                <Box sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "90%", // Adjusted width for responsiveness
                    maxWidth: 1000, // Maximum width
                    bgcolor: "#36393F",
                    border: "none",
                    pt: 2,
                    px: 4,
                    pb: 3,
                    maxHeight: '110vh', // Limit height for smaller screens
                    overflow: 'auto',
                    borderRadius: '8px',
                    boxShadow: '0px 50px 50px rgba(0, 0, 0, 0.5)'
                }}>
                    <Stack width="100%">
                        <Grid container>
                            <Grid item xs={12} lg={12}>
                                <Typography variant='h5' fontWeight="bold" style={{ color: '#fff' }}>Publish To</Typography>
                            </Grid>
                        </Grid>
                        <Divider style={{ height: '2px', backgroundColor: '#CBCBCB', marginTop: '1%' }} />

                        <Grid container>
                            <Grid item xs={12} lg={12} style={{ /* borderRight: "1px solid #CBCBCB", */ padding: '1%' }}>

                                <Stack padding="1%">
                                    <span style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={publishToOcesha}
                                                    onChange={handlePublishToOceshaChange}
                                                    style={{ color: '#fff', borderColor: '#fff' }}

                                                />
                                            }
                                            label=""
                                        />
                                        <span style={{ fontSize: '16px', display: 'block', fontWeight: 'bold' }}>Ocesha Server</span>
                                    </span>
                                </Stack>

                                <Stack padding="1%">
                                    <span style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={publishToCodegenie}
                                                    onChange={handlePublishToCodegenieChange}
                                                    style={{ color: '#fff', borderColor: '#fff' }}

                                                />
                                            }
                                            label=""
                                        />
                                        <span style={{ fontSize: '16px', display: 'block', fontWeight: 'bold' }}>Code Genie Server</span>
                                    </span>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} lg={12} style={{ /* borderRight: "1px solid #CBCBCB", */ padding: '1%', gap: '10px', display: 'flex', justifyContent: 'center' }}>
                                <Button
                                    onClick={() => PublishToServer(blogIdToPublish, publishToOcesha ? 1 : 0, publishToCodegenie ? 1 : 0)}
                                    variant="contained"
                                // sx={{ mr: 2, marginTop: '15%' }}
                                >
                                    {isLoadingCopied[blogIdToPublish] ? <CircularProgress size={24} /> : 'Publish'}
                                </Button>
                                <Button
                                    onClick={() => setPublishBlogModal(false)}
                                    variant="contained"
                                // sx={{ mr: 2, marginTop: '15%' }}
                                >
                                    Close
                                </Button>

                            </Grid>
                        </Grid>

                    </Stack>
                </Box>
            </Modal>
            {/* end popups */}
        </>
    )
}

export default Blogs_Section;