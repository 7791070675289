import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import img54 from '../../assets/newimg/logo.png';
import { isBrowser, isMobile } from "react-device-detect";
import { TextField, Typography, CircularProgress, Grid, Paper, Stack, MenuItem, Select, Button, Box } from "@mui/material";
import '../traffic_monster/generated_blogs.css';
import testimonialImg1 from '../../assets/traffic_monster/testimonialImg1.png';
import testimonialImg3 from '../../assets/traffic_monster/testimonialImg3.png';
import testimonialImg4 from '../../assets/traffic_monster/testimonialImg4.jpg';
import { useGetgeneratedBlogListingsQuery, useGetrandomBlogListingsQuery } from '../../redux/api/promptApi';
import common_bg from '../../assets/podcast_monster/common_bg.png';
import { useGenerateBusinessBlogMutation } from '../../redux/api/promptApi';
import { toast } from "react-toastify";
import axios from 'axios';
import ocesha_logo from '../../assets/ocesha_images/ocesha_logo.png';
import aboutus_section_1 from '../../assets/ocesha_images/aboutus_section_1.png';
import aboutus_section4_1 from '../../assets/ocesha_images/aboutus_section4_1.png';
import aboutus_section4_2 from '../../assets/ocesha_images/aboutus_section4_2.png';
import aboutus_section4_3 from '../../assets/ocesha_images/aboutus_section4_3.png';
import aboutus_section4_4 from '../../assets/ocesha_images/aboutus_section4_4.png';
import aboutus_section4_5 from '../../assets/ocesha_images/aboutus_section4_5.png';
import aboutus_section4_6 from '../../assets/ocesha_images/aboutus_section4_6.png';
import aboutus_section_6 from '../../assets/ocesha_images/aboutus_section_6.png';
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";

const OceshaAboutUs = () => {

    const navigate = useNavigate();
    const params = new URLSearchParams(window.location.search);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentIndexTextTesti, setCurrentIndexTextTesti] = useState(0);
    const [isWideScreen, setIsWideScreen] = useState(false);
    const [isIpadMini, setIsIpadMini] = useState(false);
    const [randomInteger, setRandomInteger] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [blogs, setBlogs] = useState<any>();
    const [randomBlogs, setRandomBlogs] = useState<any>();
    const [httpsVal, setHttpsVal] = useState('https://');
    const [businessBlogMutation] = useGenerateBusinessBlogMutation();
    const [otherOption, setOtherOption] = useState(false);
    const [uploadVideo, setUploadVideo] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [uploadedvideopath, setUploadedvideopath] = useState('');
    const [arrowClick1, setArrowClick1] = useState<any>();
    const [arrowClick2, setArrowClick2] = useState<any>();
    const [arrowClick3, setArrowClick3] = useState<any>();
    const [urlPrefix, setUrlPrefix] = useState<string>(/* 'https://' */'youtubeid');
    const [businessUrl, setBusinessUrl] = useState('');
    const [userBusinessText, setUserBusinessText] = useState('');
    const [youtubeUrl, setYoutubeUrl] = useState('');
    const [isScrapcheckloading, setIsScrapcheckloading] = useState<boolean>(false);
    const [contactname, setContactName] = useState<any>();
    const [contactemail, setContactEmail] = useState<any>();
    const [contactPhno, setContactPhNo] = useState<any>();
    const [contactMessage, setContactMessage] = useState<any>();
    const [isContacting, setIsContacting] = useState<boolean>(false);

    const sections = useRef<(HTMLDivElement | null)[]>([]);

    useEffect(() => {
        const observerOptions = {
            threshold: 0.2, // threshold based on when want the animation to trigger
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible'); // Add 'visible' class when in view
                } else {
                    entry.target.classList.remove('visible'); // Remove class when out of view
                }
            });
        }, observerOptions);

        sections.current.forEach((section) => {
            if (section instanceof Element) {
                observer.observe(section);
            }
        });

        return () => {
            sections.current.forEach((section) => {
                if (section instanceof Element) {
                    observer.unobserve(section);
                }
            });
        };
    }, []);

    const promptid = params.get('promptid') ?? "";

    const videotestimonialData = [
        {
            // image: testimonial_videoImg1,
            video: "https://www.youtube.com/embed/mEyC24nC73k",
            name: "Jay Levin",
            title: "CEO Equip Our Kids LA Weekly Magazine"
        },
        {
            // image: testimonial_videoImg2,
            video: "https://www.youtube.com/embed/bQ694TD-QCE",
            name: "Giovanni Villa",
            title: "CEO Bella2Bello"
        },
        {
            // image: testimonial_videoImg2,
            video: "https://www.youtube.com/embed/KLLaclzl5r0",
            name: "Paul Claxton",
            title: "Managing General Partner"
        }

    ];
    const testimonialCount = videotestimonialData.length;
    /* video testimonial */
    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 3 + testimonialCount) % testimonialCount);
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 3) % testimonialCount);

    };

    const getDisplayedTestimonials = () => {
        let displayedTestimonials = [];
        for (let i = 0; i < 3; i++) {
            displayedTestimonials.push(videotestimonialData[(currentIndex + i) % testimonialCount]);
        }
        return displayedTestimonials;
    };

    const handlePrevMob = () => {
        setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    };

    const handleNextMob = () => {
        setCurrentIndex((prevIndex) => (prevIndex === videotestimonialData.length - 1 ? 0 : (isMobile ? prevIndex + 1 : 0)));
    };

    /*  */



    /* 1366x768 resolution for the section(what if you had 1000 AI agents.) */
    useEffect(() => {
        const handleResize = () => {
            setIsWideScreen(window.innerWidth <= 1366 && window.innerHeight <= 768);
            setIsIpadMini(window.innerWidth <= 1024 && window.innerHeight <= 768);
        };

        handleResize(); // Initial call
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    /*  */

    /* Text testimonial */
    const testimonialtoshow = (isMobile) ? 1 : 3;

    var testimonialsettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: testimonialtoshow,
        slidesToScroll: 1,
        arrows: true,
    };
    const testimonials = [
        {
            name: "CRAIG SHAH/CEO",
            icon: <img src={testimonialImg4} className="img-fluid rounded-circle twitter-padding" alt="" width="49" height="44"></img>,
            content: "Amazing!",
            reference: "EVOKE"
        },
        {
            name: "KURT KUMAR/CEO",
            icon: <img src={testimonialImg3} className="img-fluid rounded-circle twitter-padding" alt="" width="49" height="44"></img>,
            content: "Traffic Monster solves a problem that every business encounters.  How do you get traffic and leads and ultimately sales to your business.",
            reference: "SAPX"
        },
        {
            name: "JAMAL WOODLEY/CEO",
            icon: <img src={testimonialImg1} className="img-fluid rounded-circle twitter-padding" alt="" width="49" height="44"></img>,
            content: "It's very impressive.  I think this is something that every entrepreneur will be excited about..",
            reference: "MINDMADE BRANDS"
        }
    ];
    const testimonialCount1 = testimonials.length;

    const handlePrevTextTesti = () => {
        setCurrentIndexTextTesti((prevIndex) => (prevIndex - 1 + testimonialCount1) % testimonialCount1);
    };

    const handleNextTextTesti = () => {
        setCurrentIndexTextTesti((prevIndex) => (prevIndex + 1) % testimonialCount1);
    };

    const getDisplayedTestimonials1 = () => {
        let displayedTestimonials = [];
        for (let i = 0; i < 3; i++) {
            displayedTestimonials.push(testimonials[(currentIndexTextTesti + i) % testimonialCount1]);
        }
        return displayedTestimonials;
    };
    /*  */

    useEffect(() => {
        // Calculate random integer when totalPages is available
        if (totalPages !== null) {
            const min = 1;
            const max = totalPages;
            const randomInteger = Math.floor(Math.random() * (max - min + 1)) + min;
            setRandomInteger(randomInteger);
        }
    }, [totalPages]);

    /* News portion Blogs */
    const supportEmail: string = process.env.REACT_APP_SUPPORT_EMAIL || '';
    const getBlogs = useGetgeneratedBlogListingsQuery({ promptid: ''/* promptid */, userid: ''/* supportEmail */, limitval: 3, skipval: randomInteger });

    useEffect(() => {
        if (getBlogs.data) {
            setBlogs([...getBlogs.data]);
        }
    }, [getBlogs]);
    /*  */

    /* random customer blogs */
    const getRandomBlogs = useGetrandomBlogListingsQuery({});

    useEffect(() => {
        if (getRandomBlogs.data) {
            setRandomBlogs([...getRandomBlogs.data]);
        }
    }, [getRandomBlogs]);
    /*  */

    // const domain = window.location.hostname;
    const handleReadMoreClick = (userid: any) => {
        window.open(`/blogs?userid=${userid}`, '_blank');
    }

    const supportUserid = process.env.REACT_APP_SUPPORT_USER_ID || '';

    const handleUnload = () => {
        localStorage.removeItem('CreatingfromLanding');
        localStorage.removeItem('UrlfromLanding');
        localStorage.removeItem('user_pass');
        localStorage.removeItem('user_name');
        // localStorage.removeItem('user_email');
    };
    window.addEventListener("beforeunload", handleUnload);

    useEffect(() => {
        localStorage.removeItem('CreatingfromLanding');
        localStorage.removeItem('UrlfromLanding');
        localStorage.removeItem('user_pass');
        localStorage.removeItem('user_name');
        // localStorage.removeItem('user_email');
    }, []);

    const clearCookies = () => {
        const cookies = document.cookie.split("; ");
        for (const cookie of cookies) {
            const [name] = cookie.split("=");
            document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;`;
        }
    };

    const clearLocalStorage = () => {
        const keysToRemove = ["msg", "complexity", "module", "subscription_level", "free_plan", "localStorage_msg", "allSet", "Blogs Generated", "remaining_wish_count", "userRole", "payment", "user_email", "logged_in", "prevImg", "fromUpgrade", "blogsCount", "Generating Count", "finalData", "user_pass", "fromNewcampaign", "campaignid", "returningtocampaign", "sourceurl", "campaignName", "paymentOption", "autoPublish", "limitPublish", "campId", "smUrlpublish", "addTags", "captureLeads", "newsLetter", "twitterBtn", "fbBtn", "pintrestBtn", "linkedinBtn", "video", "twitterurl", "fburl", "pintresturl", "linkedinurl", "owndomain", "ChangepayMode", "IdChangingMode", "user_name", "refreshCount", "publishingCampId", "Authorurl", "Authorname", "authorDefaultCheck", "appsumo_coupon_code", "podcastUrl", "AlertMessage", "CreatingfromLanding", "UrlfromLanding", "signedup", "uploadedvideo", "uploadedVideoName", "uploadStatus"];
        keysToRemove.forEach(key => localStorage.removeItem(key));
    };

    const handleGenerateBlogClick = async (url: string, userid: string, uploadedtext: string, youtubeUrl: any) => {
        clearCookies();
        localStorage.clear();
        if (url) {
            localStorage.clear();

            /* let youtubeMatch = url.match(
                /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|embed|watch)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})(?:\?.*)?/
            ); */

            // if (youtubeMatch) {
            localStorage.setItem('CreatingfromLanding', 'true');
            localStorage.setItem('UrlfromLanding', url);
            if (userid === '') {
                navigate('/signup', { state: { CreatingfromLanding: localStorage.getItem('CreatingfromLanding'), UrlfromLanding: localStorage.getItem('UrlfromLanding') } });
            }
            /* } else if (!youtubeMatch) {
                toast.error('Please enter valid youtube video url!');
            } */
        } else if (youtubeUrl) {
            const youtubeMatch = youtubeUrl?.match(
                /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|shorts\/|live\/|playlist\?list=)|youtu\.be\/)([a-zA-Z0-9_-]+)/

            );

            if (youtubeMatch) {
                localStorage.setItem('CreatingfromLanding', 'true');
                localStorage.setItem('YoutubeUrlfromLanding', youtubeUrl);
                if (userid === '') {
                    navigate('/signup', { state: { CreatingfromLanding: localStorage.getItem('CreatingfromLanding'), UrlfromLanding: localStorage.getItem('UrlfromLanding'), YoutubeUrlfromLanding: localStorage.getItem('YoutubeUrlfromLanding') } });
                }
            } else if (!youtubeMatch) {
                toast.error('Somethign went wrong, check your url format.');
            }
        }
        else if (/* localStorage.getItem('uploadStatus') == 'success' && */ userBusinessText) {
            localStorage.setItem('CreatingfromLanding', 'true');
            localStorage.setItem('uploadedText', userBusinessText);
            if (userid === '') {
                navigate('/signup', { state: { CreatingfromLanding: localStorage.getItem('CreatingfromLanding'), TextfromLanding: localStorage.getItem('uploadedText')/*  VideofromLanding: localStorage.getItem('uploadedvideo') */ } });
            }
        } else if (!url || !uploadedtext) {
            toast.error('Please enter your business url/upload text!');
        }
    }

    const handlevideoChange = async (event: any) => {
        const file = event.target.files[0];
        if (file) {
            setUploadVideo(file.name);
            console.log('Uploaded video file:', file);
            const formData = new FormData();
            formData.append('media_file', file);

            const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
            setIsLoading(true);
            try {
                const response = await axios.post(
                    `${apiUrl}/api/prompts/upload_media_file`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
                console.log(response.data.data);
                if (response.data.result === 'success' && response.data.data) {
                    localStorage.setItem('uploadedVideoName', file.name);
                    localStorage.setItem('uploadStatus', 'success');
                    setUploadedvideopath(response.data.data);
                    toast.success('File Uploaded Successfully!');

                    localStorage.setItem('CreatingfromLanding', 'true');
                    localStorage.setItem('uploadedvideo', response.data.data);
                    // if (localStorage.getItem('user_email') === '') {
                    navigate('/signup', {
                        state: {
                            CreatingfromLanding: localStorage.getItem('CreatingfromLanding'),
                            VideofromLanding: localStorage.getItem('uploadedvideo'),
                            videoNamefromLanding: localStorage.getItem('uploadedVideoName')
                        }
                    });
                    // }
                } else if (response.data.result === 'fail') {
                    toast.error(response.data.message);
                }
            } catch (error) {
                console.error('Error uploading video:', error);
                toast.error('Error uploading video');
            } finally {
                setIsLoading(false);
            }

        }
    };

    const handleTextChange = (event: any) => {
        let inputText = event.target.value;
        setUserBusinessText(inputText);
        localStorage.setItem('CreatingfromLanding', 'true');
        localStorage.setItem('uploadedText', inputText);
    };

    const handlescrapPage = async (url: string) => {
        setIsScrapcheckloading(true);
        try {
            const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
            const response = await axios.get(
                `${apiUrl}/api/prompts/scrap_page?url=${url}`,
            );
            if (response.data.result === 'failed') {
                toast.error(response.data.data);
            } else {
                handleGenerateBlogClick(url, localStorage.getItem('user_email') || '', userBusinessText, youtubeUrl);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsScrapcheckloading(false);
        }
    }

    const handleUrlPrefixChange = (event: any) => {
        const selectedValue = event.target.value;
        setUrlPrefix(selectedValue);
        console.log('URL Prefix Changed:', selectedValue);
    }

    useEffect(() => {
        console.log('urlPrefix updated:', urlPrefix);
    }, [urlPrefix]);

    const handleGetStartedClick = async () => {
        if (!contactname && !contactemail && !contactPhno && !contactMessage) {
            toast.error('Please fill your details!');
        } else {
            const formData = new FormData();
            formData.append('name', contactname);
            formData.append('email', contactemail);
            formData.append('phone', contactPhno);
            formData.append('subject', contactMessage);
            formData.append('message', contactMessage);
            try {
                setIsContacting(true);
                const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
                const response = await axios.post(apiUrl + '/api/auth/contactus', formData, {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
                if (response.data.status === 'success') {
                    toast.success(response.data.message);
                    setContactName('');
                    setContactEmail('');
                    setContactPhNo('');
                    setContactMessage('');
                } else {
                    toast.error('Please try agin later!');
                }
            } catch (error) {
                console.log('Error while contacting', error);
            } finally {
                setIsContacting(false);
            }
        }
    }

    const steps = ["Eat", "Code", "Sleep", "Repeat"];


    return (
        <>
            <div style={{ background: '#000' }}>


                {/* Header */}
                <div className="container">
                    <div className="row">
                        <nav className="navbar navbar-expand-md">
                            <div className="container-fluid">
                                <a className="navbar-brand" href="/" style={{ width: '5%' }}>
                                    {/* <img src="./img/logo.png" alt="" /> */}
                                    <img src={ocesha_logo} className="img-fluid" alt="" style={{ width: '95%' }} />

                                </a>
                                <button
                                    className="navbar-toggler"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarScroll"
                                    aria-controls="navbarScroll"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    <span className="">
                                        <i className="bi bi-list text-white" />
                                    </span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarScroll">
                                    <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll ms-md-5  gap-4">
                                        <li className="nav-item">
                                            <a
                                                className="nav-link nav_active"
                                                style={{ color: "#4ba5eb", fontWeight: 800 }}
                                                href="/"
                                            >
                                                Home
                                            </a>
                                        </li>
                                        <li className="nav-item" onClick={() => {
                                            navigate("/podcast");
                                        }} style={{ cursor: 'pointer' }}>
                                            <a className="nav-link nav_simple">
                                                Podcast
                                            </a>
                                        </li>
                                        <li className="nav-item" onClick={() => {
                                            navigate("/agency");
                                        }} style={{ cursor: 'pointer' }}>
                                            <a className="nav-link nav_simple">
                                                Agency
                                            </a>
                                        </li>
                                        <li className="nav-item" onClick={() => {
                                            navigate("/small-business");
                                        }} style={{ cursor: 'pointer' }}>
                                            <a className="nav-link nav_simple">
                                                Small Business
                                            </a>
                                        </li>
                                        <li className="nav-item" onClick={() => {
                                            localStorage.setItem('pricing_from_landing', 'true');
                                            navigate("/pricing");
                                        }} style={{ cursor: 'pointer' }}>
                                            <a className="nav-link nav_simple" /* href="https://codegenie.getrewardful.com/signup?_gl=1*wfwhnc*_gcl_au*MTMwOTQ5ODcxNy4xNjkyODU4NDk3*_ga*MTA4OTc3NTMyNC4xNjkyODUzODcz*_ga_YJYFH7ZS27*MTY5Mjg3OTg0NS40LjEuMTY5Mjg4NjM1MC41Mi4wLjA.&_ga=2.74546258.159718116.1692853906-1089775324.1692853873" */>
                                                Pricing
                                            </a>
                                        </li>
                                        {/*  <li className="nav-item" style={{ cursor: 'pointer' }}>
                                        <a className="nav-link nav_simple" href="https://trafficmonsterai.getrewardful.com/signup?revgems-session-id=45a7e1ae-a67a-4197-9973-f002c2338e37&_gl=1*159ev21*_gcl_au*MjI5MjQ3ODg2LjE3MTQ3MzU2NTE.*_ga*MTAyOTUzNzQyMC4xNzE0NzM1NjU0*_ga_YJYFH7ZS27*MTcxNTY3NjkxOC40LjEuMTcxNTY3NzIwMy41MS4wLjA">
                                            Affiliates
                                        </a>
                                    </li> */}
                                        {/* <li className="nav-item" style={{ cursor: 'pointer' }}>
                                            <a className="nav-link nav_simple" href="https://trafficmonsterai.getrewardful.com/signup">
                                                Affiliates
                                            </a>
                                        </li> */}

                                        <li className="nav-item" onClick={() => {
                                            navigate("/contactus");
                                        }} style={{ cursor: 'pointer' }}>
                                            <a className="nav-link nav_simple" /* href="https://codegenie.getrewardful.com/signup?_gl=1*wfwhnc*_gcl_au*MTMwOTQ5ODcxNy4xNjkyODU4NDk3*_ga*MTA4OTc3NTMyNC4xNjkyODUzODcz*_ga_YJYFH7ZS27*MTY5Mjg3OTg0NS40LjEuMTY5Mjg4NjM1MC41Mi4wLjA.&_ga=2.74546258.159718116.1692853906-1089775324.1692853873" */>
                                                Contact
                                            </a>
                                        </li>
                                        <li className="nav-item" onClick={() => {
                                            window.open(`/blogs`, '_blank');
                                        }} style={{ cursor: 'pointer' }}>
                                            <a className="nav-link nav_simple">
                                                Blogs
                                            </a>
                                        </li>
                                        <li className="nav-item" onClick={() => {
                                            navigate("/aboutus");
                                        }} style={{ cursor: 'pointer' }}>
                                            <a className="nav-link nav_simple" /* href="https://codegenie.getrewardful.com/signup?_gl=1*wfwhnc*_gcl_au*MTMwOTQ5ODcxNy4xNjkyODU4NDk3*_ga*MTA4OTc3NTMyNC4xNjkyODUzODcz*_ga_YJYFH7ZS27*MTY5Mjg3OTg0NS40LjEuMTY5Mjg4NjM1MC41Mi4wLjA.&_ga=2.74546258.159718116.1692853906-1089775324.1692853873" */>
                                                About Us
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="d-flex gap-4" role="search" style={{ justifyContent: isMobile ? 'center' : '' }}>
                                        <button className="btn btn-outline-primary px-3"
                                            onClick={() => {
                                                if (localStorage.getItem('logged_in') === 'true') {
                                                    navigate('/codegenie/generated-blogs', { state: { userEmail: localStorage.getItem('user_email') || '' } });
                                                } else {
                                                    navigate("/signin");
                                                }
                                            }}>Sign  In</button>
                                        <button className="button_leniar px-3" type="submit" onClick={() => {
                                            navigate("/signup", { state: { DirectSignup: 'true' } });
                                        }}>
                                            {/* Try Now */}Sign Up
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </nav>
                        <hr className="mt-3" style={{ color: "#9aa0a3" }} />
                    </div>
                </div>
                {/*  */}

                <div className="container" style={{
                    background: "#FAFAFA",
                    overflow: "hidden"
                }}>

                    {/* About us Section */}
                    <div
                        ref={(el) => sections.current[0] = el}
                        className="section choose-podcastmons-section"
                        style={{
                            /* backgroundImage: `url(${common_bg})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat', */
                            backgroundColor: '#FAFAFA', // To add a darker overlay
                            // backgroundBlendMode: 'overlay', // Blends the image with the background color
                            padding: isMobile ? '20px' : '50px',
                            borderRadius: '12px',
                            color: '#fff'
                        }}
                    >
                        <div className="row" style={{ marginBottom: '2%' }}>
                            <div className="col-sm-12 col-md-12 col-lg-12" /* style={{ marginTop: '2%' }} */>
                                <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-6" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                        {/* <div className="card" style={{ width: '100%', overflow: 'hidden', background: 'none', border: 'none' }}> */}
                                        {/* <div className="card-body"> */}
                                        <h4 style={{ color: '#231971', fontSize: '36px', fontWeight: 900, textAlign: 'left' }}>
                                            OCESHA<br /><br />
                                            AI/Blockchain Software <br />
                                            Development Company<br />
                                        </h4>
                                        <p className="card-text" style={{ fontSize: '16px', color: '#2A2742', textAlign: 'left', marginTop: '5%', fontWeight: 'normal' }}>
                                            Let us build your AI technology for you.
                                        </p>
                                        <Button sx={{
                                            background: '#5E4CE6', color: '#fff', fontWeight: 'bold', fontSize: '14px', display: 'flex', alignItems: 'center', padding: '1%', width: isMobile ? '100%' : '50%',
                                            '&:hover': {
                                                background: '#5E4CE6',
                                                color: '#fff',
                                            },
                                            // height: isMobile ? '100%' : '100%',

                                            borderRadius: '8px'
                                        }} onClick={() => navigate('/')}>Schedule a Free Consultation</Button>
                                        {/* </div> */}
                                        {/* </div> */}
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6" style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img src={aboutus_section_1} style={{
                                            width: '75%',
                                            maxWidth: '100%',
                                            marginTop: isMobile ? '5%' : '',
                                            borderRadius: '12px'
                                        }}></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* About us Section End */}

                    {/* Who we are section */}
                    <div
                        ref={(el) => sections.current[1] = el}
                        className="section choose-podcastmons-section"
                        style={{
                            /* backgroundImage: `url(${common_bg})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat', */
                            backgroundColor: '#FAFAFA', // To add a darker overlay
                            // backgroundBlendMode: 'overlay', // Blends the image with the background color
                            padding: isMobile ? '20px' : '50px',
                            borderRadius: '12px',
                            color: '#fff'
                        }}
                    >
                        <div className="row" style={{ marginBottom: '2%' }}>

                            <div className="col-sm-12 col-md-12 col-lg-12" /* style={{ marginTop: '2%' }} */>
                                <div className="row">
                                    {/* <div className="col-sm-12 col-md-3 col-lg-3"></div> */}
                                    <div className="col-sm-12 col-md-12 col-lg-12" /* style={{ paddingLeft: '3%' }} */>
                                        <h4 style={{ color: '#231971', fontSize: '36px', fontWeight: '900', textAlign: 'left' }}>Who We Are</h4>
                                    </div>
                                    {/* <div className="col-sm-12 col-md-3 col-lg-3"></div> */}
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12" style={{ marginTop: '1%' }}>
                                <div className="row">
                                    {/* <div className="col-sm-12 col-md-1 col-lg-1" style={{ marginTop: isMobile ? '6%' : '' }}></div> */}
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        {/* <div className="card" style={{ width: '100%', overflow: 'hidden', background: 'none', border: 'none' }}> */}
                                        {/* <div className="card-body"> */}
                                        <p className="card-text" style={{ fontSize: '16px', color: '#2A2742', textAlign: 'left'/* , marginTop: '10%' */, fontWeight: 'normal' }}>
                                            OceSha is a Southern California-based software development company, specializing in developing AI and Blockchain technologies. We develop scalable, intelligent solutions that drive efficiency, automation, and growth for businesses worldwide.
                                        </p>
                                        {/* </div> */}
                                        {/* </div> */}
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-6" style={{ marginTop: isMobile ? '3%' : '' }}>
                                        {/* <div className="card" style={{ width: '100%', overflow: 'hidden', background: 'none', border: 'none' }}> */}
                                        {/* <div className="card-body"> */}
                                        <p className="card-text" style={{ fontSize: '16px', color: '#2A2742', textAlign: 'left'/* , marginTop: '10%' */, fontWeight: 'normal' }}>
                                            We have built and launched AI and blockchain-powered platforms that are transforming industries. We are passionate about working with entrepreneurs and startups to turn their technology ideas into real products.  Learn about how we can be your funding and development partner to get your project from zero to 1.
                                        </p>
                                        {/* </div> */}
                                        {/* </div> */}
                                    </div>
                                    {/* <div className="col-sm-12 col-md-2 col-lg-2" style={{ marginTop: isMobile ? '6%' : '' }}></div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* who we are Section End */}

                    {/* Our recent AI&blockchain projects Section */}
                    <div
                        ref={(el) => sections.current[2] = el}
                        className="section benefit-features-section"
                        style={{
                            // backgroundImage: `url(${common_bg})`,
                            /* backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat', */
                            // backgroundColor: '#eeb0b1', // Overlay color with reduced opacity
                            backgroundColor: '#5E4CE6',
                            // backgroundBlendMode: 'overlay', // Blends the image with the overlay color
                            padding: isMobile ? '20px' : '50px',
                            borderRadius: '12px',
                            color: '#fff'
                        }}
                    >
                        <div className="row" style={{ marginBottom: '5%', marginTop: '5%' }}>
                            <div className="col-sm-12 col-md-1 col-lg-1">&nbsp;</div>

                            <div className="col-sm-12 col-md-10 col-lg-10">
                                <div className="card" style={{ width: '100%', borderRadius: '12px', overflow: 'hidden', background: '#D3CCFB', height: /* isMobile ? */ '100%' /* : '105px' */ }}>
                                    <div className="col-sm-12 col-md-12 col-lg-12" style={{ padding: '5%' }}>
                                        <h4 style={{ color: '#231971', fontSize: '36px', fontWeight: '900', textAlign: 'left' }}>Our Recent AI & Blockchain Projects</h4>
                                        <div className="row" style={{ marginTop: '4%' }}>
                                            <div className="col-sm-12 col-md-6 col-lg-6" style={{ marginTop: isMobile ? '6%' : '' }}>
                                                <div className="card" style={{ width: '100%', borderRadius: '12px', overflow: 'hidden', background: '#E9E6FA', height: /* isMobile ? */ '100%' /* : '105px' */ }}>
                                                    <div className="card-body">
                                                        <h5 className="card-title" style={{ fontSize: '20px', fontWeight: '900', textAlign: 'left', color: '#5E4CE6' }}><a href="https://www.ocesha.com/" style={{ color: '#5E4CE6' }}>OceSha.com</a></h5>
                                                        <p className="card-text" style={{ fontSize: '15px', color: '#2A2742', textAlign: 'left', fontWeight: 'lighter' }}>
                                                            An AI-driven content creation, SEO optimization, and monetization platform that helps businesses generate high-quality content at scale.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6" style={{ marginTop: isMobile ? '6%' : '' }}>
                                                <div className="card" style={{ width: '100%', borderRadius: '12px', overflow: 'hidden', background: '#E9E6FA', height: /* isMobile ? */ '100%' /* : '105px' */ }}>
                                                    <div className="card-body">
                                                        <h5 className="card-title" style={{ fontSize: '20px', fontWeight: '900', textAlign: 'left', color: '#5E4CE6' }}><a href="https://www.ocesha.com/contactus" style={{ color: '#5E4CE6' }}>OceSha Enterprise</a></h5>
                                                        <ul>
                                                            <li className="card-text" style={{ fontSize: '15px', color: '#2A2742', textAlign: 'left' }}>
                                                                <span style={{ fontWeight: 'bold' }}>Enterprise AI:</span>&nbsp;<span style={{ fontWeight: 'lighter' }}>An AI-powered smart contract auditing platform, ensuring
                                                                    security, compliance, and accuracy in blockchain
                                                                    transactions.</span>
                                                            </li>
                                                            <li className="card-text" style={{ fontSize: '15px', color: '#2A2742', textAlign: 'left', marginTop: '2%' }}>
                                                                <span style={{ fontWeight: 'bold' }}>Flexible Deployment:</span>&nbsp;<span style={{ fontWeight: 'lighter' }}>Supports multi-cloud, hybrid, and on-premise.</span>
                                                            </li>
                                                            <li className="card-text" style={{ fontSize: '15px', color: '#2A2742', textAlign: 'left', marginTop: '2%' }}>
                                                                <span style={{ fontWeight: 'bold' }}>High Security:</span>&nbsp;<span style={{ fontWeight: 'lighter' }}>No data persistence, ensuring compliance.</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row" style={{ marginTop: '1%' }}>
                                            <div className="col-sm-12 col-md-6 col-lg-6" style={{ marginTop: isMobile ? '6%' : '' }}>
                                                <div className="card" style={{ width: '100%', borderRadius: '12px', overflow: 'hidden', background: '#E9E6FA', height: /* isMobile ? */ '100%' /* : '105px' */ }}>
                                                    <div className="card-body">
                                                        <h5 className="card-title" style={{ fontSize: '20px', fontWeight: '900', textAlign: 'left' }}><span><a href="https://smartcontracts.code-genie.ai/" style={{ color: '#5E4CE6' }}>CodeGenie</a> </span><span style={{ color: '#2A2742' }}>- Blockchain Audit</span></h5>
                                                        <p className="card-text" style={{ fontSize: '15px', color: '#2A2742', textAlign: 'left', fontWeight: 'lighter' }}>
                                                            An AI-powered smart contract auditing platform, ensuring security, compliance, and accuracy in blockchain transactions.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6" style={{ marginTop: isMobile ? '6%' : '' }}>
                                                <div className="card" style={{ width: '100%', borderRadius: '12px', overflow: 'hidden', background: '#E9E6FA', height: /* isMobile ? */ '100%' /* : '105px' */ }}>
                                                    <div className="card-body">
                                                        <h5 className="card-title" style={{ fontSize: '20px', fontWeight: '900', textAlign: 'left' }}><span><a href="https://smartcontracts.code-genie.ai/" style={{ color: '#5E4CE6' }}>CodeGenie</a> </span><span style={{ color: '#2A2742' }}>- Code Auditor</span></h5>
                                                        <p className="card-text" style={{ fontSize: '15px', color: '#2A2742', textAlign: 'left', fontWeight: 'lighter' }}>
                                                            An AI-powered platform that automates code review and optimization across multiple programming languages. It analyzes code for security vulnerabilities, inefficiencies, and compliance issues, providing actionable insights and AI-generated fixes.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row" style={{ marginTop: '1%' }}>
                                            <div className="col-sm-12 col-md-6 col-lg-6" style={{ marginTop: isMobile ? '6%' : '' }}>
                                                <div className="card" style={{ width: '100%', borderRadius: '12px', overflow: 'hidden', background: '#E9E6FA', height: /* isMobile ? */ '100%' /* : '105px' */ }}>
                                                    <div className="card-body">
                                                        <h5 className="card-title" style={{ fontSize: '20px', fontWeight: '900', textAlign: 'left' }}><span><a href="https://easi-autism-inventory-sh7xcmq.gamma.site/" style={{ color: '#5E4CE6' }}>EASI Autism Inventory</a> </span><span style={{ color: '#2A2742' }}>(EASI-AI)</span></h5>
                                                        <h5 className="card-title" style={{ fontSize: '16px', fontWeight: 'bold', textAlign: 'left' }}><span style={{ color: '#2A2742' }}>(To be released March 2025)</span></h5>
                                                        <p className="card-text" style={{ fontSize: '15px', color: '#2A2742', textAlign: 'left', fontWeight: 'lighter', marginTop: '5%' }}>
                                                            A trusted, AI-powered tool that helps families identify early autism indicators through expert-backed screening. <span style={{ fontWeight: 'bold' }}>Fast, secure, and research-driven,</span> it provides <span style={{ fontWeight: 'bold' }}>actionable insights</span> on speech, social, and behavioral patterns.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6" style={{ marginTop: isMobile ? '6%' : '' }}>
                                                <div className="card" style={{ width: '100%', borderRadius: '12px', overflow: 'hidden', background: '#E9E6FA', height: /* isMobile ? */ '100%' /* : '105px' */ }}>
                                                    <div className="card-body">
                                                        <h5 className="card-title" style={{ fontSize: '20px', fontWeight: '900', textAlign: 'left' }}><span><a href="https://yaahmon-r02f9j1.gamma.site/" style={{ color: '#5E4CE6' }}>YaahMon</a> </span><span style={{ color: '#2A2742' }}>(In Development)</span></h5>
                                                        <p className="card-text" style={{ fontSize: '15px', color: '#2A2742', textAlign: 'left', fontWeight: 'lighter' }}>
                                                            A cross border crypto payment solution that use AI Agents to send instant payments to anywhere in the world.  The technology integrates the best of Artificial Intelligence and Crypto Payments technology.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row" style={{ marginTop: '1%' }}>
                                            <div className="col-sm-12 col-md-6 col-lg-6" style={{ marginTop: isMobile ? '6%' : '' }}>
                                                <div className="card" style={{ width: '100%', borderRadius: '12px', overflow: 'hidden', background: '#E9E6FA', height: /* isMobile ? */ '100%' /* : '105px' */ }}>
                                                    <div className="card-body">
                                                        <h5 className="card-title" style={{ fontSize: '20px', fontWeight: '900', textAlign: 'left', color: '#5E4CE6' }}><a href="https://rocketfuel.inc/" style={{ color: '#5E4CE6' }}>RocketFuel.inc</a></h5>
                                                        <p className="card-text" style={{ fontSize: '15px', color: '#2A2742', textAlign: 'left', fontWeight: 'lighter' }}>
                                                            A crypto payments solution serving over 80,000 merchants worldwide, enabling 1-click crypto transactions across over 200 digital assets.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6" style={{ marginTop: isMobile ? '6%' : '' }}>
                                                <div className="card" style={{ width: '100%', borderRadius: '12px', overflow: 'hidden', background: '#E9E6FA', height: /* isMobile ? */ '100%' /* : '105px' */ }}>
                                                    <div className="card-body">
                                                        <h5 className="card-title" style={{ fontSize: '20px', fontWeight: '900', textAlign: 'left', color: '#5E4CE6' }}><a href="https://www.tradesocio.com/solutions/brokerage/" style={{ color: '#5E4CE6' }}>TradeSocio</a></h5>
                                                        <p className="card-text" style={{ fontSize: '15px', color: '#2A2742', textAlign: 'left', fontWeight: 'lighter' }}>
                                                            <span style={{ fontWeight: 'bold' }}>TradeSocio</span> is a powerful, secure, and customizable brokerage platform designed to modernize trading experiences. It offers <span style={{ fontWeight: 'bold' }}>self-trading, multi-asset support, fractional shares, and goal-based investment options,</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row" style={{ marginTop: '1%' }}>
                                            <div className="col-sm-12 col-md-12 col-lg-12" style={{ marginTop: isMobile ? '6%' : '' }}>
                                                <div className="card" style={{ width: '100%', borderRadius: '12px', overflow: 'hidden', background: '#E9E6FA', height: /* isMobile ? */ '100%' /* : '105px' */ }}>
                                                    <div className="card-body">
                                                        <h5 className="card-title" style={{ fontSize: '20px', fontWeight: '900', textAlign: 'left', color: '#5E4CE6' }}><a href="https://vottun.com/" style={{ color: '#5E4CE6' }}>Vottun.com</a></h5>
                                                        <p className="card-text" style={{ fontSize: '15px', color: '#2A2742', textAlign: 'left', fontWeight: 'lighter' }}>
                                                            A multi-chain blockchain ecosystem and platform that enables enterprises and governments to create and manage verifiable credentials, supply chain tracking, and decentralized applications.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="col-sm-12 col-md-1 col-lg-1">&nbsp;</div>

                        </div>
                    </div>
                    {/* Our recent AI&blockchain projects End */}

                    {/* key benefits Section */}
                    <div
                        ref={(el) => sections.current[3] = el}
                        className="section choose-podcastmons-section"
                        style={{
                            /* backgroundImage: `url(${common_bg})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat', */
                            backgroundColor: '#FAFAFA', // To add a darker overlay
                            // backgroundBlendMode: 'overlay', // Blends the image with the background color
                            padding: isMobile ? '20px' : '50px',
                            borderRadius: '12px',
                            color: '#fff'
                        }}
                    >
                        <div className="row" style={{ marginBottom: '2%' }}>

                            <div className="col-sm-12 col-md-12 col-lg-12" style={{ marginTop: '2%' }}>
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-12" /* style={{ paddingLeft: '3%' }} */>
                                        <h4 style={{ color: '#231971', fontSize: '36px', fontWeight: '900', textAlign: 'left' }}>Fortune 500 Enterprises We've Worked With</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12" style={{ marginTop: '2%' }}>
                                <div className="row">
                                    <div className="col-sm-12 col-md-3 col-lg-3">
                                        {/* <div className="card" style={{ width: '100%', overflow: 'hidden', background: 'none', border: 'none' }}> */}
                                        {/* <div className="card-body"> */}
                                        <div style={{ display: 'flex', alignItems: 'center', height: '50%' }}>
                                            <img src={aboutus_section4_1} style={{ width: '65px', display: 'flex', alignItems: 'left' }} />
                                        </div>
                                        <h5 className="card-title" style={{ fontSize: '20px', color: '#2A2742', textAlign: 'left', marginTop: isMobile ? '4%' : '6%', fontWeight: '800' }}>Technology &
                                            Enterprise Solutions</h5>
                                        <p className="card-text" style={{ fontSize: '18px', color: '#2A2742', textAlign: 'left', marginTop: '2%', fontWeight: 'normal' }}>
                                            Oracle, HP, PeopleSoft
                                        </p>
                                        {/* <Button sx={{ background: '#F65F62', color: '#000', fontWeight: 'bold', fontSize: '14px', display: 'flex', alignItems: 'center', padding: '3%' }}>Learn More</Button> */}
                                        {/* </div> */}
                                        {/* </div> */}
                                    </div>
                                    <div className="col-sm-12 col-md-3 col-lg-3" style={{ marginTop: isMobile ? '4%' : '' }}>
                                        {/* <div className="card" style={{ width: '100%', overflow: 'hidden', background: 'none', border: 'none' }}> */}
                                        {/* <div className="card-body"> */}
                                        <div style={{ display: 'flex', alignItems: 'center', height: '50%' }}>
                                            <img src={aboutus_section4_2} style={{ width: '65px', display: 'flex', alignItems: 'left' }} />
                                        </div>
                                        <h5 className="card-title" style={{ fontSize: '20px', color: '#2A2742', textAlign: 'left', marginTop: isMobile ? '4%' : '6%', fontWeight: '800' }}>Automotive &
                                            Manufacturing</h5>
                                        <p className="card-text" style={{ fontSize: '18px', color: '#2A2742', textAlign: 'left', marginTop: '2%', fontWeight: 'normal' }}>
                                            Honda, Avery Dennison
                                        </p>
                                        {/* <Button sx={{ background: '#F65F62', color: '#000', fontWeight: 'bold', fontSize: '14px', display: 'flex', alignItems: 'center', padding: '3%' }}>Learn More</Button> */}
                                        {/* </div> */}
                                        {/* </div> */}
                                    </div>
                                    <div className="col-sm-12 col-md-3 col-lg-3" style={{ marginTop: isMobile ? '4%' : '' }}>
                                        {/* <div className="card" style={{ width: '100%', overflow: 'hidden', background: 'none', border: 'none' }}> */}
                                        {/* <div className="card-body"> */}
                                        <div style={{ display: 'flex', alignItems: 'center', height: '50%' }}>
                                            <img src={aboutus_section4_3} style={{ width: '65px', display: 'flex', alignItems: 'left' }} />
                                        </div>
                                        <h5 className="card-title" style={{ fontSize: '20px', color: '#2A2742', textAlign: 'left', marginTop: isMobile ? '4%' : '6%', fontWeight: '800' }}>Finance & Investment</h5>
                                        <p className="card-text" style={{ fontSize: '18px', color: '#2A2742', textAlign: 'left', marginTop: '2%', fontWeight: 'normal' }}>
                                            Capital Group American Funds
                                        </p>
                                        {/* <Button sx={{ background: '#F65F62', color: '#000', fontWeight: 'bold', fontSize: '14px', display: 'flex', alignItems: 'center', padding: '3%' }}>Learn More</Button> */}
                                        {/* </div> */}
                                        {/* </div> */}
                                    </div>
                                    <div className="col-sm-12 col-md-3 col-lg-3" style={{ marginTop: isMobile ? '4%' : '' }}>
                                        {/* <div className="card" style={{ width: '100%', overflow: 'hidden', background: 'none', border: 'none' }}> */}
                                        {/* <div className="card-body"> */}
                                        <div style={{ display: 'flex', alignItems: 'center', height: '50%' }}>
                                            <img src={aboutus_section4_4} style={{ width: '65px', display: 'flex', alignItems: 'left' }} />
                                        </div>
                                        <h5 className="card-title" style={{ fontSize: '20px', color: '#2A2742', textAlign: 'left', marginTop: isMobile ? '4%' : '6%', fontWeight: '800' }}>Nonprofit & Energy</h5>
                                        <p className="card-text" style={{ fontSize: '18px', color: '#2A2742', textAlign: 'left', marginTop: '2%', fontWeight: 'normal' }}>
                                            American Red Cross, Nevada Power
                                        </p>
                                        {/* <Button sx={{ background: '#F65F62', color: '#000', fontWeight: 'bold', fontSize: '14px', display: 'flex', alignItems: 'center', padding: '3%' }}>Learn More</Button> */}
                                        {/* </div> */}
                                        {/* </div> */}
                                    </div>
                                </div>

                                <div className="row" style={{ marginTop: isMobile ? '4%' : '2%' }}>
                                    <div className="col-sm-12 col-md-3 col-lg-3">
                                        {/* <div className="card" style={{ width: '100%', overflow: 'hidden', background: 'none', border: 'none' }}> */}
                                        {/* <div className="card-body"> */}
                                        <div style={{ display: 'flex', alignItems: 'center', height: '50%' }}>
                                            <img src={aboutus_section4_5} style={{ width: '65px', display: 'flex', alignItems: 'left' }} />
                                        </div>
                                        <h5 className="card-title" style={{ fontSize: '20px', color: '#2A2742', textAlign: 'left', marginTop: isMobile ? '4%' : '6%', fontWeight: '800' }}>Consulting & Staffing</h5>
                                        <p className="card-text" style={{ fontSize: '18px', color: '#2A2742', textAlign: 'left', marginTop: '2%', fontWeight: 'normal' }}>
                                            Deloitte, Robert Half
                                        </p>
                                        {/* <Button sx={{ background: '#F65F62', color: '#000', fontWeight: 'bold', fontSize: '14px', display: 'flex', alignItems: 'center', padding: '3%' }}>Learn More</Button> */}
                                        {/* </div> */}
                                        {/* </div> */}
                                    </div>
                                    <div className="col-sm-12 col-md-3 col-lg-3" style={{ marginTop: isMobile ? '4%' : '' }}>
                                        {/* <div className="card" style={{ width: '100%', overflow: 'hidden', background: 'none', border: 'none' }}> */}
                                        {/* <div className="card-body"> */}
                                        <div style={{ display: 'flex', alignItems: 'center', height: '50%' }}>
                                            <img src={aboutus_section4_6} style={{ width: '65px', display: 'flex', alignItems: 'left' }} />
                                        </div>
                                        <h5 className="card-title" style={{ fontSize: '20px', color: '#2A2742', textAlign: 'left', marginTop: isMobile ? '4%' : '6%', fontWeight: '800' }}>Industrial & Innovation</h5>
                                        <p className="card-text" style={{ fontSize: '18px', color: '#2A2742', textAlign: 'left', marginTop: '2%', fontWeight: 'normal' }}>
                                            Corning
                                        </p>
                                        {/* <Button sx={{ background: '#F65F62', color: '#000', fontWeight: 'bold', fontSize: '14px', display: 'flex', alignItems: 'center', padding: '3%' }}>Learn More</Button> */}
                                        {/* </div> */}
                                        {/* </div> */}
                                    </div>
                                    {isMobile ? '' :
                                        <div className="col-sm-12 col-md-3 col-lg-3">
                                            &nbsp;
                                        </div>}
                                    {isMobile ? '' :
                                        <div className="col-sm-12 col-md-3 col-lg-3">
                                            &nbsp;
                                        </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* key benefits Section End */}

                    {/* Meet our founder Section */}
                    <div
                        ref={(el) => sections.current[4] = el}
                        className="section key-features-section"
                        style={{
                            backgroundColor: '#5E4CE6',
                            padding: isMobile ? '20px' : '50px',
                            borderRadius: '12px',
                            color: '#fff'
                        }}
                    >
                        <div className="row" style={{ overflow: 'hidden', marginTop: '5%', marginBottom: '5%' }}>
                            <div className="col-sm-12 col-md-1 col-lg-1">&nbsp;</div>

                            <div className="col-sm-12 col-md-10 col-lg-10">
                                <div className="card" style={{ width: '100%', borderRadius: '12px', overflow: 'hidden', background: '#D3CCFB', height: /* isMobile ? */ '100%' /* : '105px' */ }}>
                                    <div className="col-sm-12 col-md-12 col-lg-12" style={{ padding: '5%' }}>
                                        <h4 style={{ color: '#231971', fontSize: '36px', fontWeight: '900', textAlign: 'left' }}>Meet Our Founder -<span><a href='https://rohanhall.com/' style={{ color: '#5E4CE6' }}> Rohan Hall</a></span></h4>
                                        <Timeline position="alternate-reverse" sx={{ position: "relative", padding: 0, marginTop: '6%' }}>
                                            {/* Single Continuous Vertical Line */}
                                            <Box
                                                sx={{
                                                    position: "absolute",
                                                    top: 0,
                                                    left: "50%",
                                                    transform: "translateX(-50%)",
                                                    width: "2px", // Line thickness
                                                    height: "100%",
                                                    backgroundColor: "#BDB8DF",
                                                    zIndex: 0,
                                                }}
                                            />
                                            {[
                                                {
                                                    title: "AI & Blockchain Expert",
                                                    description:
                                                        "With over 20 years of experience, Rohan has built<br /> and scaled multiple AI and blockchain platforms,<br /> working with Fortune 500 companies and disruptive<br /> startups alike.",
                                                },
                                                {
                                                    title: "Author & Educator",
                                                    description:
                                                        "He has written multiple books on AI, blockchain, and<br /> entrepreneurship, and actively teaches businesses<br /> how to leverage AI for automation and growth.",
                                                },
                                                {
                                                    title: "Industry Experience",
                                                    description:
                                                        "Rohan has worked with global organizations such<br /> as Oracle, IBM, Deloitte, HP, Accenture, and<br /> Corning, leading large-scale technology<br /> implementations.",
                                                },
                                                {
                                                    title: "Podcaster & Thought Leader",
                                                    description:
                                                        "As the host of the Explainable AI Podcast, he<br /> interviews top AI professionals from Google,<br /> OpenAI, Microsoft, and other leading tech<br /> companies.",
                                                },
                                                {
                                                    title: "Social Impact & Education",
                                                    description:
                                                        "Through his nonprofit, Ninja CEO Foundation,<br /> Rohan is committed to educating underprivileged<br /> youth in AI and blockchain technologies, equipping<br /> them with skills for the future.",
                                                },
                                            ].map((step, index) => (
                                                <TimelineItem key={index} sx={{ minHeight: "150px" }}>
                                                    <TimelineSeparator>
                                                        {/* Horizontal Line */}
                                                        <Box
                                                            sx={{
                                                                width: 50,
                                                                height: "2px",
                                                                backgroundColor: "#BDB8DF",
                                                                position: "absolute",
                                                                top: index % 2 !== 0 ? (isMobile ? '10%' : "19%") : (isMobile ? '9%' : "20%"),
                                                                left: index % 2 !== 0 ? "50%" : (isMobile ? '37%' : "45%"), // Adjust based on position
                                                                transform: "translateY(-50%)",
                                                            }}
                                                        />
                                                        {/* Square Numbered Dot */}
                                                        <TimelineDot
                                                            sx={{
                                                                backgroundColor: "#E9E6FA",
                                                                color: "#2A2742",
                                                                width: 36,
                                                                height: 36,
                                                                borderRadius: "8px", // Square shape
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                fontSize: "1rem",
                                                                fontWeight: "900",
                                                                border: '1px solid #BDB8DF',
                                                                zIndex: 1, // Ensure dots appear above the line
                                                            }}
                                                        >
                                                            {index + 1}
                                                        </TimelineDot>
                                                    </TimelineSeparator>
                                                    <TimelineContent sx={{
                                                        // textAlign: index % 2 !== 0 ? "left" : "right",
                                                        ml: index % 2 === 0 ? -4 : 4, // Moves left-side text slightly left
                                                        mr: index % 2 !== 0 ? -4 : 4, // Moves right-side text slightly left
                                                    }}>
                                                        <Typography variant="h6" fontWeight="bold" style={{ textAlign: index % 2 !== 0 ? "left" : "right", fontSize: '20px', fontWeight: '800', color: '#2A2742' }}>
                                                            {step.title}
                                                        </Typography>
                                                        <Typography variant="body1" style={{ textAlign: index % 2 !== 0 ? "left" : "right", fontSize: '16px', fontWeight: 'lighter', color: '#2A2742' }} dangerouslySetInnerHTML={{ __html: step.description }} />
                                                    </TimelineContent>
                                                </TimelineItem>
                                            ))}
                                        </Timeline>
                                        <div style={{ display: 'flex', gap: '10px', marginTop: '5%' }}>
                                        <Button sx={{
                                            background: '#5E4CE6', color: '#fff', fontWeight: 'bold', fontSize: '14px', display: 'flex', alignItems: 'center', padding: '1%', width: isMobile ? '100%' : '25%',
                                            '&:hover': {
                                                background: '#5E4CE6',
                                                color: '#fff',
                                            },
                                            // height: isMobile ? '100%' : '100%',

                                            borderRadius: '8px'
                                        }} onClick={() => navigate('/')}>Schedule a FREE Consultation</Button>
                                        <Button sx={{
                                            background: '#5E4CE6', color: '#fff', fontWeight: 'bold', fontSize: '14px', display: 'flex', alignItems: 'center', padding: '1%', width: isMobile ? '100%' : '20%',
                                            '&:hover': {
                                                background: '#5E4CE6',
                                                color: '#fff',
                                            },
                                            // height: isMobile ? '100%' : '100%',

                                            borderRadius: '8px'
                                        }} onClick={() => navigate('https://rohanhall.com/')}>Learn About Rohan Hall</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-1 col-lg-1">&nbsp;</div>
                        </div>
                    </div>
                    {/* Meet our founder Section End */}

                    {/* Why Ocesha Section */}
                    <div
                        ref={(el) => sections.current[5] = el}
                        className="section key-features-section"
                        style={{
                            /* backgroundImage: `url(${common_bg})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat', */
                            backgroundColor: '#FAFAFA', // To add a darker overlay
                            // backgroundBlendMode: 'overlay', // Blends the image with the background color
                            padding: isMobile ? '20px' : '50px',
                            borderRadius: '12px',
                            color: '#fff'
                        }}
                    >
                        <div className="row">
                            {/* <div className="col-sm-12 col-md-1 col-lg-1"></div> */}
                            <div className="col-sm-12 col-md-5 col-lg-5 d-flex justify-content-start" style={{ alignSelf: 'center', paddingLeft: '3%' }}>
                                <img src={aboutus_section_6} style={{
                                    width: /* isMobile ?  */'100%' /* : '338px' */,
                                    maxWidth: '100%',
                                    marginTop: isMobile ? '5%' : '',
                                    borderRadius: '8px'
                                }}></img>
                            </div>
                            <div className="col-sm-12 col-md-7 col-lg-7" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingLeft: '5%', marginTop: isMobile ? '6%' : '' }}>
                                <h4 style={{ color: '#231971', fontSize: '36px', fontWeight: '900', textAlign: 'left' }}>Why OceSha?</h4>
                                <div className="chevron-container1" style={{ marginTop: '9%' }}>
                                    <div className="chevron-item1">
                                        <div className="chevron1" style={{ height: isMobile ? '400px' : '260px' }}>1</div>
                                        <div className="content1">
                                            <p style={{ fontSize: '20px', fontWeight: '800' }}>AI-Powered Custom Software Development</p>
                                            <p style={{ fontSize: '18px', fontWeight: 'lighter' }}>We build cutting-edge AI solutions tailored to your business<br />needs, including automation, and seamless integration of AI<br />tools for marketing, content creation, customer service, and<br />decision-making.</p>
                                        </div>
                                    </div>

                                    <div className="chevron-item1">
                                        <div className="chevron1" style={{ height: isMobile ? '400px' : '260px' }}>2</div>
                                        <div className="content1">
                                            <p style={{ fontSize: '20px', fontWeight: '800' }}>Blockchain & Web3 Expertise</p>
                                            <p style={{ fontSize: '18px', fontWeight: 'lighter' }}>Secure smart contract development, crypto payment<br />integrations, and decentralized applications (dApps).</p>
                                        </div>
                                    </div>

                                    <div className="chevron-item1">
                                        <div className="chevron1" style={{ height: isMobile ? '400px' : '260px' }}>3</div>
                                        <div className="content1">
                                            <p style={{ fontSize: '20px', fontWeight: '800' }}>Scalable & Future-Proof Technology Stack</p>
                                            <p style={{ fontSize: '18px', fontWeight: 'lighter' }}>We use modern, high-performance technologies like Python,<br />Node.js, React, and microservices architecture with cloud-<br />native development on AWS and Azure ensures reliability,<br />security, and scalability.</p>
                                        </div>
                                    </div>

                                    <div className="chevron-item1">
                                        <div className="chevron1" style={{ height: isMobile ? '400px' : '260px' }}>4</div>
                                        <div className="content1">
                                            <p style={{ fontSize: '20px', fontWeight: '800' }}>Agile & DevOps-Driven Development Process</p>
                                            <p style={{ fontSize: '18px', fontWeight: 'lighter' }}>Rapid iterations, continuous deployment (CI/CD), and<br />automated testing ensure high-quality, efficient software<br />delivery.  End-to-end development from idea to execution,<br />with ongoing maintenance and support.</p>
                                        </div>
                                    </div>

                                    <div className="chevron-item1">
                                        <div className="chevron1" style={{ height: isMobile ? '400px' : '260px' }}>5</div>
                                        <div className="content1">
                                            <p style={{ fontSize: '20px', fontWeight: '800' }}>Strategic JV & Partnership Opportunities</p>
                                            <p style={{ fontSize: '18px', fontWeight: 'lighter' }}>We collaborate with businesses to co-develop AI-powered<br />platforms with revenue-sharing models. Joint venture<br />opportunities for integrating AI-driven automation and<br />blockchain solutions into existing business models.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Why ocesha Section End */}

                    {/* Join the future of AI & BlockChain and start your journey today Section */}
                    <div
                        ref={(el) => sections.current[6] = el}
                        className="section benefit-features-section"
                        style={{
                            // backgroundImage: `url(${common_bg})`,
                            /* backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat', */
                            // backgroundColor: '#eeb0b1', // Overlay color with reduced opacity
                            backgroundColor: '#5E4CE6',
                            // backgroundBlendMode: 'overlay', // Blends the image with the overlay color
                            padding: isMobile ? '20px' : '50px',
                            borderRadius: '12px',
                            color: '#fff',
                        }}
                    >
                        <div style={{ marginTop: '5%', marginBottom: '5%', display: 'flex', flexDirection: 'column', gap: '245px' }}>
                            <div className="row" /* style={{ marginBottom: '2%' }} */>
                                <div className="col-sm-12 col-md-1 col-lg-1">&nbsp;</div>

                                <div className="col-sm-12 col-md-10 col-lg-10">
                                    <div className="card" style={{ width: '100%', borderRadius: '12px', overflow: 'hidden', background: '#D3CCFB', height: /* isMobile ? */ '100%' /* : '105px' */ }}>
                                        <div className="col-sm-12 col-md-12 col-lg-12" style={{/*  marginTop: '2%' *//* , paddingLeft: '3%', paddingRight: '3%'  */padding: '5%' }}>
                                            <div className="row">
                                                <h4 style={{ color: '#231971', fontSize: '36px', fontWeight: '900', textAlign: 'left' }}>Join the Future of AI & Blockchain</h4>
                                                <div className="col-sm-12 col-md-6 col-lg-6" style={{ marginTop: isMobile ? '6%' : '8%' }}>
                                                    <div style={{ background: '#E9E6FA', border: '2px solid #CBCBCB', borderRadius: '6px', height: '18px' }}>&nbsp;</div>
                                                    <p className="card-text" style={{ fontSize: '16px', color: '#2A2742', fontWeight: 'lighter', marginTop: '6%' }}>
                                                        At OceSha, we're more than just a software company we're a
                                                        technology powerhouse dedicated to shaping the future of AI and
                                                        blockchain.
                                                    </p>
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-6" style={{ marginTop: isMobile ? '6%' : '5%' }}>
                                                    <div style={{ background: '#E9E6FA', border: '2px solid #CBCBCB', borderRadius: '6px', height: '18px' }}>&nbsp;</div>
                                                    <p className="card-text" style={{ fontSize: '16px', color: '#2A2742', fontWeight: 'lighter', marginTop: '6%' }}>
                                                        Whether you're looking to scale content creation, automate
                                                        business processes, or develop a blockchain-powered solution,
                                                        we're here to help.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-1 col-lg-1">&nbsp;</div>

                            </div>

                            <div className="row" /* style={{ marginBottom: '2%' }} */>
                                <div className="col-sm-12 col-md-1 col-lg-1">&nbsp;</div>

                                <div className="col-sm-12 col-md-10 col-lg-10">
                                    <div className="card" style={{ width: '100%', borderRadius: '12px', overflow: 'hidden', background: '#D3CCFB', height: /* isMobile ? */ '100%' /* : '105px' */ }}>
                                        <div className="col-sm-12 col-md-12 col-lg-12" style={{/*  marginTop: '2%' *//* , paddingLeft: '3%', paddingRight: '3%'  */padding: '5%' }}>
                                            <div className="row">
                                                <h4 style={{ color: '#231971', fontSize: '36px', fontWeight: '900', textAlign: 'left' }}>Start Your Journey Today!</h4>
                                                <div className="col-sm-12 col-md-12 col-lg-12" style={{ marginTop: isMobile ? '6%' : '4%' }}>
                                                    <p className="card-text" style={{ fontSize: '16px', color: '#2A2742', fontWeight: 'lighter' }}>
                                                        Partner with OceSha to build next-generation software solutions that drive innovation and growth!
                                                    </p>
                                                    <Button sx={{
                                                        background: '#5E4CE6', color: '#fff', fontWeight: 'bold', fontSize: '14px', display: 'flex', alignItems: 'center', padding: '1%', width: isMobile ? '100%' : '30%',
                                                        '&:hover': {
                                                            background: '#5E4CE6',
                                                            color: '#fff',
                                                        },
                                                        borderRadius: '8px'
                                                    }} onClick={() => navigate('/')}>Schedule a Free Consultation</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-1 col-lg-1">&nbsp;</div>

                            </div>
                        </div>
                    </div>
                    {/* Join the future of AI & BlockChain section End */}
                </div >
            </div>
        </>
    )
}

export default OceshaAboutUs;