import { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  Stack,
  Divider,
  FormControl,
  TextField,
  SvgIcon,
} from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import {
  LoginSocialGoogle,
  LoginSocialFacebook,
  LoginSocialTwitter,
  LoginSocialGithub,
  LoginSocialLinkedin,
  IResolveParams,
} from "reactjs-social-login";
import { useGeneratedbusinessurlblogsMutation } from "../../redux/api/promptApi";
import { usePlanDetailsMutation } from "../../redux/api/productApi";
import { LoadingButton } from "@mui/lab";
//import { setCookie, getCookie, removeCookie } from "../..//utils/cookies.js";
import BackSignin from "../../assets/back_signin.png";
import Logo from '../../assets/logo.svg';

import { ReactComponent as GoogleIcon } from "../../assets/ico_google.svg";
import { ReactComponent as FacebookIcon } from "../../assets/ico_facebook.svg";
import { ReactComponent as TwitterIcon } from "../../assets/ico_twtter.svg";
import { ReactComponent as GithubIcon } from "../../assets/ico_github.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/ico_linkedin.svg";
// import body2 from "../pages/auth/text-2.png";
import img54 from '../../assets/newimg/logo.png';

import {
  useSigninUserMutation,
  useSocialAuthMutation,
} from "../../redux/api/authApi";
import { useAppDispatch } from "../../redux/store";
import { useSubscribeNewsMutation } from "../../redux/api/authApi";
import { setModule } from "../../redux/features/genieSlice";
import axios from "axios";
import { setCookie, getCookie, removeCookie } from '../../utils/storageUtils';
import OceshaLogo from '../../assets/ocesha_images/ocesha_logo.png';




const newsSubscribeform = object({
  email: string()
    .min(1, "Email address is required")
    .email("Email address is invalid"),
  status: string(),
})

export type NewsSubscriptionForm = TypeOf<typeof newsSubscribeform>

const signinSchema = object({
  email: string()
    .min(1, "Email address is required")
    .email("Email address is invalid"),
  password: string()
    .min(1, "Password is required")
    .min(8, "Password must be more than 8 characters"),
});

export type SigninInput = TypeOf<typeof signinSchema>;



const SigninPage = () => {

  const location = useLocation();
  const { CreatingfromLanding } = location.state || {};
  const { UrlfromLanding } = location.state || {};


  const [signinUser, signinState] = useSigninUserMutation();
  const navigate = useNavigate();

  const [generatedBusinessUrlBlogsMutation] = useGeneratedbusinessurlblogsMutation();

  const dispatch = useAppDispatch();
  const [authSocial, socialState] = useSocialAuthMutation();
  const [inputEmail, setInputEmail] = useState('');


  const REDIRECT_URI = window.location.href;
  const [generatedBlogDetail, setGeneratedBlogDetail] = useState<any>();

  const [planDetails, setPlanDetails] = useState<any>();
  const [getuserPlanDetails] = usePlanDetailsMutation();

  const methods = useForm<SigninInput>({
    resolver: zodResolver(signinSchema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmitHandler: SubmitHandler<SigninInput> = (values) => {
    signinUser(values);
    setInputEmail(values.email);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await generatedBusinessUrlBlogsMutation({ userid: inputEmail, createddate: '', createddate_to: '' });
        if ('data' in response) {
          const { data } = response.data;
          setGeneratedBlogDetail(data);
          console.log('data from variable=>', generatedBlogDetail);
        } else if ('error' in response) {

          const { error } = response;
          console.log('Error:', error);
        }
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };

    fetchData();
  }, [generatedBusinessUrlBlogsMutation, inputEmail]);

  useEffect(() => {
    console.log(generatedBlogDetail);
    console.log(inputEmail);
  }, [generatedBlogDetail, inputEmail]);

  const fetchPlanDatas = async () => {
    try {
      const response = await getuserPlanDetails({ email: localStorage.getItem('user_email') || '', campaignid: '' });
      console.log('plan details from signin=>', response);
      /* if ('data' in response) {
        if ('status' in response.data) {
          console.log('st', response.data.status);
          if (response.data.status === 'failed') {
            navigate('/pricing');
          }
        }
      } */
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchPlanDatas();
  }, [])

  useEffect(() => {

    if (signinState.isSuccess) {
      console.log(signinState.data);
      localStorage.clear();
      toast.success("Login Success");

      localStorage.setItem("logged_in", "true");
      localStorage.setItem("free_plan", signinState.data.free_plan.toString());
      localStorage.setItem("remaining_wish_count", signinState.data.remaining_wish_count.toString());
      const subscriptionLevel = signinState.data.subscription_level || '';
      localStorage.setItem("user_email", signinState.data.user_email || '');
      localStorage.setItem("FromSignIn", 'true');
      if (signinState?.data?.user_email) {
        setCookie("user_email", signinState.data.user_email);
      }
      localStorage.setItem('userRole', signinState.data.role);
      localStorage.setItem('payment', '');
      // localStorage.setItem('CreatingfromLanding', CreatingfromLanding);
      // localStorage.setItem('UrlfromLanding', UrlfromLanding);

      /* if (signinState.data.role === "user") {
        fetchPlanDatas();
      }
      if (!signinState.data.verified) navigate("/verify"); */
      // else {
      if (signinState.data.role === "user") {


        /* const fetchData = async () => {
          try {
            const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
            const response = await axios.get(apiUrl + 'prompts/listBlogsByUserID'); 
            setGeneratedBlogDetail(response.data);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
        fetchData(); */

        // if (generatedBlogDetail && generatedBlogDetail.length > 0) {
        localStorage.setItem('activeItem', 'blogs');
        navigate('/codegenie/generated-blogs', { state: { userEmail: signinState.data.user_email } });
        /* } else {
          navigate('/codegenie/dashboard');
        } */

        /* if (freePlan === '0' || (generatingCount && parseInt(generatingCount) === 1)) {
          navigate("/codegenie/generated-blogs");
        } else {
          navigate("/codegenie/dashboard");
        } */
        /* if (localStorage.getItem("module") === "Any Code") {
          dispatch(setModule("Any Code"));
          navigate("/codegenie/any_code");
        }
        else if (localStorage.getItem("module") === "SMART CONTRACTS") {
          dispatch(setModule("SMART CONTRACTS"));
          navigate("/codegenie/smart_contracts");
        }
        else if (signinState.data.initial_product === "Any Code") {
          localStorage.setItem("module", "Any Code");
          dispatch(setModule("Any Code"));
          navigate("/codegenie/any_code");
        }
        else {
          localStorage.setItem("module", "SMART CONTRACTS");
          dispatch(setModule("SMART CONTRACTS"));
          navigate("/codegenie/smart_contracts");
        } */
      } else if (signinState.data.role === "admin") {
        navigate("/admin/dashboard");
      }
      // }
    }
    if (signinState.isError) {
      localStorage.setItem("logged_in", "false");

      if (Array.isArray((signinState.error as any).data.detail)) {
        (signinState.error as any).data.detail.map((el: any) =>
          toast.error(`${el.loc[1]} ${el.msg}`)
        );
      } else toast.error((signinState.error as any).data.detail);
    }
  }, [signinState]);



  useEffect(() => {
    if (socialState.isSuccess) {
      toast.success("Social signup success");

      localStorage.setItem("logged_in", "true");
      localStorage.setItem('userRole', socialState.data.role);

      if (socialState.data.role === "user") {
        localStorage.setItem("module", "SMART CONTRACTS");
        dispatch(setModule("SMART CONTRACTS"));
        navigate("/codegenie/smart_contracts");
      } else {
        navigate("/admin/dashboard");
      }
    }
    if (socialState.isError) {

      localStorage.setItem("logged_in", "false");


      if (Array.isArray((socialState.error as any).data.detail)) {
        (socialState.error as any).data.detail.map((el: any) =>
          toast.error(`${el.loc[1]} ${el.msg}`)
        );
      } else toast.error((socialState.error as any).data.detail);
    }
  }, [socialState]);



  const [subscribeVal, setSubscribeVal] = useState<NewsSubscriptionForm>({ email: '', status: '' })
  const [newsSubsctiptionMutation, newsSubscriptionState] = useSubscribeNewsMutation();

  const methodsfoot = useForm<NewsSubscriptionForm>({
    resolver: zodResolver(newsSubscribeform),
  });
  const {
    register: RegisterFoot,
    handleSubmit: handleSubmitFoot,
    formState: { errors: Errors },
  } = methodsfoot;
  const onSubmitHandlerFoot: SubmitHandler<NewsSubscriptionForm> = (values) => {
    newsSubsctiptionMutation(values);
  };
  const handleSubscribeNews = () => {
    newsSubsctiptionMutation(subscribeVal)
      .unwrap()
      .then((response) => {
        console.log('news subscription=>', response);
        const status = response.status;

        console.log(status);
        if (status === 'success') {
          toast.success(response.message);
          setSubscribeVal({
            email: '',
            status: ''
          });
        }
      })
      .catch((error) => {
        toast.error(error?.data?.detail ?? 'Something went wrong!');
        console.error('Error sending query:', error);
      });
  }

  return (
    <>
      {/* Header */}
      <div className="container">
        <div className="row">
          <nav className="navbar navbar-expand-md">
            <div className="container-fluid">
              <a className="navbar-brand" href="/" style={{ textAlign: 'left' }}>
                <img src={OceshaLogo} className="img-fluid" alt="" style={{ width: '5%' }}/* style={{ width: '50%' }} */ />
              </a>
            </div>
          </nav>
          {/* <hr className="mt-3" style={{ color: "#9aa0a3" }} />s */}
        </div>
      </div>
      {/*  */}
      <div className="row">
        <div className="col-md-6 text-white text-center mt-2">
          <div className="px-sm-0 px-2">
            <div>
              <div className="Ac-1">
                <div>
                  <Grid item xs={6} position="relative">
                    <img className="signinimg" src={BackSignin} alt="Signup Background" />
                    <Typography
                      position="absolute"
                      bottom={103}
                      left={0}
                      variant="h4"
                      width="100%"
                      fontWeight={600}
                      color="white"

                    >
                      {/* Audit, Fix, Refactor, Document any Code Within Seconds <br></br> */}
                    </Typography>

                  </Grid>
                </div>
              </div> <br></br> <br></br>
            </div>
          </div>
        </div>
        <div className="col-md-6 my-auto">
          <div className="px-sm-0 px-2">
            {/*  <Box
              position="absolute"
              top={50}
              display="flex"
              alignItems="center"
              gap={4}
            >
              <div className="">
                <Typography variant="h5" fontWeight={600} color="text.secondary" onClick={() => navigate('/')}>
                  <img width={44} height={44} src={OceshaLogo} alt="Logo" onClick={() => navigate('/')} />
                  Ocesha
                </Typography>
              </div>
            </Box> */}
            <p className="Sign-1">
              <Box width={464}>
                <div className="Sign-2">
                  <Typography variant="h4" mb={4} color="text.secondary">
                    Sign in your account
                  </Typography>
                </div>
                <FormProvider {...methods}>
                  <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmit(onSubmitHandler)}
                  >
                    <Stack gap={3}>
                      <FormControl fullWidth>
                        <Typography mb={1} color="text.secondary">
                          Email address
                        </Typography>
                        <TextField
                          {...register("email")}
                          type="email"
                          placeholder="Enter your email"
                          error={!!errors["email"]}
                          helperText={errors["email"]?.message}
                          style={{ border: '1px solid #CBCBCB', borderRadius: '5px' }}
                        // value={inputEmail}
                        // onChange={(event)=>setInputEmail(event.target.value)}
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <Stack
                          mb={1}
                          flexDirection="row"
                          justifyContent="space-between"
                        >
                          <Typography color="text.secondary">Password</Typography>
                          <Link
                            to="/forgot"
                            style={{
                              color: "#0168B5",
                              textDecoration: "none",
                            }}
                          >
                            Forgot Password?
                          </Link>
                        </Stack>
                        <TextField
                          {...register("password")}
                          type="password"
                          placeholder="Enter your password"
                          error={!!errors["password"]}
                          helperText={errors["password"]?.message}
                          style={{ border: '1px solid #CBCBCB', borderRadius: '5px' }}
                        />
                      </FormControl>
                    </Stack>
                    <LoadingButton
                      loading={signinState.isLoading}
                      type="submit"
                      fullWidth
                      sx={{
                        height: 56,
                        background:
                          "linear-gradient(90deg, #036AB7 0%, #4BA5EB 100%)",
                        color: "white",
                        mt: 4,
                      }}
                    >
                      Log in
                    </LoadingButton>
                  </Box>
                </FormProvider>
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  gap={1}
                  height={44}
                  mt={3}
                >
                  {/* <Divider sx={{ flexGrow: 1 }} />
                  <Typography>Or</Typography>
                  <Divider sx={{ flexGrow: 1 }} /> */}
                </Stack>
                {/* <Stack direction="column" alignItems="center">
                  <Box
                    width={56}
                    height={56}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    border="1px solid #CACBCC"
                    borderRadius="50%"
                  >
                    <LoginSocialGoogle
                      client_id={process.env.REACT_APP_GG_APP_ID || ""}
                      scope="email"
                      onResolve={({ provider, data }: IResolveParams) => {
                        console.log(data);
                        if (data)
                          authSocial({
                            provider: provider as string,
                            email: data?.email || "" as string,
                            name: data?.name || "" as string,
                            id: data.sub as string,
                          });
                      }}
                      onReject={(err) => {
                        console.log(err);
                      }}
                    >
                      <SvgIcon>
                        <GoogleIcon />
                      </SvgIcon>
                    </LoginSocialGoogle>
                  </Box>
                </Stack> */}
                <Typography mt={3} style={{ color: '#fff' }}>
                  New user?{" "}
                  <Link
                    to="/signup"
                    state={{ newAccntFromSignin: 'true' }}
                    style={{
                      color: "#0168B5",
                    }}
                  >
                    Create an account
                  </Link>
                </Typography>
              </Box>
            </p>
          </div>
        </div>
      </div>


    </>
  );
};

export default SigninPage;
